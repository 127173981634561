import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { notifyUser } from '../../actions/notifyActions';
import { Link } from 'react-router-dom';

import Alert from '../layout/Alert';

class ForgottenPassword extends Component {
  state = {
    email: '',
    error: null,
    emailSent: false
  };


  onSubmit = event => {
    const { email } = this.state;
    event.preventDefault();
    console.log(this.props.firebase.auth);

    //this.props.firebase.auth
    this.props.firebase.auth()
      .sendPasswordResetEmail(email)
      .then( () => {
        this.setState({ email: '', error: null, emailSent: true });
      }).catch(error => {
        this.setState({ error });
      });

    /*
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ email: '' });
      })
      .catch(error => {
        this.setState({ error });
      });

      */
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  okClick = event => {
    this.setState({ emailSent: false });
  }

  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <div>
        { this.state.emailSent === false && 
          <div>
            <p>Please enter your email address to reset your password</p>
            <form onSubmit={this.onSubmit}>
              <input
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <button disabled={isInvalid} type="submit">
                Reset My Password
              </button>
              {error && <p>{error.message}</p>}
            </form>
          </div>
        }
        { this.state.emailSent && 
          <div>
            <p>An email containing a reset link has been sent to you email address</p>

            <Link to={`/`} className="btn btn-primary">OK</Link>
          </div>
        }
      </div>
    );
  }
}


export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      notify: state.notify
    }),
    // actions go in here
    { notifyUser }
  )
)(ForgottenPassword);


