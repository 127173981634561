import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import store  from '../../store';


class Tags extends Component {
  state = {
    assignmentId: false,
    tags: [],
    addingTag: false
  }

  getTags() {
    const { firestore } = this.props;
    const { assignmentId } = this.state;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';


//    firestore.get({ collection: 'tags', where: ["assignmentId", "==", this.state.assignmentId ] }).then((results) => {  
    firestore.get({ collection: tagsPath }).then((results) => {  
        let messages = [];
      var tags = [];
      for(var i = 0; i < results.docs.length; i++) {
        var tag = results.docs[i].data();
        tags.push({
          "id": results.docs[i].id,
          "name": tag.name,
          "value": tag.value,
          "assignmentId": tag.assignmentId,
          "createdBy": tag.createdBy
        });
      }

      this.setState({ tags, "addingTag": false });
    });  
  }  


  addATag = (e) => {
    const { assignmentId } = this.state;
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';

    let tag = {
      name: '',
      assignmentId: assignmentId,
      value: '',
      createdBy: store.getState().firebase.auth.uid
    };

    this.setState({ "addingTag": true });
    //let commentBank = this.state.commentBank.slice(0);
    firestore.add({ collection: tagsPath }, tag).then(  (result) => {
      this.getTags();
    });
  }

  deleteTag = (tagId, e) => {
    const { assignmentId } = this.state;
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';

    if(window.confirm('Are you sure you want to delete this tag?')) {
      firestore.delete({ collection: tagsPath, doc: tagId }).then( async (result) => {
        this.getTags();
      });
    }

  }


  saveInFirestore = (tagId) => {
    const { firestore } = this.props;
    const { tags } = this.state;
    const assignmentId = this.state.assignmentId;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';


    let tag = null;

    for(var i = 0; i < tags.length; i++) {
      if(tags[i].id == tagId) {
        tag = tags[i];
        break;
      }
    };

    if(tag === null) {
      return;
    }

    var saveProgressElement = null;
    saveProgressElement = document.getElementById('saving-overlay');
    saveProgressElement.style.display = 'block';


    firestore.update({ collection: tagsPath, doc: tag.id }, tag).then( async (result) => {
      saveProgressElement.style.display = 'none';
    });  
  }  

  blurTag = (tagId, e) => {
    this.saveInFirestore(tagId);
  }

  updateTagName = (tagId, e) => {
    const {tags} = this.state;
    var newTags = [];
    for(var i = 0; i < tags.length; i++) {
      let name = tags[i].name;
      if(tags[i].id == tagId) {
        name =  e.target.value;
      }

      newTags.push({
        id: tags[i].id,
        name: name,
        assignmentId: tags[i].assignmentId,
        value: tags[i].value,
        createdBy: tags[i].createdBy
      });
    }

    this.setState({ tags: newTags });
  }  
  updateTagValue = (tagId, e) => {
    const {tags} = this.state;
    var newTags = [];
    for(var i = 0; i < tags.length; i++) {
      let value = tags[i].value;
      if(tags[i].id == tagId) {
        value =  e.target.value;
      }

      newTags.push({
        id: tags[i].id,
        name: tags[i].name,
        assignmentId: tags[i].assignmentId,
        value: value,
        createdBy: tags[i].createdBy
      });
    }

    this.setState({ tags: newTags });
  }

  componentDidMount() {
    if(typeof this.props.match.params.assignmentId != 'undefined') {
      this.setState({ assignmentId: this.props.match.params.assignmentId}, () => {
        this.getTags();
      });
    }

  }

  componentDidUpdate(prevProps) {
    if(typeof this.props.match.params.assignmentId != 'undefined') {
      if (this.props.match.params.assignmentId !== prevProps.match.params.assignmentId) {
        this.setState({ assignmentId: this.props.match.params.assignmentId}, () => {
          this.getTags();
        });
      }
    }
  }

  render() {
    const { tags  } = this.state;
    return (
      <div style={{ "marginBottom": "20px" }}>
        <h1>Tags</h1>

        <div>
        <Link to={`/assignment/${this.state.assignmentId}`}>&lt; Back to assignment</Link>
        </div>

        <button 
                type="button" 
                className="btn btn-primary" 
                onClick={this.addATag}
                data-toggle="tooltip" 
                data-placement="top" 
                title="Add a tag"            
                >Add A Tag</button>

        { tags.map( (tag, index) => (
          <div  key={index}>
            <div className="row" style={{"padding": "10px 0", "borderBottom": "1px solid #aaaaaa"}}>
              <div className="col-md-8">  
                <div className="form-group">
                  <label>Name</label>
                  <input 
                      value={tag.name} 
                      id={ 'tagName' + tag.id } 
                      onChange={this.updateTagName.bind(this, tag.id)}
                      onBlur={this.blurTag.bind(this, tag.id)}
                      className="form-control"                    
                      />
                </div>
                <div className="form-group">
                  <label>Value</label>
                  <textarea 
                      value={tag.value} 
                      id={ 'tagValue' + tag.id } 
                      onChange={this.updateTagValue.bind(this, tag.id)}
                      onBlur={this.blurTag.bind(this, tag.id)}
                      className="form-control"
                      rows="6"
                      />
                </div>

                <div>
                  <button type="button" className="btn btn-danger" onClick={this.deleteTag.bind(this, tag.id )}>
                        <i className="far fa-trash-alt"></i> Delete Tag
                      </button>

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>    
    );
  }
}

export default firestoreConnect()(Tags);