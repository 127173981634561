import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { firebaseConnect } from 'react-redux-firebase'; //firebase instead of firestore
import store  from '../../store';

import dataStore from '../../data/DataStore';


class AssignmentList extends Component {
  state = {
    assignments: false,
    selectedAssignments: []
  }



  deleteTags = (assignmentId) => {
    const { firestore } = this.props;
    firestore.get({ collection: 'tags', where: ["assignmentId", "==", assignmentId ] }).then((results) => {  
      let batch = firestore.batch();

      for(var i = 0; i < results.docs.length; i++) {
        var tag = results.docs[i];
        batch.delete(tag.ref);
      }

      batch.commit().then(function() {

      });
    });

  }

  selectAssignment = (assignmentId, e) => {
    console.log('assignment id = ' + assignmentId);
    let url = '/assignment/' + assignmentId;
    this.props.history.push(url);

  }

  deleteAssignment = async (assignmentId, e) => {
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;

    await firestore.delete({ collection: userPath + '/assignments', doc: assignmentId });
    await this.deleteTags(assignmentId);
  }

  deleteSelected = async (e) => {
    const selectedAssignments = this.state.selectedAssignments;
    console.log(selectedAssignments);

    e.preventDefault();
    if(!window.confirm("Are you sure you want to delete ?")) {
      return;
    }
    for(let i = 0; i < selectedAssignments.length; i++) {
      console.log('delete ' + selectedAssignments[i]);
      await this.deleteAssignment(selectedAssignments[i]);

    }
    this.loadAssignmentList();
    this.setState( { selectedAssignments: [] });

  }
  /*
  test = async () => {
    console.log(dataStore);
    let list = await dataStore.getAssignmentList();
    console.log(list);
  }
*/

  handleCheckbox = (assignmentId, e) => {
    const target = e.target;
    const value = target.checked;    

    let selectedAssignments = this.state.selectedAssignments.slice(0);
    if(value) {
      selectedAssignments.push(assignmentId);
    } else {
      let index = selectedAssignments.indexOf(assignmentId);
      if(index != -1) {
        selectedAssignments.splice(index, 1);
      }
    }

    console.log(selectedAssignments);
    this.setState({ selectedAssignments });

  } 
  loadAssignmentList() {

    dataStore.getAssignmentList().then((results) => {
      this.setState({ assignments: results });
    });

  }

  componentDidMount() {
    this.loadAssignmentList();
  }

  componentDidUpdate(prevProps) {
  }



  render() {
    const {  auth } = this.props;
    const { assignments } = this.state;
    const uid = store.getState().firebase.auth.uid;

    if(!assignments) {
      return (
        <div>Loading...</div>
      );
    }

    let assignmentList = assignments.filter(assignment => (assignment.createdBy === uid) && (typeof assignment.commentBankId !== 'undefined')  );

    /*
    if(assignmentList.length === 0) {
      return (
        <div></div>
      );
    }
    */
    if(assignments) {
      return (
        <div>

          <div className="row">
            <div className="col-md-12">            
              <h2>Assignments</h2>

              <div style={{ "marginBottom": "8px"}}>
              <Link to="/assignment" className="btn btn-primary"><i className="fas fa-plus"></i> Add an assignment</Link>

              { this.state.selectedAssignments.length > 0 && 
                <button 
                  style={{ "marginLeft": "10px" }} 
                  className="btn btn-danger" 
                  onClick={this.deleteSelected}>
                  <i className="fas fa-trash"></i> Delete Selected
                  </button>
              }

              </div>

              { assignmentList.length > 0 && 
              <table className="table tableStriped table-clickable">
                <thead>
                  <tr>
                    <th style={{ "width": "20px" }}>&nbsp;</th>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  { assignmentList.map((entry, index) => (
                    <tr key={entry.id} className="table-clickable-row" className={ (this.state.selectedAssignments.indexOf( entry.id ) != -1) ? "selected-table-row" : "" }>
                      <td style={{ "width": "20px" }}>

                        <label className="cb-container">
                          <input 
                            type="checkbox" 
                            value="1" 
                            checked={this.state.selectedAssignments.indexOf( entry.id ) != -1} 
                            onChange={this.handleCheckbox.bind(this, entry.id)}
                            />
                          <span className="checkmark"></span>     
                        </label>

                      </td>
                      <td onClick={this.selectAssignment.bind(this, entry.id )}>{entry.name}</td>
                      <td onClick={this.selectAssignment.bind(this, entry.id )}>{entry.description}</td>

               
                    </tr>
                  ))}
                </tbody>
              </table>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }
}


export default firestoreConnect()(AssignmentList);

/*
export default compose(
  firestoreConnect(props => [
    {
      collection: 'users/' + store.getState().firebase.auth.uid + '/assignments'//,
      //where: ['createdBy', '==', store.getState().firebase.auth.uid]
    }
  ]),
  connect((state) => ({
    auth: state.firebase.auth,
    assignments: state.firestore.ordered.assignments
  }))
)(AssignmentList);
*/