import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import store  from '../../store';


class MessageList extends Component {
  render() {
    const { messages, auth } = this.props;

    if(!messages) {
      return (
        <div>Loading...</div>
      );
    }

    let messageList = messages.filter(message => message.createdBy === auth.uid);

    if(messageList.length === 0) {
      return (
        <div></div>
      )
    }
    if(messages) {
      return (
        <div>
          <h2>Messages</h2>
          <div className="row">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Text</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                { messageList.length === 0 &&
                  <tr>
                    <td colSpan="3">No messages found</td>
                  </tr>
                }
                { messageList.map((entry, index) => (
                  <tr key={entry.id}>
                    <td>{entry.name}</td>
                    <td>{entry.type}</td>
                    <td>{entry.text}</td>
                    <td style={{"textAlign": "right"}}><Link to={`/message/edit/${entry.id}`} className="btn btn-dark">Edit</Link></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'messages',
      where: ['createdBy', '==', store.getState().firebase.auth.uid]
    }
  ]),
  connect((state) => ({
    auth: state.firebase.auth,
    messages: state.firestore.ordered.messages
  }))
)(MessageList);