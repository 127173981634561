import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Criteria from '../criteria/Criteria';
import store  from '../../store';


function escapeRegExp(stringToGoIntoTheRegex) {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

class ViewFeedback extends Component {
  state = {
    formErrors: {},
    id: false,
    assignmentName: '',
    assignmentId: '',

    firstName: '',
    lastName: '',
    studentNumber: '',
    email: '',

    greeting: '',
    signoff: '',


    // array of { id, name, comments }
    commentBank: [],
    // the comment bank criteria ids in order
    commentBankCriteriaIds: [],

    // replace tags
    tags: [],


    commentBankId: false,
    commentBankName: '',


    // array of { id, name, ratings }
    rubric: [],

    // the rubric criteria ids in order
    rubricCriteriaIds: [],

    rubricId: false,
    rubricName: '',


    // the comments for the feedback
    // key is the criteriaId, value is the comment
    comments: {},
    ratings: {},
    grade: 0,

    copySuccess: '',

    // not used anymore??
    messages: [],
    greetingMessage: false,
    signoffMessage: false
  }

  editFeedback = (e) => {
    e.preventDefault();
    console.log('edit feedback');
    this.props.history.push('/assignment/' + this.state.assignmentId + '/feedback/edit/' + this.state.id);

  }

  newFeedback = (e) => {
    window.scrollTo(0,0);
    this.props.history.push('/assignment/addfeedback/' + this.state.assignmentId);
  }  

  doCancel = (e) => {
    e.preventDefault();
    this.props.history.push('/assignment/' + this.state.assignmentId);

  }

  /*
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  setComment = (criteriaId, commentIndex, e) => {
    let comment = e.target.value;    

    let commentBank = this.state.commentBank.slice(0);

    let criteria = false;
    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        commentBank[i].comments[commentIndex] = comment;

      }
    }    

    this.setState({ commentBank });
  }


*/

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
    setTimeout(() => {
      this.setState({ copySuccess: '' });
    }, 1000);
  }

  // messages are for personalised greeting and signoff (not really used anymore?)
  getMessages() {
    const { firestore } = this.props;

    console.log('get messages');
    firestore.get({ collection: 'messages', where: ["createdBy", "==", store.getState().firebase.auth.uid ] }).then((results) => {  
      console.log('get messages done');
      let messages = [];
      for(var i = 0; i < results.docs.length; i++) {
        var message = results.docs[i].data();
        messages.push({
          "name": message.name,
          "type": message.type,
          "text": message.text
        });
      }

      this.setState({ messages });
    });  
  }



  // tags are values that get replaced
  getTags() {
    const { firestore } = this.props;
    const { assignmentId } = this.state;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';

    firestore.get({ collection: tagsPath }).then((results) => {  
      let messages = [];
      var tags = [];
      for(var i = 0; i < results.docs.length; i++) {
        var tag = results.docs[i].data();
        tags.push({
          "id": results.docs[i].id,
          "name": tag.name,
          "value": tag.value,
          "assignmentId": tag.assignmentId,
          "createdBy": tag.createdBy
        });
      }
      this.setState({ tags });
    });  
  }    

  getAssignmentDetails(assignmentId) {
    const { firestore  } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const assignmentsPath = 'users/' + uid + '/assignments';

    this.setState({ assignmentName: '' });
    firestore.get({ collection: assignmentsPath, doc: assignmentId }).then((result) => {
      let assignment = result.data();
      this.setState({ id: false, assignmentName: assignment.name, assignmentId: assignmentId, commentBankId: assignment.commentBankId });
      //this.getRubricDetails(assignment.rubricId);
      console.log('get comment bank : ' + assignment.commentBankId);
      this.getCommentBank(assignment.commentBankId);

      if(typeof assignment.rubricId != 'undefined') {
        this.getRubric(assignment.rubricId);
      }

//      this.getMessages();
    });
  }

  getCommentBank(commentBankId) {
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    console.log("Comment bank id = " + commentBankId);

    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const commentBankPath = 'users/' + uid + '/commentBanks/' + commentBankId;

    firestore.get({ collection: commentBanksPath, doc: commentBankId }).then((results) => {
      let commentBankData = results.data();
      if(typeof commentBankData != 'undefined') {
        firestore.get({ collection: commentBankPath + '/commentCriteria' }).then((results) => {  
          let commentBank = [];
          for(let i = 0; i < results.docs.length; i++) {
            let criteria = results.docs[i].data();
            let comments = criteria.comments;
            if(typeof comments === 'undefined') {
              comments = [];
            }
            commentBank.push({
              "id": results.docs[i].id,
              "name": criteria.name,
              "comments": comments
//              "value": ''
            });
          }

          this.setState({ commentBank, commentBankId, commentBankName: commentBankData.name, commentBankCriteriaIds: commentBankData.criteriaIds, addingCriteria: false });
        });  
      } else {
        // uh oh, cant load the comment bank..
        console.log("cant load the comment bank!!!");
      }
    });
  }



  
  getRubric(rubricId) {
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const rubricsPath = 'users/' + uid + '/rubrics';
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + rubricId + '/rubricCriteria';

    console.log('get rubric: ' + rubricId);
    firestore.get({ collection: rubricsPath, doc: rubricId }).then((results) => {
      let rubricData = results.data();
      if(typeof rubricData != 'undefined') {

        firestore.get({ collection: rubricCriteriaPath }).then((results) => {  
          let rubric = [];
          for(let i = 0; i < results.docs.length; i++) {
            let rubricCriteria = results.docs[i].data();
            let ratings = rubricCriteria.ratings;
            if(typeof ratings === 'undefined') {
              ratings = [];
            }
            rubric.push({
              "id": results.docs[i].id,
              "name": rubricCriteria.name,
              "ratings": ratings
//              "value": ''
            });
          }

          this.setState({ rubric, rubricId, rubricName: rubricData.name, rubricCriteriaIds: rubricData.rubricCriteriaIds, addingRubricCriteria: false });
        });  
      } else {
        // uh oh, cant load the comment bank..
        console.log("cant load the rubric bank!!!");
      }
    });
  }

  getFeedbackDetails(assignmentId, feedbackId) {
    const { firestore  } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + assignmentId + '/feedback';

    console.log("FEEEDBACK PATH = " + feedbackPath);

    firestore.get({ collection: feedbackPath, doc: feedbackId }).then((result) => {
      let feedback = result.data();

      let comments = [];
      for(let i = 0; i < feedback.criteriaIds.length; i++) {
        comments[feedback.criteriaIds[i]] = feedback.comments[i];
      }

      let ratings = [];

      if(typeof feedback.rubricCriteriaIds !== 'undefined' && typeof feedback.ratings != 'undefined') {
        for(let i = 0; i < feedback.rubricCriteriaIds.length; i++) {
          if(i < feedback.ratings.length) {
            ratings[feedback.rubricCriteriaIds[i]] = feedback.ratings[i];
          }

        }
      }

      let rubricId = false;
      if(typeof feedback.rubricId !== 'undefined') {
        rubricId = feedback.rubricId;
      }

      this.setState({ 
        id: feedbackId,
        assignmentId: feedback.assignmentId, 
        commentBankId: feedback.commentBankId,
        rubricId: rubricId,
        firstName: feedback.firstName,
        lastName: feedback.lastName,
        studentNumber: feedback.studentNumber,
        email: feedback.email,
        grade: feedback.grade,
        comments: comments,
        ratings: ratings
      });

      this.getCommentBank(feedback.commentBankId);
      if(rubricId !== false) {
        this.getRubric(rubricId);
      }

        /*
      let currentComments = {
        criteriaIds: submission.criteriaIds,
        criteriaSelectedLevels: submission.criteriaSelectedLevels,
        criteriaLabels: submission.criteriaLabels,
        criteriaComments: submission.criteriaComments
      };

      */
//      this.getRubricDetails(submission.rubricId, currentComments);
//      this.getMessages();
      /*
      this.setState({ assignmentName: assignment.name, assignmentId: assignmentId, rubricId: assignment.rubricId });
      this.getRubricDetails(assignment.rubricId);
      this.getMessages();
      */

      this.getTags();

    });

  }

  
  componentDidMount() {
    this.setState({ name: '' })

    if(typeof this.props.match.params.id != 'undefined') {
      this.getFeedbackDetails(this.props.match.params.assignmentId, this.props.match.params.id);
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {
      this.getAssignmentDetails(this.props.match.params.assignmentId);
    }

    /*
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    })
    */

  }

  componentDidUpdate(prevProps) {

    if(typeof this.props.match.params.id != 'undefined') {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        this.getFeedbackDetails(this.props.match.params.assignmentId, this.props.match.params.id);
      }
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {
      if (this.props.match.params.assignmentId !== prevProps.match.params.assignmentId) {

        this.getAssignmentDetails(this.props.match.params.assignmentId);
      }
    }
  }

  render() {
    const { formErrors, tags } = this.state;

    let criteriaIds = this.state.commentBankCriteriaIds;
    
    let commentBank = [];
    let commentBankMap = {};
    if(this.state.commentBank != 'undefined') {
      commentBank = this.state.commentBank;

      for(let i = 0; i < commentBank.length; i++) {
        commentBankMap[commentBank[i].id] = commentBank[i];
      }
    }

    let rubricCriteriaIds = this.state.rubricCriteriaIds;
    if(typeof rubricCriteriaIds === 'undefined') {
      rubricCriteriaIds = [];
    }

    let rubric = [];
    let rubricMap = {};
    if(this.state.rubric != 'undefined') {
      rubric = this.state.rubric;
      for(let i = 0; i < rubric.length; i++) {
        rubricMap[rubric[i].id] = rubric[i];
      }
    }

    let feedback = '';

//    feedback += 'Rubric Score\n';
    for(let i = 0; i < rubricCriteriaIds.length; i++) {
      let rubricCriteriaId = rubricCriteriaIds[i];
      if(rubricMap[rubricCriteriaId].name.length > 0 && this.state.ratings[rubricCriteriaId].length > 0) {
        feedback += rubricMap[rubricCriteriaId].name + ': ';
        feedback += this.state.ratings[rubricCriteriaId];

      }
      feedback += '\n';
    }
    feedback += '\n\n';

    for(let i = 0; i < criteriaIds.length; i++) {
      let criteriaId = criteriaIds[i];

      if(commentBankMap[criteriaId].name !== 'Greeting' && commentBankMap[criteriaId].name !== 'Signoff') {
        feedback += commentBankMap[criteriaId].name + '\n\n';
      }

      let content = this.state.comments[criteriaId];

      content = content.replace(/\[Name\]/g, this.state.firstName);
      content = content.replace(/\(Name\)/g, this.state.firstName);
      content = content.replace(/\[name\]/g, this.state.firstName);
      content = content.replace(/\(name\)/g, this.state.firstName);

      for(let j = 0; j < tags.length; j++) {
        var replaceTag = escapeRegExp('[' + tags[j].name + ']');
        var regex = new RegExp(replaceTag, "ig");
        content = content.replace(regex, tags[j].value);
      }


      feedback += content;

      feedback += '\n\n';
    } 

//    feedback += 'Grade: ' + this.state.grade;


    feedback = feedback.trim();


    return (
      <div style={{ "marginBottom": "20px" }}>
        <h1>{ this.state.assignmentName}</h1>
        <Link to={`/assignment/${this.state.assignmentId}`}>&lt; Back to assignment</Link>

        <h2>Student Details</h2>

        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label style={{ "marginRight": "4px" }} htmlFor="firstName">First Name:</label>
                {this.state.firstName}
              </div>            
              <div className="form-group">
                <label style={{ "marginRight": "4px" }} htmlFor="firstName">Last Name:</label>
                {this.state.lastName}
              </div>            
              <div className="form-group">
                <label style={{ "marginRight": "4px" }} htmlFor="studentNumber">Student Number:</label>
                {this.state.studentNumber}
              </div>            
              <div className="form-group">
                <label style={{ "marginRight": "4px" }} htmlFor="email">Email:</label>
                {this.state.email}
              </div>            
            </div>
          </div>

          <h2>Feedback</h2>
          <div style={{ "marginBottom": "10px" }}>
            <button type="button" onClick={this.copyToClipboard} className="btn btn-primary" >Copy To Clipboard</button>
            <span style={{ marginLeft: "10px" }}>{ this.state.copySuccess }</span>
          </div>

          <div style={{ "marginBottom": "10px" }}>
            <textarea value={feedback} readOnly ref={(textarea) => this.textArea = textarea} style={{ "width": "600px", "height": "400px" }}/>
          </div>            



          <div style={{ "marginTop": "4px"}}>
            <button type="button" onClick={this.editFeedback} style={{ "marginRight": "10px" }} className="btn btn-secondary" >Edit Feedback</button>
            <button type="button" onClick={this.newFeedback} style={{ "marginRight": "10px" }} className="btn btn-primary" >New Feedback</button>          
            <button type="button" onClick={this.doCancel} style={{ "marginRight": "10px" }} className="btn btn-primary" >Feedback List</button>

          </div>
        </form>


      </div>
    );
  }
}

export default firestoreConnect()(ViewFeedback);