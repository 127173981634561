import React, { Component } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';


class Criteria extends Component {
  state = {
    highlightedScaleIndex: false,
    highlightedCommentIndex: false,
    highlightedSide: false

  }

  getComment(scaleIndex, side, commentIndex) {
    let comment = '';
    const { scale } = this.props
    let sortedScale = scale.slice();
    sortedScale.sort( (a,b) => {
      return a.index - b.index;
    });

    let scaleEntry = sortedScale[scaleIndex];

    if(side === 'left') {
      comment = scaleEntry.negativeComments[commentIndex];
    }

    if(side === 'right') {
      
      comment = scaleEntry.positiveComments[commentIndex];
    }

//    let hoverScaleLabel = scaleEntry.label;

    return comment;
  }

  getScaleLabel(scaleIndex) {
    const { scale } = this.props
    let sortedScale = scale.slice();
    sortedScale.sort( (a,b) => {
      return a.index - b.index;
    });

    let scaleEntry = sortedScale[scaleIndex];
    return scaleEntry.label;
  }

  mouseOverComment = (scaleIndex, side, commentIndex, e) => {
    let comment = this.getComment(scaleIndex, side, commentIndex);
    let scaleLabel = this.getScaleLabel(scaleIndex);

    this.setState({ "highlightedScaleIndex": scaleIndex, "highlightedSide": side, "highlightedCommentIndex": commentIndex });
  }

  mouseOutComment = (scaleIndex, side, commentIndex, e) => {
    this.setState({ "highlightedScaleIndex": false, "highlightedSide": false, "highlightedCommentIndex": false });

  }

  mouseClickComment  = (scaleIndex, side, commentIndex, e) => {

    const { selectedCriteria } = this.props;


    if(typeof this.props.onCommentSelected != 'undefined') {
      let comment = this.getComment(scaleIndex, side, commentIndex);
      let scaleLabel = this.getScaleLabel(scaleIndex);
      let criteriaId = this.props.id;

      let label = selectedCriteria.name + ': ' + scaleLabel;

      this.props.onCommentSelected(criteriaId, scaleIndex, comment, label);
    }

  }


  render() {
    const { selectedCriteria, scale } = this.props
    let level = false;

    if(typeof this.props.level != 'undefined') {
      level = this.props.level;
    }

    if(!selectedCriteria || ! scale) {
      return (
        <div>Loading...</div>
      );
    }

    let sortedScale = scale.slice();

    sortedScale.sort( (a,b) => {
      return a.index - b.index;
    });

    let firstLevel = 0;
    let showName = true;

    if(level !== false) {
      // only want to show one level
      firstLevel = level;
      sortedScale = sortedScale.splice(level, 1);
      showName = false;
    } else {
      level = 0;
      showName = true;
    }

    let midPoint = 100;
    const rowHeight = 21;
    const columnWidth = 20;
    let gridHeight = rowHeight * sortedScale.length;
    let lineHeight = gridHeight - (rowHeight - 5);

    let popup = false;
    let popupLeft = false;
    let popupTop = false;
    let popupText = false;
    let popupTitle = '';

    if(this.state.highlightedCommentIndex !== false && this.state.highlightedScaleIndex !== false && this.state.highlightedSide !== false) {
      popup = true;
      popupTitle = sortedScale[this.state.highlightedScaleIndex - firstLevel].label;
      popupTop = -10 + ( (rowHeight - 10) / 2 + (this.state.highlightedScaleIndex- firstLevel) * rowHeight);
//      console.log(rowHeight + ',' + )
      if(this.state.highlightedSide === 'left') {
        popupLeft =  Math.round(-10/2 + midPoint - (this.state.highlightedCommentIndex + 1) * columnWidth);
        popupText = sortedScale[this.state.highlightedScaleIndex- firstLevel].negativeComments[this.state.highlightedCommentIndex];
      } else {
        popupLeft =  Math.round(-10/2 + midPoint + (this.state.highlightedCommentIndex + 1) * columnWidth);
        popupText = sortedScale[this.state.highlightedScaleIndex- firstLevel].positiveComments[this.state.highlightedCommentIndex];

      }

      popupText = popupText.replace(/\n/g, '<br/>');
    }

    return (
      <div>
        {showName && 
          <label style={{ "fontWeight": 'bold'}}>{selectedCriteria.name}</label>
        }

          <div style={{ "position": "relative", "height": gridHeight + "px" }}>
            { popup && 
              <div style={{ transform: "translateY(-100%)", "whiteSpace": "nowrap", "padding": "2px", "zIndex": 100, "backgroundColor": "white", "border": "1px solid #cccccc", "fontSize": "12px", "position": "absolute", "top": popupTop, "left": popupLeft}}>
                <div style={{ "fontWeight": "bold" }}>{popupTitle}</div>
                <div dangerouslySetInnerHTML={{__html: popupText }} />
              </div>
            }

            <div style={{ "borderLeft": "1px solid red", "position": "absolute", "top": (Math.floor( (rowHeight - 5) / 2)) + "px", "left": Math.floor( midPoint) + "px", "height": lineHeight + "px" }}></div>
              { sortedScale.map((scaleEntry, index) => (
                <div key={index}>
                  { scaleEntry.negativeComments.map((comment, commentIndex) => (

                    <div key={commentIndex} 
                        className={ 'comment-symbol comment-symbol-negative' }
                        onMouseOver={this.mouseOverComment.bind(this, index + firstLevel, 'left', commentIndex)} 
                        onMouseOut={this.mouseOutComment.bind(this, index + firstLevel, 'left', commentIndex )}
                        onClick={this.mouseClickComment.bind(this, index + firstLevel, 'left', commentIndex)}

                        style={{ "top": ( (rowHeight - 10) / 2 + index * rowHeight) + "px", "left": Math.round(-10/2 + midPoint - (commentIndex + 1) * columnWidth) + "px" }}></div>
                  ))}

                  <div className="scale-point-symbol" 
                    style={{  "left": Math.round(-5/2 + midPoint) + "px", "top": (Math.floor( (rowHeight - 5) / 2 + index * rowHeight)) + "px" }}></div>

                  { scaleEntry.positiveComments.map((comment, commentIndex) => (
                    <div key={commentIndex} 
                        className={ 'comment-symbol' }
                        onMouseOver={this.mouseOverComment.bind(this, index + firstLevel, 'right', commentIndex)} 
                        onMouseOut={this.mouseOutComment.bind(this, index + firstLevel, 'right', commentIndex )}
                        onClick={this.mouseClickComment.bind(this, index + firstLevel, 'right', commentIndex)}
                        style={{ "top": ( (rowHeight - 10) / 2 + index * rowHeight) + "px", "left": Math.round(-10/2 + midPoint + (commentIndex + 1) * columnWidth) + "px" }}></div>
                  ))}
                </div>
              ))}
          </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(props => [
    {collection: 'criteria', storeAs: 'selectedCriteria' + props.id, doc: props.id },
    {collection: '/criteria/' + props.id + '/scale', storeAs: 'selectedCriteriaScale' + props.id}
  ]),
  connect( ({ firestore: { ordered }}, props) => ({
    selectedCriteria: ordered['selectedCriteria' + props.id] && ordered['selectedCriteria' + props.id][0],
    scale: ordered['selectedCriteriaScale' + props.id]
  }))
)(Criteria);