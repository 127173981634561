import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import spinner from '../layout/spinner2.gif';
import store  from '../../store';
import dataStore from '../../data/DataStore';

import { firebaseSendFeedbackResult } from '../../store';


class FeedbackList extends Component {
  assignmentListenerId = false;
  currentAssignmentId = false;

  state = {
    sendingMail: {},
    selectedFeedback: [],
    feedbackList: false
  };
  

  setAssignment(assignmentId) {
    if(this.currentAssignmentId !== assignmentId) {

      if(this.currentAssignmentId !== false) {
        let currentAssignment = dataStore.getAssignmentStore(this.currentAssignmentId);
        currentAssignment.removeListener(this.assignmentListenerId);
      }

      if(assignmentId !== false) {
        let assignment = dataStore.getAssignmentStore(assignmentId);

        this.assignmentListenerId = assignment.addListener(this);
        this.currentAssignmentId = assignmentId; 
        assignment.load();
      }

      this.setState({ assignmentId: assignmentId });
    }
  }

  onFeedbackListUpdate(feedbackList) {
    this.setState({ feedbackList: feedbackList });
  }
  
  editFeedback = (feedbackId, e) => {
    const assignmentId = this.props.assignmentId;
    var url = '/assignment/' + assignmentId + '/feedback/edit/' + feedbackId;
    this.props.history.push(url);

  }

  handleCheckbox = (feedbackId, e) => {
    const target = e.target;
    const value = target.checked;    

    let selectedFeedback = this.state.selectedFeedback.slice(0);
    if(value) {
      selectedFeedback.push(feedbackId);
    } else {
      let index = selectedFeedback.indexOf(feedbackId);
      if(index != -1) {
        selectedFeedback.splice(index, 1);
      }
    }

    this.setState({ selectedFeedback });

  } 

  deleteSelected = async (e) => {
    const selectedFeedback = this.state.selectedFeedback;

    e.preventDefault();
    if(!window.confirm("Are you sure you want to delete ?")) {
      return;
    }
    for(let i = 0; i < selectedFeedback.length; i++) {
      await this.deleteFeedback(selectedFeedback[i]);

    }
    this.setState( { selectedFeedback: [] });
    const assignmentId = this.props.assignmentId;
    let assignmentStore = dataStore.getAssignmentStore(assignmentId);
    assignmentStore.getFeedbackList();

  }  

  deleteFeedback = async (feedbackId, e) => {
    const assignmentId = this.props.assignmentId;
    let assignmentStore = dataStore.getAssignmentStore(assignmentId);
    await assignmentStore.deleteFeedback(feedbackId);
  }

  /*
  loadFeedbackList() {
    let assignmentStore = dataStore.getAssignmentStore(this.props.assignmentId);
    assignmentStore.getFeedbackList().then((results) => {
      this.setState({ feedbackList: results });
    });
  }
*/
  componentDidMount() {
//    this.loadFeedbackList();
    this.setAssignment(this.props.assignmentId);
  }
  componentDidUpdate(prevProps) {
    if(typeof this.props.assignmentId != 'undefined') {
      if(this.props.assignmentId !== prevProps.assignmentId) {
        //this.loadFeedbackList();
        this.setAssignment(this.props.assignmentId);
      }
    } 
  }

  componentWillUnmount() {

    this.setAssignment(false);

  }
  
  render() {
    const { assignmentId } = this.props;
    const { feedbackList } = this.state;

    if(!feedbackList) {
      return (
        <div>Loading...</div>
      );
    }

    let orderedFeedbackList =  feedbackList.slice(0);
    orderedFeedbackList.sort(function(a, b) {
      if(a.lastName == b.lastName) {
        return 0;
      }
      return (a.lastName > b.lastName) ? 1 : -1;
    });

    return (
      <div className="">
        <div style={{marginBottom: "12px"}}>
          <Link to={`/`}>&lt; Back to assignment list</Link>
        </div>

        <div style={{ "marginBottom": "20px"}}>
          <Link to={ '/assignment/addfeedback/' + assignmentId } className="btn btn-primary"><i className="fas fa-plus"></i> Add Student Feedback</Link>
          &nbsp; &nbsp;
          <Link to={ '/assignment/edit/' + assignmentId } className="btn btn-secondary"><i className="far fa-edit"></i> Edit Assignment Details</Link>
          &nbsp; &nbsp;
          <Link to={ '/assignment/tags/' + assignmentId } className="btn btn-secondary"><i className="fas fa-tags"></i> Tags</Link>
          &nbsp; &nbsp;
          { this.state.selectedFeedback.length > 0 && 
                  <button 
                    style={{ "marginLeft": "10px" }} 
                    className="btn btn-danger" 
                    onClick={this.deleteSelected}>
                    <i className="fas fa-trash"></i> Delete Selected
                    </button>
                }



        </div>


        { orderedFeedbackList.length > 0 && 

          <table id="feedback-list" className="table tableStriped table-clickable">
            <thead>
              <tr>
                { /*
                <th>Student Number</th>
                */ }
                <th style={{ "width": "20px" }}>&nbsp;</th>
                <th>Last Name</th>
                <th>First Name</th>
                <th style={{ "textAlign": "right" }} >Grade</th>
                
                <th style={{ "width": "80px"}}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              { orderedFeedbackList.map((entry, index) => (
                <tr key={entry.id} className={ (this.state.selectedFeedback.indexOf( entry.id ) != -1) ? "selected-table-row" : "" }>
                  { /*
                  <td><Link to={ '/assignment/feedback/edit/' + entry.id }>{entry.studentNumber}</Link></td>
                  */ }
                  <td style={{ "width": "20px" }}>

                  <label className="cb-container">
                    <input 
                      type="checkbox" 
                      value="1" 
                      checked={this.state.selectedFeedback.indexOf( entry.id ) != -1} 
                      onChange={this.handleCheckbox.bind(this, entry.id)}
                      />
                    <span className="checkmark"></span>     
                  </label>


                  </td>
                  <td onClick={this.editFeedback.bind(this, entry.id)}>{entry.lastName}</td>
                  { (entry.lastName !== '' || entry.firstName !== '') && 
                    <td onClick={this.editFeedback.bind(this, entry.id)}>{entry.firstName}</td>
                  }
                  { (entry.studentNumber === '' && entry.lastName === '' && entry.firstName === '') && 
                    <td onClick={this.editFeedback.bind(this, entry.id)}>(blank name)</td>
                  }
                  <td style={{ "textAlign": "right" }} onClick={this.editFeedback.bind(this, entry.id)}>{entry.grade}</td>
                  <td>
                    
                    <Link to={ '/assignment/' + assignmentId + '/feedback/view/' + entry.id } className="view-link"><i className="fas fa-eye"></i> View</Link>
                    
                  </td>    

                </tr>
              ))}
              
            </tbody>
          </table>
        }
      </div>
    );
  }
}


export default firestoreConnect()(FeedbackList);

/*
export default compose(
  firestoreConnect(['feedback']),
  connect((state) => ({
    feedback: state.firestore.ordered.feedback
  }))
)(FeedbackList);
*/