import React, { Component } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';

class Submission extends Component {

  state = {
  };

  render() {
    const submission = this.props.selectedSubmission;
    if(!submission) {
      return (
        <div>Loading...</div>
      )
    }


    return (
      <div>
        <Link to={`/assignment/${submission.assignmentId}`}>&lt; Back to assignment</Link>
        <p>
          {submission.greeting.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}          
        </p>

        {submission.criteriaLabels.map((entry, index) => (
          <p key={index}>
            <h3>{entry}</h3>
            <div>
              {submission.criteriaComments[index].split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })}              
            </div>
          </p>
        ))}


        <p>
          {submission.signoff.split('\n').map((item, key) => {
              return <span key={key}>{item}<br/></span>
            })}          
        </p>
      </div>
    );
  }
}


// if editing, id will be passed through params, how to know if invalid id?
// if adding, no id in params, 
export default compose(
  firestoreConnect(props => [
      { collection: 'submissions', storeAs: 'selectedSubmission' + props.match.params.id, doc: props.match.params.id },
    ]
  ),
  connect( ({ firestore: { ordered }}, props) => (
    {selectedSubmission: ordered['selectedSubmission' + props.match.params.id] && ordered['selectedSubmission' + props.match.params.id][0] }
  ))
)(Submission);

