import store, { firestore } from '../store';


//  users / { userId } / commentBanks / {commentBankId} / patterns / {}

// name
// description
// comment keys: array of comma separated keys for each criteria

class CommentBankPatternsStore {

  constructor(commentBankId) {
    this.commentBankId = commentBankId;

    this.listeners = [];

    this.patterns = [];
    /*
     each pattern: 
     {
       id : string,
       name : string,
       criteriaIds : array,
       commentKeys: array
     }
    */
   
    /*
    this.name = "";
    this.commentBank = [];
    this.criteriaIds = [];
    */
  }


  addListener = (listener) => {
    let id = this.generateKey();
    this.listeners.push({
      id: id,
      listener: listener
    });

    this.commentBankPatternsUpdated();

    return id;
  }

  removeListener = (listenerId) => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id === listenerId) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

  // update the listeners
  commentBankPatternsUpdated = () => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(typeof this.listeners[i].listener !== 'undefined'
        && typeof this.listeners[i].listener.onCommentBankPatternsUpdate !== 'undefined') {
          this.listeners[i].listener.onCommentBankPatternsUpdate({
            commentBankId: this.commentBankId,
            patterns: this.patterns
          });
        }
    }
  }  

  // load a comment bank from firestore
  load = async() => {
    const uid = store.getState().firebase.auth.uid;

    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const commentBankPatternsPath = commentBanksPath + '/' + this.commentBankId + '/patterns';

    let patterns = [];

    let results = await firestore.collection(commentBankPatternsPath).get();
    for(let i = 0; i < results.docs.length; i++) {
      let pattern = results.docs[i].data();
      patterns.push({
        id: results.docs[i].id,
        name: pattern.name,
        description: pattern.description,
        comments: pattern.comments
      });
    }

    this.patterns = patterns;

    
    // update the listeners
    this.commentBankPatternsUpdated();
  }

  addPattern = async(pattern) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const patternPath = commentBanksPath + '/' + this.commentBankId + '/patterns';
  
    let result = await firestore.collection(patternPath).add(pattern);
    let patternId = result.id;

    this.load();
    // need to reload everything...
  }

  deletePattern = async(patternId) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const patternPath = commentBanksPath + '/' + this.commentBankId + '/patterns';
    await firestore.collection(patternPath).doc(patternId).delete();

    this.load();
    // need to reload everything...

  }


  generateKey = () => {
    var lut = [];
  
    for ( var i = 0; i < 256; i ++ ) {
      lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }    
  
    var d0 = Math.random() * 0xffffffff | 0;
    var d1 = Math.random() * 0xffffffff | 0;
    var d2 = Math.random() * 0xffffffff | 0;
    var d3 = Math.random() * 0xffffffff | 0;
    var uuid = lut[ d0 & 0xff ] + lut[ d0 >> 8 & 0xff ] + lut[ d0 >> 16 & 0xff ] + lut[ d0 >> 24 & 0xff ] + '-' +
      lut[ d1 & 0xff ] + lut[ d1 >> 8 & 0xff ] + '-' + lut[ d1 >> 16 & 0x0f | 0x40 ] + lut[ d1 >> 24 & 0xff ] + '-' +
      lut[ d2 & 0x3f | 0x80 ] + lut[ d2 >> 8 & 0xff ] + '-' + lut[ d2 >> 16 & 0xff ] + lut[ d2 >> 24 & 0xff ] +
      lut[ d3 & 0xff ] + lut[ d3 >> 8 & 0xff ] + lut[ d3 >> 16 & 0xff ] + lut[ d3 >> 24 & 0xff ];
  
    return uuid.toUpperCase();  
  }  
}

export default CommentBankPatternsStore;  