import store, { firestore } from '../store';

class AssignmentStore {

  constructor(assignmentId) {
    this.assignmentId = assignmentId;

    this.feedbackList = false;
    this.commentBankId = false;
    this.rubricId = false;
    this.name = false;

    this.listeners = [];

  }

  addListener = (listener) => {
    let id = this.generateKey();
    this.listeners.push({
      id: id,
      listener: listener
    });

    if(this.feedbackList !== false) {
      this.feedbackListUpdated();
    }

    if(this.name !== false) {
      this.assignmentDetailsUpdated();
    }

    return id;
  }

  removeListener = (listenerId) => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id === listenerId) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

  // update the listeners
  feedbackListUpdated = () => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(typeof this.listeners[i].listener !== 'undefined'
        && typeof this.listeners[i].listener.onFeedbackListUpdate !== 'undefined') {
          this.listeners[i].listener.onFeedbackListUpdate(this.feedbackList);
        }
    }
  }

  assignmentDetailsUpdated = () => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(typeof this.listeners[i].listener !== 'undefined'
        && typeof this.listeners[i].listener.onAssignmentDetailsUpdate !== 'undefined') {
          this.listeners[i].listener.onAssignmentDetailsUpdate({
            id: this.assignmentId,
            commentBankId: this.commentBankId,
            rubricId: this.rubricId,
            name: this.name
          });
        }
    }
  }

  load = async() => {
    this.getAssignmentDetails();
    this.getFeedbackList();
  }

  getAssignmentDetails = async() => {
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;
    const assignmentsPath = 'users/' + uid + '/assignments';

    let results = await firestore.collection(assignmentsPath).doc(this.assignmentId).get();
    let assignmentData = results.data();    

    this.commentBankId = assignmentData.commentBankId;
    this.rubricId = assignmentData.rubricId;
    this.name = assignmentData.name;

    this.assignmentDetailsUpdated();
    

  }
  getFeedbackList = async() => {
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + this.assignmentId + '/feedback';

    let results = await firestore.collection(feedbackPath).get();

    this.feedbackList = [];
    for(var i = 0; i < results.docs.length; i++) {
      let feedback = results.docs[i].data();
      feedback.id = results.docs[i].id;
      this.feedbackList.push(feedback);
    }

    this.feedbackListUpdated();
  }

  deleteFeedback = async(feedbackId) => {
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + this.assignmentId + '/feedback';
    await firestore.collection(feedbackPath).doc(feedbackId).delete();
//    this.getFeedbackList();
  }


  generateKey = () => {
    var lut = [];
  
    for ( var i = 0; i < 256; i ++ ) {
      lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }    
  
    var d0 = Math.random() * 0xffffffff | 0;
    var d1 = Math.random() * 0xffffffff | 0;
    var d2 = Math.random() * 0xffffffff | 0;
    var d3 = Math.random() * 0xffffffff | 0;
    var uuid = lut[ d0 & 0xff ] + lut[ d0 >> 8 & 0xff ] + lut[ d0 >> 16 & 0xff ] + lut[ d0 >> 24 & 0xff ] + '-' +
      lut[ d1 & 0xff ] + lut[ d1 >> 8 & 0xff ] + '-' + lut[ d1 >> 16 & 0x0f | 0x40 ] + lut[ d1 >> 24 & 0xff ] + '-' +
      lut[ d2 & 0x3f | 0x80 ] + lut[ d2 >> 8 & 0xff ] + '-' + lut[ d2 >> 16 & 0xff ] + lut[ d2 >> 24 & 0xff ] +
      lut[ d3 & 0xff ] + lut[ d3 >> 8 & 0xff ] + lut[ d3 >> 16 & 0xff ] + lut[ d3 >> 24 & 0xff ];
  
    return uuid.toUpperCase();  
  }


}


export default AssignmentStore;

