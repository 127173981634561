import React, { Component } from 'react';
import spinner from '../layout/spinner2.gif';

class CommentBankEditor extends Component {
  state = {
    prompt: '',
    generatedComments: [],
    inProgress: false
  }
  goBack = (e) => {
  //      this.props.onSetStep(0);
    this.props.feedback.closeGeneratedComments();
  }

  onFinish = (e) => {
  //      this.props.onFinish();
    this.props.feedback.addGeneratedComments(this.props.criteriaId, this.state.generatedComments);
  }


  processGeneratedComments(text) {
    console.log('process generatedo comments');
    console.log(text);
    text = text.trim();
    let generatedComments = [];

    let index = 1;
    let lastComment = false;
    let commentStart = 0;

    if(text.indexOf('1.') != -1) {
      let count = 1;
      while(!lastComment) {
        index++;
        let commentEnd = text.indexOf(index + '.');
        if(commentEnd == -1) {
          commentEnd = text.length;
          lastComment = true;
        }
        let markerLength = (count + '.').length;
        let comment = text.substring(commentStart + markerLength, commentEnd).trim();
        generatedComments.push(
          comment
        );
        commentStart = commentEnd;
        count++;
      }
    } else {
      let comments = text.split('\n');
      for(let i = 0; i < comments.length; i++) {
        let comment = comments[i].trim();
        if(comment.length > 0) {
          generatedComments.push(comment);
        }
      }
    }


    this.setState({
      generatedComments: generatedComments
    });

  }

  async generate(e) {
    let debug = false;

    if(debug) {

      let response = [{"text":"\n\n1. Hi there!\n2. Howdy!\n3. Hey there!\n4. Whats up?\n5. Get lost.","index":0,"logprobs":null,"finish_reason":"stop"}];
      let text = response[0]['text'];
      this.processGeneratedComments(text);
      ///
      return;
    } else {

      this.setState({ inProgress: true });
      let data = { prompt: this.state.prompt };

      console.log("call generate");
      fetch("https://trial.headset.software/generateComments.php", {
      //  fetch("http://level3.jojati.com/headset/generateComments.php", {
        method: "POST",
        headers: {'Content-Type': 'application/json'}, 
        body: JSON.stringify(data)
      }).then(res => {
        console.log('response = ' + res);
        console.log(res);
        res.json().then(response => {
          console.log(response);
          let  text = response[0]['text'];
          this.setState({ inProgress: false });
          this.processGeneratedComments(text);
          console.log("Request complete! response:", res);
  
        });
//        let response = await res.json();
      });
    }

  }

  promptChanged = (e) => {
    this.setState({
      prompt: e.target.value
    });
  }

  componentDidMount() {

  }

  promptKeyUp(e) {
    let key = e.key;
    if(key == 'Enter') {
      this.generate(e);
    }
  }

  componentDidUpdate(prevProps) {
    if(typeof prevProps == 'undefined' || prevProps.criteriaId == false || prevProps.criteriaId != this.props.criteriaId) {
      console.log('new props' + this.props.criteriaId);
      this.setState({
        prompt: '',
        generatedComments: [],
        inProgress: false
      });
    }
  }  

  generatedCommentChange(index, e) {
    let generatedComments = [];
    for(let i = 0; i < this.state.generatedComments.length; i++) {
      generatedComments.push(this.state.generatedComments[i]);
    }
    generatedComments[index] = e.target.value;
    this.setState({ generatedComments: generatedComments });
  }
    
  render() {
    const generatedComments = this.state.generatedComments;
    return (
      <div style={{"position": "relative"}}>
        <h2>Generate Comments</h2>

        <div>
          <label>Prompt</label>
          {this.state.inProgress && 
          <div><img src={spinner} height="12"/>&nbsp;&nbsp;Generating...</div>
          }

          {!this.state.inProgress && 
          <div style={{"display":"flex"}}>
            <input type="text" style={{ "width":"360px"}} name="prompt" id="prompt" value={this.state.prompt} onChange={this.promptChanged.bind(this)} onKeyUp={this.promptKeyUp.bind(this)}/>
            <button type="button" onClick={this.generate.bind(this)} className="btn btn-sm" style={{ "marginRight": "6px" }}>Generate</button>
          </div>}
        </div>
        
        <div>
        { generatedComments.map( (comment, index) => (
          <div key={index}>
            <textarea style={{"marginTop": "10px", "width": "95%", "height": "80px" }} onChange={this.generatedCommentChange.bind(this, index)} value={comment}/>
          </div>
        ))}
        </div>

        <div style={{ "marginTop": "30px", "display": "flex" }}>
        <button type="button" onClick={this.goBack.bind(this)} className="btn btn-secondary">Cancel</button>
        { this.state.generatedComments.length > 0 && 
          <div>
          &nbsp;
          <button type="button" onClick={this.onFinish.bind(this)} className="btn btn-primary">Save</button>
          </div>
        }
        </div>
      </div>
    )
  }
}

export default CommentBankEditor;