import store, { firestore } from '../store';
import CommentBankStore from './CommentBankStore';
import CommentBankPatternsStore from './CommentBankPatternsStore';
import RubricStore from './RubricStore';
import AssignmentStore from './AssignmentStore';
import TagStore from './TagStore';

class DataStore {
  constructor() {
    this.commentBankMap = {};
    this.commentBankPatternsMap = {};
    this.rubricMap = {};
    this.assignmentMap = {};
  }

  getAssignmentList = async() => {
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;

    let results = await firestore.collection(userPath + '/assignments').get();
    
    let assignments = [];
    for(var i = 0; i < results.docs.length; i++) {
      let assignment = results.docs[i].data();
      assignment.id = results.docs[i].id;
      assignments.push(assignment);
    }

    return assignments;
  }


  getFeedbackList = async(assignmentId) => {
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + assignmentId + '/feedback';

    let results = await firestore.collection(feedbackPath).get();

    let feedbackList = [];
    for(var i = 0; i < results.docs.length; i++) {
      let feedback = results.docs[i].data();
      feedback.id = results.docs[i].id;
      feedbackList.push(feedback);
    }

    return feedbackList;
  }

  deleteFeedback = async(assignmentId, feedbackId) => {
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + assignmentId + '/feedback';
    await firestore.collection(feedbackPath).doc(feedbackId).delete();
  }

  getAssignmentStore = (assignmentId) => {
    if(this.assignmentMap.hasOwnProperty(assignmentId)) {
      return this.assignmentMap[assignmentId];
    }

    let assignment = new AssignmentStore(assignmentId);
    this.assignmentMap[assignmentId] = assignment;
    return assignment;

  }


  getRubricStore = (rubricId) => {
    if(this.rubricMap.hasOwnProperty(rubricId)) {
      return this.rubricMap[rubricId];
    }

    let rubric = new RubricStore(rubricId);
    this.rubricMap[rubricId] = rubric;
    return rubric;
  }

  getCommentBankStore = (commentBankId) => {

    if(this.commentBankMap.hasOwnProperty(commentBankId)) {
      return this.commentBankMap[commentBankId];
    }

    let commentBank = new CommentBankStore(commentBankId);
    this.commentBankMap[commentBankId] = commentBank;
    return commentBank;
  }

  getCommentBankPatternsStore = (commentBankId) => {
    if(this.commentBankPatternsMap.hasOwnProperty(commentBankId)) {
      return this.commentBankPatternsMap[commentBankId];
    }

    let commentBankPatterns = new CommentBankPatternsStore(commentBankId);
    this.commentBankPatternsMap[commentBankId] = commentBankPatterns;
    return commentBankPatterns;

  }

}

const dataStore = new DataStore();

export default dataStore;