import React, { Component } from 'react';

class CriteriaCreator extends Component {
  state = {
    formErrors: {}
  }

  finish = () => {
    this.props.onFinish(this.state);
  }

  onCancel = (e) => {
    this.props.onCancel();
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { criteria } = this.props;

    let formErrors = {};
    let hasError = false;

    if(criteria.name.trim() === '') {
      formErrors.criteria = 'Please enter a name for criteria';
      hasError = true;
    }
    if(criteria.scalePoints === 0) {
      formErrors.scalePoints = 'Please set scale points';
      hasError = true;
    }

    if(hasError) {
      this.setState({ formErrors });
    } else {
      this.setState({ formErrors });
      this.props.onSetStep(1);
    }

  }

  render() {
    let { formErrors } = this.state; 
    const { criteria } = this.props;
    return (
      <div>
        <h1>Criteria Creator</h1>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-8">
                <div className="form-group">
                    <label htmlFor="criteria">Criteria</label>
                    <input 
                        type="text" 
                        className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                        name="name" 
                        placeholder="" 
                        value={criteria.name} 
                        onChange={this.props.onChange}/>
                    { formErrors.name && 
                      <div  className="invalid-feedback">{formErrors.name}</div>
                    }
                </div>          

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea 
                        className="form-control" 
                        name="description" 
                        placeholder="" 
                        value={criteria.description} 
                        onChange={this.props.onChange}
                        rows="3"
                    />
                </div>          

                <div className="form-group">
                    <label htmlFor="scalePoints">Scale Points</label>
                    <div className="" style={{ paddingLeft: "0"}}>
                        <div style={{ display: "inline-block", "padding": ".75rem .75rem", "lineHeight": "1.5", "verticalAlign": "middle" }}><div className="circle-button" onClick={this.props.onDecreaseScalePoints}>-</div></div>
                        
                        <input
                            
                            min="0"
                            className={ 'form-control col-2 ' + ( formErrors.scalePoints ? ' is-invalid': '') } 
                            style={{ display: "inline-block", width: "60px" }}
                            name="scalePoints"
                            value={criteria.scalePoints}
                            onChange={this.props.onChangeScalePoints}/>
                        
                            <div style={{ "display": "inline-block", "padding": ".75rem .75rem", "lineHeight": "1.5", "verticalAlign": "middle" }}><div className="circle-button" onClick={this.props.onIncreaseScalePoints} >+</div></div>
                            { formErrors.scalePoints && 
                              <div  className="invalid-feedback">{formErrors.scalePoints}</div>
                            }

                    </div>                            
                    

                </div>          

            </div>
            <div className="col-md-4">
                <h3>{ criteria.name }&nbsp;</h3>

                <div> 
                { criteria.scaleLabels.map((scaleLabel, index) => (
                <div key={index} style={{ paddingBottom: "6px", "paddingLeft": "30px", position: "relative"}}>
                    { (criteria.scaleLabels.length === 1 ) 
                        ? <div style={{ width: "1px", borderLeft: "0px solid #ff0000", position: "absolute", left: "15px", top: "18px", "bottom": 0 }}></div>
                        : (index === 0) 
                        ? <div style={{ width: "1px", borderLeft: "1px solid #ff0000", position: "absolute", left: "15px", top: "18px", "bottom": 0 }}></div>
                        : (index === criteria.scaleLabels.length - 1)
                        ? <div style={{ width: "1px", borderLeft: "1px solid #ff0000", position: "absolute", left: "15px", top: "0", "bottom": "24px" }}></div>
                        : <div style={{ width: "1px", borderLeft: "1px solid #ff0000", position: "absolute", left: "15px", top: "0", "bottom": "0" }}></div>
                    }
                    <div className="scale-point-symbol" style={{ "top": "18px", "left": "13px" }}></div>
                    
                    <div style={{width: "90%", display: "inline-block"}}>
                        <input 
                                className="form-control"
                                name={ `scaleLabel${index}`} 
                                value={scaleLabel} 
                                onChange={ this.props.onChangeScaleLabel.bind(this, index)}
                                placeholder="name"
                            />
                    </div>
                </div>
                ))}
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <button className="btn btn-secondary" type="button" onClick={this.props.onCancel}>Cancel</button>
              &nbsp;
              <button className="btn btn-primary" type="submit">Next</button>
            </div>          
          </div>
        </form>
      </div>
    )
  }
}


export default CriteriaCreator;

