import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Criteria from '../criteria/Criteria';
import store  from '../../store';
import dataStore from '../../data/DataStore';
import spinner from '../layout/spinner2.gif';
import CommentBankEditor from '../criteria/CommentBankEditor';

function tempescapeRegExp(stringToGoIntoTheRegex) {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}


var g_header = null;

function setupScrollPage() {
  window.scrollTo(0, 0);

  var elements = document.getElementsByClassName('header'); 
  if(elements.length == 0) {
    return;
  }
  g_header = elements[0];
  window.addEventListener('scroll', feedbackScrollPage);

}

function feedbackScrollPage() {
  if(g_header == null) {
    return;
  }

  // Get the offset position of the navbar
  var sticky = g_header.offsetTop;
  
  if (window.pageYOffset > sticky) {
    g_header.classList.add("sticky");
    g_header.classList.add("container");
  } else {
    g_header.classList.remove("sticky");
    g_header.classList.remove("container");
  }  
}


class Feedback extends Component {

  saveInProgress = false;

  commentBankListenerId = false;
  commentBankPatternsListenerId = false;
  currentCommentBankId = false;

  rubricListenerId = false;
  currentRubricId = false;

  assignmentListenerId = false;
  currentAssignmentId = false;


  state = {
    formErrors: {},
    id: false,
    assignmentName: '',
    assignmentId: '',

    firstName: '',
    lastName: '',
    studentNumber: '',
    email: '',

    greeting: '',
    signoff: '',


    // array of { id, name, comments }
    commentBank: [],
    // the comment bank criteria ids in order
    commentBankCriteriaIds: [],

    commentBankId: false,
    commentBankName: '',

    hoverComment: '',
    hoverCommentName: '',


    // array of { id, name, ratings }
    rubric: [],

    // the rubric criteria ids in order
    rubricCriteriaIds: [],

    rubricId: false,
    rubricName: '',


    // the comments for the feedback
    // key is the criteriaId, value is the comment
    comments: {},
    ratings: {},
    grade: 0,

    // details about current comment being edited..
    editCriteriaId: false,
    editCommentIndex: false,

    // is a criteria level currently being added?
    addingCriteria: false,

    // is a rubric criteria level currently being added?
    addingRubricCriteria: false,

    // not used anymore??
    messages: [],
    greetingMessage: false,
    signoffMessage: false,


    // show which comments have been selected
    selectedComments: [],

    // tags used to replace content
    tags: [],
    addingTag: false,


    editCommentVisible: false,

    currentCommentBankPatternDescription: "",
    currentCommentBankPatternName: "", 
    saveCommentBankPatternVisible: false,

    commentBankData: [],
    commentBankPatternsData: [],


    copySuccess: '',

    generateCommentsFor: false
  }


  initNewFeedback() {
    let comments = {};
    let criteriaIds = [];
    for(let i = 0; i < this.state.commentBankCriteriaIds.length; i++) {
      comments[this.state.commentBankCriteriaIds[i]] = '';
//      criteriaIds.push(this.state.commentBankCriteriaIds[i]);
    }

    let newState = {
      id: false,
      firstName: '',
      lastName: '',
      studentNumber: '',
      email: '',
      comments: comments,
      ratings: {},
      grade: 0,    
      selectedComments: []  
    }
    // set the id of the feedback to false, then set the assignment
    this.setState(newState,  () => {
      this.getAssignmentDetails(this.props.match.params.assignmentId);
    });  
    return;//


  let state = {
    formErrors: {},
    id: false,
    assignmentName: '',
    assignmentId: '',

    firstName: '',
    lastName: '',
    studentNumber: '',
    email: '',

    greeting: '',
    signoff: '',
    // array of { id, name, comments }
    commentBank: [],
    // the comment bank criteria ids in order
    commentBankCriteriaIds: [],

    commentBankId: false,
/*
    // array of { id, name, comments }
    commentBank: [],
    // the comment bank criteria ids in order
    commentBankCriteriaIds: [],

    commentBankId: false,
    commentBankName: '',

    hoverComment: '',
    hoverCommentName: '',


    // array of { id, name, ratings }
    rubric: [],

    // the rubric criteria ids in order
    rubricCriteriaIds: [],

    rubricId: false,
    rubricName: '',
*/

    // the comments for the feedback
    // key is the criteriaId, value is the comment
    comments: {},
    ratings: {},
    grade: 0,
/*
    // details about current comment being edited..
    editCriteriaId: false,
    editCommentIndex: false,

    // is a criteria level currently being added?
    addingCriteria: false,

    // is a rubric criteria level currently being added?
    addingRubricCriteria: false,

    // not used anymore??
    messages: [],
    greetingMessage: false,
    signoffMessage: false,


    // show which comments have been selected
    selectedComments: [],
*/
    // tags used to replace content
    tags: [],
    addingTag: false,


    editCommentVisible: false,

    copySuccess: ''
  };

      // set the id of the feedback to false, then set the assignment
      this.setState(state,  () => {
        this.getAssignmentDetails(this.props.match.params.assignmentId);
      });    
  }

  /******************  ASSIGNMENT  ****************************/
  setAssignment(assignmentId) {
    if(this.currentAssignmentId !== assignmentId) {

      if(this.currentAssignmentId !== false) {
        let currentAssignment = dataStore.getAssignmentStore(this.currentAssignmentId);
        currentAssignment.removeListener(this.assignmentListenerId);
      }

      if(assignmentId !== false) {
        let assignment = dataStore.getAssignmentStore(assignmentId);

        this.assignmentListenerId = assignment.addListener(this);
        this.currentAssignmentId = assignmentId; 
        assignment.load();
      }

      this.setState({ assignmentId: assignmentId });
    }
  }

  
  onAssignmentDetailsUpdate(assignmentDetails) {

    this.setState({ 
      assignmentName: assignmentDetails.name, 
      assignmentId: assignmentDetails.id
    }, () => {
      this.getTags();

    });

    this.setCommentBank(assignmentDetails.commentBankId);

    if(typeof assignmentDetails.rubricId != 'undefined') {  
      this.setRubric(assignmentDetails.rubricId);
    }


  }

  /******************  END ASSIGNMENT **********************/
  
  

  doCancel = (e) => {
    e.preventDefault();
    this.props.history.push('/assignment/' + this.state.assignmentId);

  }

  onSubmit = (e) => {
    e.preventDefault();
  }


  submitFeedback = (e) => {
    this.saveInFirestore(false);
  }

  submitFeedbackAndView = (e) => {
    this.saveInFirestore('view');
  }

  newFeedback = (e) => {
    this.saveInFirestore('new');
  }

  saveInFirestore = (nextScreen) => {
    if(this.saveInProgress) {
      return;
    }

    const { firestore, history } = this.props;
    const assignmentId = this.state.assignmentId;
    const uid = store.getState().firebase.auth.uid;
    const assignmentPath = 'users/' + uid + '/assignments/' + assignmentId;


    var next = false;
    if(typeof nextScreen !== 'undefined') {
      next = nextScreen;
    }


    this.saveInProgress = true;

    const commentBank = this.state.commentBank;
    const comments = this.state.comments;

    const rubric = this.state.rubric;
    const ratings = this.state.ratings;

    let criteriaIds = [];
    for(let i = 0; i < this.state.commentBankCriteriaIds.length; i++) {
      criteriaIds.push(this.state.commentBankCriteriaIds[i]);
    }

    let rubricCriteriaIds = [];
    for(let i = 0; i < this.state.rubricCriteriaIds.length; i++) {
      rubricCriteriaIds.push(this.state.rubricCriteriaIds[i]);
    }

    
    let commentBankMap = {};
    for(let i = 0; i < commentBank.length; i++) {
      commentBankMap[commentBank[i].id] = commentBank[i];
    }

    let rubricMap = {};
    for(let i = 0; i < rubric.length; i++) {
      rubricMap[rubric[i].id] = rubric[i];
    }

    const data  = this.state;
    let feedback = {};

    feedback.assignmentId = data.assignmentId;
    feedback.commentBankId = data.commentBankId;
    if(typeof data.rubricId != 'undefined') {
      feedback.rubricId = data.rubricId;
    }

    feedback.firstName = data.firstName;
    feedback.lastName = data.lastName;
    feedback.studentNumber = data.studentNumber;
    feedback.email = data.email;
    feedback.grade = data.grade;
    feedback.criteriaIds = criteriaIds;
    feedback.selectedComments = data.selectedComments;
    feedback.comments = [];
    feedback.commentLabels = [];

    feedback.rubricCriteriaIds = rubricCriteriaIds;
    feedback.ratings = [];
    feedback.ratingLabels = [];


    for(let i = 0; i < criteriaIds.length; i++) {
      var comment = comments[criteriaIds[i]];
      if(typeof comment === 'undefined') {
        comment = '';
      }
      feedback.comments.push(comment);
      var label = commentBankMap[criteriaIds[i]].name;
      if(typeof label === 'undefined') {
        label = '';
      }
      feedback.commentLabels.push(label);
    }


    for(let i = 0; i < rubricCriteriaIds.length; i++) {
      var rating = ratings[rubricCriteriaIds[i]];
      if(typeof rating === 'undefined') {
        rating = '';
      }
      feedback.ratings.push(rating);

      var label = rubricMap[rubricCriteriaIds[i]].name;
      if(typeof label === 'undefined') {
        label = '';
      }
      feedback.ratingLabels.push(label);
    }

    var saveProgressElement = null;
    if(next === 'view' || next === 'new') {
      saveProgressElement = document.getElementById('progress-overlay');
    } else {
      saveProgressElement = document.getElementById('saving-overlay');
    }
    saveProgressElement.style.display = 'block';

    if(this.state.id === false) {
      // create new
      firestore.add({ collection: assignmentPath + '/feedback' }, feedback).then(  (result) => {
        let feedbackId = result.id;
//        let feedbackPath = 'assignments/' + assignmentId + '/feedback';
//        firestore.add({ collection: feedbackPath }, { feedbackId: feedbackId }).then(  (result) => {
          saveProgressElement.style.display = 'none';
          this.saveInProgress = false;
          //
          if(next === 'view') {
            window.scrollTo(0,0);

            history.push('/assignment/' + assignmentId + '/feedback/view/' + feedbackId);
          } else if(next === 'new') {
            window.scrollTo(0,0);
            this.initNewFeedback();
            history.push('/assignment/addfeedback/' + assignmentId);
            
          } else {
            this.setState({
              id: feedbackId
            });
          }
          /*
          else {
            history.push('/assignment/' + assignmentId);
          }
          */
//        });
      });
    } else {
      // update it

      firestore.update({ collection: assignmentPath + '/feedback', doc: this.state.id }, feedback).then( async (result) => {
        saveProgressElement.style.display = 'none';
        this.saveInProgress = false;
//        
        if(next === 'view') {
          window.scrollTo(0,0);
          history.push('/assignment/' + assignmentId + '/feedback/view/' + this.state.id);
        } else if(next === 'new') {
          window.scrollTo(0,0);
          this.initNewFeedback();
          history.push('/assignment/addfeedback/' + assignmentId);
        }
          
          /*
          else {
            history.push('/assignment/' + assignmentId);
          }
          */

      });  
    }
  }


  onChange = (e) => {
    
    this.setState({ [e.target.name]: e.target.value });
  }

  onBlur = (e) => {
    this.saveInFirestore();
  }


  /******************* START OF COMMENT BANK CODE ************************************/

  /*
  // load a comment bank from firestore
  getCommentBank(commentBankId) {

    var commentBank = dataStore.getCommentBankStore(commentBankId);

    commentBank.load().then( (result) => {
      //this.setState(result);
    });
  }
  */


  showAddComment = (criteriaId, e) => {
    this.setState({ currentEditComment: "", currentEditCommentName: "", editCommentIndex: false, editCriteriaId: criteriaId, editCommentVisible: true }, () => {
      document.getElementById('currentEditCommentName').focus();
    });

  }

  generateComments = (criteriaId, e) => {
    this.setState({ generateCommentsFor: criteriaId }, () => {

    });
  }
  // show the edit box for a comment in the comment bank
  editComment = (criteriaId, commentIndex, e) => {

    // get the current value of the comment from the store and set the edit box value to it
    let commentBank = this.state.commentBank.slice(0);    
    let currentComment = '';
    let currentCommentName = '';

    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        if(commentIndex >= commentBank[i].comments.length) {
          return;
        }
        currentComment = commentBank[i].comments[commentIndex];
        currentCommentName = commentBank[i].commentNames[commentIndex];
      }
    }

    
    this.setState({ editCriteriaId: criteriaId, editCommentIndex: commentIndex, currentEditComment: currentComment, currentEditCommentName: currentCommentName, editCommentVisible: true }, () => {
      document.getElementById('currentEditComment').focus();
    });
  }



  saveComment = (e) => {
    const criteriaId = this.state.editCriteriaId;
    const commentIndex = this.state.editCommentIndex;
    const comment = this.state.currentEditComment;
    const commentName = this.state.currentEditCommentName;
    const commentBankId = this.state.commentBankId;

    var commentBank = dataStore.getCommentBankStore(commentBankId);

    if(commentIndex === false) {
      commentBank.addAComment(criteriaId, comment, commentName);
      
    } else {
      commentBank.updateComment(criteriaId, commentIndex, comment, commentName );
    }


    this.setState({ currentEditComment: "", currentEditCommentName: "", editCriteriaId: 0, editCommentVisible: false });

  }
  cancelComment = (e) => {
    this.setState({ currentEditComment: "", currentEditCommentName: "", editCriteriaId: 0, editCommentVisible: false });

  }
  // set the comment bank for this feedback
  setCommentBank(commentBankId) {
    if(this.currentCommentBankId !== commentBankId) {

      if(this.currentCommentBankId !== false) {
        let currentCommentBank = dataStore.getCommentBankStore(this.currentCommentBankId);
        currentCommentBank.removeListener(this.commentBankListenerId);
      }

      if(this.commentBankPatternsListenerId !== false) {
        let currentCommentBankPatterns = dataStore.getCommentBankPatternsStore(this.currentCommentBankId);
        currentCommentBankPatterns.removeListener(this.commentBankPatternsListenerId);
      }

      if(commentBankId !== false) {
        let commentBank = dataStore.getCommentBankStore(commentBankId);

        this.commentBankListenerId = commentBank.addListener(this);
        this.currentCommentBankId = commentBankId; 
        commentBank.load();

        let commentBankPatterns = dataStore.getCommentBankPatternsStore(commentBankId);
        this.commentBankPatternsListenerId = commentBankPatterns.addListener(this);
        commentBankPatterns.load();
        
      }

      this.setState({ commentBankId: commentBankId });
    }
  }

  onCommentBankUpdate(commentBankData) {
    this.setState(commentBankData);
  }

  onCommentBankPatternsUpdate(commentBankPatternsData) {

    this.setState({ commentBankPatternsData:  commentBankPatternsData.patterns });

  }


  // add a comment to the comment bank
  addCommentToCriteria = (criteriaId, e) => {

    const { commentBankId } = this.state;

    // get the selected text to add
    let commentElement = document.getElementById('feedbackComment' + criteriaId);
    let selectedText = '';

    if (typeof commentElement.selectionStart != 'undefined')
    {    
        var startPosition = commentElement.selectionStart;
        var endPosition = commentElement.selectionEnd;
        selectedText = commentElement.value.substring(startPosition, endPosition);
    }    

    if(selectedText == '') {
      alert('Please select text to add');
      return;
    }

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.addAComment(criteriaId, selectedText, '');
  }

/*
  // show the edit box for a comment in the comment bank
  editComment = (criteriaId, commentIndex, e) => {

    // get the current value of the comment from the store and set the edit box value to it
    let commentBank = this.state.commentBank.slice(0);    
    let currentComment = '';
    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        if(commentIndex >= commentBank[i].comments.length) {
          return;
        }
        currentComment = commentBank[i].comments[commentIndex];
      }
    }

    this.setState({ editCriteriaId: criteriaId, editCommentIndex: commentIndex, currentEditComment: currentComment });
  }
*/

/*
  setComment = (criteriaId, commentIndex, e) => {
    let comment = e.target.value;    
    this.setState({ currentEditComment: comment });
  }

  setCommentName = (criteriaId, commentIndex, e) => {
    let comment = e.target.value;    
    this.setState({ currentEditCommentName: comment });

  }
*/
/*
  // close the edit comment box and save the comment
  closeEditComment = (criteriaId, commentIndex, e) => {
    //this.saveComments(criteriaId);
    const { commentBankId, currentEditComment, currentEditCommentName } = this.state;

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.updateComment(criteriaId, commentIndex, currentEditComment);

    this.setState({ editCriteriaId: false, editCommentIndex: false, currentEditComment: '' });
  }
*/

  // delete a comment in the comment bank
  deleteComment = (criteriaId, commentIndex, e) => {
    const { commentBankId } = this.state;

    if(window.confirm('Are you sure you want to delete?')) {
      var commentBank = dataStore.getCommentBankStore(commentBankId);
      commentBank.deleteComment(criteriaId, commentIndex);
    }
  }



  changeCriteriaName = (criteriaId, e) => {
    let commentBank = this.state.commentBank.slice(0);
    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        commentBank[i].name = e.target.value;
      }
    }

    this.setState({ commentBank });
  }

  blurCriteriaName = (criteriaId, e) => {
    const { commentBankId } = this.state;
    let criteriaName =  e.target.value;

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.updateCriteriaName(criteriaId, criteriaName);
  }

  showSaveCommentBankPattern = (e) => {

    this.setState( 
      { 
        currentCommentBankPatternDescription: "", 
        currentCommentBankPatternName: "", 
//        editCommentIndex: false, 
//        editCriteriaId: criteriaId, 
        saveCommentBankPatternVisible: true 
      }, 
      () => {
        document.getElementById('currentCommentBankPatternName').focus();
      }
    );

  }


  saveCommentBankPattern = (e) => {
    const { commentBankId, currentCommentBankPatternName, currentCommentBankPatternDescription } = this.state;

    if(currentCommentBankPatternName == '') {
      alert('Please enter a pattern name');
      return;
    }

    // get comment bank sets this back to false..    
//    this.setState({ "addingCommentBankPattern": true });

//    var commentBank = dataStore.getCommentBankStore(commentBankId);
//    commentBank.addCriteria({ "name": '' });
    this.setState( { 
      currentCommentBankPatternDescription: "", 
      currentCommentBankPatternName: "", 
      saveCommentBankPatternVisible: false 
    });

    let commentBankPatterns = dataStore.getCommentBankPatternsStore(commentBankId);
    commentBankPatterns.addPattern({
      name: currentCommentBankPatternName,
      description: currentCommentBankPatternDescription,
      comments: this.state.selectedComments
    })
    /*
    if(commentIndex === false) {
      commentBank.addAComment(criteriaId, comment, commentName);
      
    } else {
      commentBank.updateComment(criteriaId, commentIndex, comment, commentName );
    }
*/
  }

  cancelCommentBankPatternDialog = (e) => {
    this.setState( 
      { 
        currentCommentBankPatternDescription: "", 
        currentCommentBankPatternName: "", 
        saveCommentBankPatternVisible: false 
      }
    );
  }

  deleteCommentBankPattern = (patternId, e) => {
    const { commentBankId } = this.state;

    if(window.confirm('Are you sure you want to delete?')) {
      let commentBankPatterns = dataStore.getCommentBankPatternsStore(commentBankId);
      commentBankPatterns.deletePattern(patternId);
  
    }
  }

  selectCommentBankPattern = (patternId, e) => {
    const { commentBankPatternsData  } = this.state;


    let patternComments = false;
    for(let i = 0; i < commentBankPatternsData.length; i++) {
      if(commentBankPatternsData[i].id == patternId) {
        patternComments = commentBankPatternsData[i].comments;
        break;
      }
    }

    if(patternComments === false) {
      console.log('couldnt find pattern');
    }


    // now go through all the comments, select the correct ones
    let criteriaIds = this.state.commentBankCriteriaIds;
    
    let commentBank = [];
    let commentBankMap = {};
    if(this.state.commentBank != 'undefined') {
      commentBank = this.state.commentBank;

      if(commentBank !== false) {
        for(let i = 0; i < commentBank.length; i++) {
          commentBankMap[commentBank[i].id] = commentBank[i];
        }
      }
    }

    // make a copy of selected comments
    var selectedComments = this.state.selectedComments.slice(0);
    let commentText = {};
    for(let criteriaId in this.state.comments) {
      commentText[criteriaId] = this.state.comments[criteriaId];
    }



    for(let i = 0; i < criteriaIds.length; i++) {
      let criteriaId = criteriaIds[i];
      let comments = commentBankMap[criteriaId].comments;
      let commentKeys = commentBankMap[criteriaId].commentKeys;

      for(let j = 0; j < commentKeys.length; j++) {
        if(patternComments.indexOf(commentKeys[j]) != -1) {
          let commentKey = commentKeys[j];
          let comment = comments[j];
          // is it already selected
          var selectedCommentIndex = selectedComments.indexOf(commentKey);
          if(selectedCommentIndex === -1) {
            // not already selected, so select it
            selectedComments.push(commentKey);

            let currentComment = '';
            if( typeof commentText[criteriaId] != 'undefined') {
              currentComment = commentText[criteriaId];// commentElement.value;
              if(currentComment.length > 0 && currentComment[currentComment.length - 1] != "\n") {
                currentComment += " ";
              }
            }

            currentComment += comment;
            commentText[criteriaId] = currentComment;
        
          }

        }
      }
    }

    // save the new comments
    this.setState({ selectedComments, comments: commentText }, () => {
      // ok now save to firestore
      this.saveInFirestore(false);
    });

  }

  addCriteria = (e) => {
    const { commentBankId } = this.state;

    // get comment bank sets this back to false..    
    this.setState({ "addingCriteria": true });

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.addCriteria({ "name": '' });
  }

  moveCriteria = (criteriaId, direction, e) => {
    const { commentBankId } = this.state;

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.moveCriteria(criteriaId, direction);

  }

  deleteCriteria = (criteriaId, e) => {
    const { commentBankId } = this.state;


    if(!window.confirm('Are you sure you want to delete the set of comments?')) {
      return;
    }

    var commentBank = dataStore.getCommentBankStore(commentBankId);
    commentBank.deleteCriteria(criteriaId);
  }



  feedbackGripMouseDown = (criteriaId, e) => {
    console.log('feedback grip mouse down');
  } 

  /******************* END OF COMMENT BANK CODE ************************************/

//  this.mouseOverComment.bind(this, criteriaId, commentIndex)
  mouseOverComment = (criteriaId, commentIndex, e) => {

    const commentBank = this.state.commentBank;
  
    let criteriaComments = false;
    let criteriaCommentNames = false;
    let criteriaCommentKeys = false;

    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        criteriaComments = commentBank[i].comments;
        criteriaCommentNames = commentBank[i].commentNames;
        criteriaCommentKeys = commentBank[i].commentKeys;
        
        break;
      }
    }


    if(criteriaComments !== false && commentIndex < criteriaComments.length) {

      let comment = criteriaComments[commentIndex];
      let commentName = criteriaCommentNames[commentIndex];

      this.setState({ hoverComment: comment, hoverCommentName: commentName });
    }
    
  }

  mouseOutComment = (criteriaId, commentIndex, e) => {
    this.setState({ hoverComment: '', hoverCommentName: '' });
  }

  // comment has been clicked on, insert it..
  clickComment = (criteriaId, commentIndex, e) => {

    const commentBank = this.state.commentBank;
  
    // find the comments for the criteria Id
    let criteriaComments = false;
    let criteriaCommentKeys = false;

    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id == criteriaId) {
        criteriaComments = commentBank[i].comments;
        criteriaCommentKeys = commentBank[i].commentKeys;        
        break;
      }
    }


    if(criteriaComments !== false && commentIndex < criteriaComments.length) {
      // get the comment key by using the comment index
      var commentKey = '';
      if(criteriaCommentKeys !== false && commentIndex < criteriaCommentKeys.length) {
        commentKey = criteriaCommentKeys[commentIndex];
      }

      // find out if this comment is already selected
      // by looking in the selected comments
      var selectedComments = this.state.selectedComments.slice(0);

      // get the comment text
      let comment = criteriaComments[commentIndex];

      var selectedCommentIndex = selectedComments.indexOf(commentKey);
      if(selectedCommentIndex === -1) {
        // comment isnt in the selected comments, so add it
        // highlight the comment in blue by adding to selected comments
        selectedComments.push(commentKey);

        // not add in the text
        this.setState({ selectedComments }, () => {

          this.addContentToComment(criteriaId, comment);
        });
      } else {
        // remove the comment
        selectedComments.splice(selectedCommentIndex, 1);
        this.setState({ selectedComments }, () => {
          this.removeContentFromComment(criteriaId, comment);
        });
      }

    }

  }

  removeContentFromComment = (criteriaId, content) => {
    let commentElement = document.getElementById('feedbackComment' + criteriaId);
    var currentComment = commentElement.value;

    currentComment = currentComment.replace(content, '');
    let comments = this.state.comments;
    comments[criteriaId] = currentComment;

    this.setState({ comments }, () => {
      this.saveInFirestore(false);
    });  
  }

  addContentToComment = (criteriaId, content) => {
    let commentElement = document.getElementById('feedbackComment' + criteriaId);
    var currentComment = commentElement.value;

    // find where the cursor is, if there is text selected
    let selectionStart = commentElement.selectionStart;
    let selectionEnd = commentElement.selectionEnd;

    if (commentElement.selectionStart || commentElement.selectionStart === 0) {
      // Others
      var startPos = commentElement.selectionStart;
      var endPos = commentElement.selectionEnd;

      if(commentElement.selectionStart > 0 && currentComment[commentElement.selectionStart - 1] != '\n') {
        content = " " + content;
      }
      currentComment = commentElement.value.substring(0, startPos) +
      content +
        commentElement.value.substring(endPos, commentElement.value.length);
        selectionStart = startPos + content.length;
        selectionEnd = startPos + content.length;
    } else {


      if(currentComment.length > 0 && currentComment[currentComment.length - 1] != "\n") {
        currentComment += " ";
      }
      currentComment += content;
    }

//      currentComment += comment;
    let comments = this.state.comments;
    comments[criteriaId] = currentComment;



    this.setState({ comments }, () => {

      commentElement.selectionStart = selectionStart;
      commentElement.selectionEnd = selectionEnd;
      this.saveInFirestore(false);
    });    
  }

  // move this up into assignment details?
  getTags() {
    const { firestore } = this.props;
    const { assignmentId } = this.state;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';

    
    firestore.get({ collection: tagsPath }).then((results) => {  
      let messages = [];
      var tags = [];
      for(var i = 0; i < results.docs.length; i++) {
        var tag = results.docs[i].data();
        tags.push({
          "id": results.docs[i].id,
          "name": tag.name,
          "value": tag.value,
          "assignmentId": tag.assignmentId,
          "createdBy": tag.createdBy
        });
      }

      this.setState({ tags, "addingTag": false });
    });  
  }  


  // move this up into assignment details?
  addATag = (tagName) => {
    const { firestore } = this.props;
    const { assignmentId } = this.state;
    const uid = store.getState().firebase.auth.uid;
    const tagsPath = 'users/' + uid + '/assignments/' + assignmentId + '/tags';


    let tag = {
      name: tagName,
      assignmentId: assignmentId,
      value: '',
      createdBy: store.getState().firebase.auth.uid
    };

    this.setState({ "addingTag": true });
    //let commentBank = this.state.commentBank.slice(0);
    firestore.add({ collection: tagsPath }, tag).then(  (result) => {
      this.getTags();
    });
  }

  foundTag = (tag) => {
    const { tags } = this.state;


    if(this.state.addingTag) {
      return;
    }

    if(tag === 'Name' || tag === 'name') {
      return;
    }

    let foundTag = false;
    for(var i = 0; i < tags.length; i++) {
      if(tags[i].name == tag) {
        foundTag = true;
        break;
      }
    }

    if(!foundTag) {
      this.addATag(tag);
    }

  }

  checkTags = (value) => {
    var inTag = false;
    var currentTag = '';
    for(var i = 0; i < value.length; i++) {
      if(!inTag) {
        if(value[i] == '[') {
          inTag = true;
          currentTag = '';
        }
      } else {
        if(value[i] === ']') {
          inTag = false;
          this.foundTag(currentTag);
          currentTag = '';
        } else {
          currentTag += value[i];
        }
      }
    }
  }

  tagClick = (criteriaId, tag, e) => {
    this.addContentToComment(criteriaId, '[' + tag + ']');

  }

  feedbackCommentKeyUp = (criteriaId, e) => {
    if(e.key === ']') {
      this.checkTags(e.target.value);
    }

  }

  // a feedback comment has been updated..
  updateFeedbackComment = (criteriaId, e) => {
    let comment = e.target.value;
    let comments = this.state.comments;
    comments[criteriaId] = comment;
    this.setState({ comments });
  }


  // blur on a feedback comment
  feedbackCommentBlur = (criteriaId, e) => {
    this.saveInFirestore();
    this.checkTags(e.target.value);
  }



  /*************** START RUBRIC FUNCTIONS  ********************/

  changeRubricCriteriaName = (rubricCriteriaId, e) => {
    let rubric = this.state.rubric.slice(0);
    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == rubricCriteriaId) {
        rubric[i].name = e.target.value;
      }
    }

    this.setState({ rubric });
  }

  blurRubricCriteriaName = (rubricCriteriaId, e) => {
    const { firestore } = this.props;
    const { rubricId } = this.state;

    const uid = store.getState().firebase.auth.uid;
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + rubricId + '/rubricCriteria';

    let rubricCriteria = false;

    let rubric = this.state.rubric.slice(0);
    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == rubricCriteriaId) {
        rubricCriteria =  {
          "name": rubric[i].name
        }
      }
    }    

    if(rubricCriteria) {
      firestore.update({ collection: rubricCriteriaPath, doc: rubricCriteriaId }, rubricCriteria).then( async (result) => {

      });
    }    
  }



  addRubricCriteria = (e) => {
    const { rubricId } = this.state;
    this.setState({ "addingRubricCriteria": true });

    var rubric = dataStore.getRubricStore(rubricId);
    rubric.addCriteria({ name: '' });
  }

  addRubricCriteriaRating = (rubricCriteriaId, e) => {
    const { rubricId } = this.state;
    let rating = '';
    
    var rubric = dataStore.getRubricStore(rubricId);
    rubric.addRating(rubricCriteriaId, rating);
  }  



  // show the edit box for a comment in the comment bank
  editRating = (rubricCriteriaId, ratingIndex, e) => {

    let rubric = this.state.rubric;
    let rubricCriteria = false;
    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == rubricCriteriaId) {
        rubricCriteria = rubric[i];
        break;
      }
    }


    if(rubricCriteria && ratingIndex < rubricCriteria.ratings.length) {

      this.setState({ editRubricCriteriaId: rubricCriteriaId, editRatingIndex: ratingIndex, rubricEditRatingValue: rubricCriteria.ratings[ratingIndex] }, () => {
        let ratingElement = document.getElementById( rubricCriteriaId + '-' + ratingIndex);
    
        if(ratingElement) {
          ratingElement.focus();
        }
    
      });
    }
  }


  setRating = (rubricCriteriaId, ratingIndex, e) => {
    let rating = e.target.value;    

    this.setState({ rubricEditRatingValue: rating });

  }

  // close the edit comment box and save the comment
  closeEditRating = (rubricCriteriaId, ratingIndex, e) => {
//    this.saveRatings(rubricCriteriaId);

    const { rubricId, rubricEditRatingValue } = this.state;
    var rubric = dataStore.getRubricStore(rubricId);
    rubric.updateRating(rubricCriteriaId, ratingIndex, rubricEditRatingValue);

    this.setState({ editRubricCriteriaId: false, rubricIndex: false });
  }

  // delete a rating in the rubric
  deleteRating = (rubricCriteriaId, ratingIndex, e) => {

    const { rubricId } = this.state;

    var rubric = dataStore.getRubricStore(rubricId);
    rubric.deleteRating(rubricCriteriaId, ratingIndex);
  }




  moveRubricCriteria = (rubricCriteriaId, direction, e) => {
    const { rubricId } = this.state;

    var rubric = dataStore.getRubricStore(rubricId);
    rubric.moveCriteria(rubricCriteriaId, direction);

  }

  deleteRubricCriteria = (rubricCriteriaId, e) => {
    const { rubricId } = this.state;

    var rubric = dataStore.getRubricStore(rubricId);
    rubric.deleteCriteria(rubricCriteriaId);
  }



  // rubric score rating clicked on
  clickRating = (rubricCriteriaId, ratingIndex, e) => {

    const rubric = this.state.rubric;
  
    let rubricCriteriaRatings = false;
    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == rubricCriteriaId) {
        rubricCriteriaRatings = rubric[i].ratings;
        break;
      }
    }

    if(rubricCriteriaRatings !== false && ratingIndex < rubricCriteriaRatings.length) {
      let rating = rubricCriteriaRatings[ratingIndex];

      let ratings = this.state.ratings;
      ratings[rubricCriteriaId] = rating;

      this.setState({ ratings }, () => {
        this.saveInFirestore();
      });

    }
  }

  /*
  getRubric(rubricId) {
    var rubric = dataStore.getRubricStore(rubricId);
    rubric.load().then( (result) => {
      this.setState(result);
    });    
  }
  */


  // set the comment bank for this feedback
  setRubric(rubricId) {
    if(this.currentRubricId !== rubricId) {

      if(this.currentRubricId !== false) {
        let currentRubric = dataStore.getRubricStore(this.currentRubricId);
        currentRubric.removeListener(this.rubricListenerId);
      }

      if(rubricId !== false) {
        let rubric = dataStore.getRubricStore(rubricId);

        this.rubricListenerId = rubric.addListener(this);
        this.currentRubricId = rubricId; 
        rubric.load();
      }

      this.setState({ rubricId: rubricId });
    }
  }

  onRubricUpdate(rubricData) {
    this.setState(rubricData);
  }

  /*************** END RUBRIC FUNCTIONS  ********************/

  // messages are for personalised greeting and signoff (not really used anymore?)
  getMessages() {
    alert('get messages');
    const { firestore } = this.props;

    firestore.get({ collection: 'messages', where: ["createdBy", "==", store.getState().firebase.auth.uid ] }).then((results) => {  
      let messages = [];
      for(var i = 0; i < results.docs.length; i++) {
        var message = results.docs[i].data();
        messages.push({
          "name": message.name,
          "type": message.type,
          "text": message.text
        });
      }

      this.setState({ messages });
    });  
  }

  // get details of an assignment
  // if no feedback has been selected, need to get the rubric and comment bank from the assignment
  getAssignmentDetails(assignmentId) {
    this.setState({ assignmentName: '', addingTag: true });
    this.setAssignment(assignmentId);
  }

  getFeedbackDetails(assignmentId, feedbackId) {
    const { firestore  } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const feedbackPath = 'users/' + uid + '/assignments/' + assignmentId + '/feedback';

    this.setAssignment(assignmentId);

    firestore.get({ collection: feedbackPath, doc: feedbackId }).then((result) => {
      let feedback = result.data();

      let comments = [];
      for(let i = 0; i < feedback.criteriaIds.length; i++) {
        comments[feedback.criteriaIds[i]] = feedback.comments[i];
      }

      let ratings = [];

      if(typeof feedback.rubricCriteriaIds !== 'undefined' && typeof feedback.ratings != 'undefined') {
        for(let i = 0; i < feedback.rubricCriteriaIds.length; i++) {
          if(i < feedback.ratings.length) {
            ratings[feedback.rubricCriteriaIds[i]] = feedback.ratings[i];
          }

        }
      }
/*
      let rubricId = false;
      if(typeof feedback.rubricId !== 'undefined') {
        rubricId = feedback.rubricId;
      }
*/

      let selectedComments = [];
      if(typeof feedback.selectedComments != 'undefined') {
        selectedComments = feedback.selectedComments;
      }
      // set adding tag to true so it doesnt try to create one while getting tags
      this.setState({ 
        id: feedbackId,
        assignmentId: feedback.assignmentId, 
//        commentBankId: feedback.commentBankId,
//        rubricId: rubricId,
        firstName: feedback.firstName,
        lastName: feedback.lastName,
        studentNumber: feedback.studentNumber,
        email: feedback.email,
        grade: feedback.grade,
        comments: comments,
        selectedComments: selectedComments,
        ratings: ratings,
        addingTag: true
      });

    });

  }

  
  componentDidMount() {
    
    this.setState({ name: '' })

    if(typeof this.props.match.params.id != 'undefined') {
      this.getFeedbackDetails(this.props.match.params.assignmentId, this.props.match.params.id);
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {

      // set the id of the feedback to false, then set the assignment
      this.setState({ id: false }, () => {
        this.getAssignmentDetails(this.props.match.params.assignmentId);
      });
    }

    console.log('component did mount');

    /*
    window.onscroll = function() {
      console.log('scroll');
    }
*/  
    setupScrollPage();

  }

  componentDidUpdate(prevProps) {

    if(typeof this.props.match.params.id != 'undefined') {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        this.getFeedbackDetails(this.props.match.params.assignmentId, this.props.match.params.id);
      }
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {
      if (this.props.match.params.assignmentId !== prevProps.match.params.assignmentId) {
        // set the id of the feedback to false, then set the assignment
        this.setState({ id: false }, () => {
          this.getAssignmentDetails(this.props.match.params.assignmentId);
        });
      }

    }
  }

  componentWillUnmount() {
    
    this.setCommentBank(false);
    this.setRubric(false);
    this.setAssignment(false);

    console.log('remove event listener');
    window.removeEventListener('scroll', feedbackScrollPage);
  }


  getFeedback = () => {
    let feedback = '';

    const { comments, commentBankCriteriaIds, tags } = this.state;
    let commentBank = [];
    let commentBankMap = {};


    if(this.state.commentBank != 'undefined') {
      commentBank = this.state.commentBank;
  
      if(commentBank !== false) {
        for(let i = 0; i < commentBank.length; i++) {
          commentBankMap[commentBank[i].id] = commentBank[i];
        }
      }
    }
  
    let rubricCriteriaIds = this.state.rubricCriteriaIds;
    if(typeof rubricCriteriaIds === 'undefined') {
      rubricCriteriaIds = [];
    }

    let rubric = [];
    let rubricMap = {};
    if(this.state.rubric != 'undefined') {
      rubric = this.state.rubric;
      for(let i = 0; i < rubric.length; i++) {
        rubricMap[rubric[i].id] = rubric[i];
      }
    }

    for(let i = 0; i < rubricCriteriaIds.length; i++) {
      let rubricCriteriaId = rubricCriteriaIds[i];
      if(rubricMap[rubricCriteriaId].name.length > 0 && this.state.ratings[rubricCriteriaId].length > 0) {
        feedback += rubricMap[rubricCriteriaId].name + ': ';
        feedback += this.state.ratings[rubricCriteriaId];

      }
      feedback += '\n';
    }
    feedback += '\n\n';



    //{ criteriaIds.map( (criteriaId, index) => (

    for(let i = 0; i < commentBankCriteriaIds.length; i++) {
      let criteriaId = commentBankCriteriaIds[i];
      let content = comments[criteriaId];
      let heading = commentBankMap[criteriaId].name;

      if(heading !== 'Greeting' && heading !== 'Signoff') {
        feedback += heading + '\n\n';
      }

      content = content.replace(/\[Name\]/g, this.state.firstName);
      content = content.replace(/\(Name\)/g, this.state.firstName);
      content = content.replace(/\[name\]/g, this.state.firstName);
      content = content.replace(/\(name\)/g, this.state.firstName);


      for(let j = 0; j < tags.length; j++) {
        var replaceTag = tempescapeRegExp('[' + tags[j].name + ']');
        var regex = new RegExp(replaceTag, "ig");
        content = content.replace(regex, tags[j].value);
      }



      feedback += content;
      feedback += '\n\n';
    }


    feedback = feedback.trim();
    return feedback;
  }

  copyFeedbackToClipboard = (e) => {
    e.preventDefault();

    let feedback = this.getFeedback();

    this.textArea.value = feedback;

    this.textArea.select();
    
    document.execCommand('copy');
    e.target.focus();

    this.setState({ copySuccess: 'Copied!' });
    setTimeout(() => {
      this.setState({ copySuccess: '' });
    }, 1000);


  }

  closeGeneratedComments() {
    this.setState({
      generateCommentsFor: false
    });
  }

  addGeneratedComments(criteriaId, comments) {
    const commentBankId = this.state.commentBankId;

    var commentBank = dataStore.getCommentBankStore(commentBankId);
//    commentBank.addAComment(criteriaId, selectedText, '');

    for(let i = 0; i < comments.length; i++) {
      let comment = comments[i].trim();
      if(comment != '') {
        commentBank.addAComment(criteriaId, comment, '');
      }
    }

    this.closeGeneratedComments();
  }

  render() {
    const { formErrors, tags, commentBankPatternsData } = this.state;

    let criteriaIds = this.state.commentBankCriteriaIds;
    
    let commentBank = [];
    let commentBankMap = {};
    if(this.state.commentBank != 'undefined') {
      commentBank = this.state.commentBank;

      if(commentBank !== false) {
        for(let i = 0; i < commentBank.length; i++) {
          commentBankMap[commentBank[i].id] = commentBank[i];
        }
      }
    }

    //console.log(commentBankMap);

    let rubricCriteriaIds = this.state.rubricCriteriaIds;

    if(typeof rubricCriteriaIds === 'undefined') {
      rubricCriteriaIds = [];
    }
    let rubric = [];
    let rubricMap = {};
    if(this.state.rubric != 'undefined') {
      rubric = this.state.rubric;

      for(let i = 0; i < rubric.length; i++) {
        rubricMap[rubric[i].id] = rubric[i];
      }
    }



    return (
      <div style={{ "marginBottom": "20px" }}>


        {this.state.saveCommentBankPatternVisible &&
          <div>
          <div style={{ "position": "fixed", "top": 0, "right": 0, "left": 0, "bottom": 0, "backgroundColor": "black", "zIndex": 1000, "opacity": 0.4 }}></div>
          <div style={{ "position": "fixed", "left": "calc(50% - 165px)", "top": "20px", "width": "370px", "height": "220px", "zIndex": 1001, "backgroundColor": "#fafafa" }}>
            <input 
              className="form-control"
              type="text" 
              placeholder="Pattern Name" 
              name="currentCommentBankPatternName"
              id="currentCommentBankPatternName"
              value={this.state.currentCommentBankPatternName}
              onChange={this.onChange}
              style={{ "position": "absolute", "top": "10px", "left": "10px", "width": "auto" }}
            />
            <textarea 
              id="currentCommentBankPatternDescription"
              name="currentCommentBankPatternDescription"
              placeholder="Description"
              value={this.state.currentCommentBankPatternDescription} 
              onChange={this.onChange} 
              style={{ "position": "absolute", "top": "56px", "left": "10px", "right": "10px", "bottom": "50px" }}
              rows="14" 
              cols="40"
              ></textarea>
            <div style={{ "position": "absolute", "bottom": "10px", "left": "10px", "right": "10px", "height": "30px", "textAlign": "right" }}>
              <button onClick={this.saveCommentBankPattern} className="btn btn-primary">OK</button>
              &nbsp;&nbsp;
              <button onClick={this.cancelCommentBankPatternDialog} className="btn">Cancel</button>
            </div>
          </div>
        </div>
      }

        {this.state.editCommentVisible && 
          <div>
            <div style={{ "position": "fixed", "top": 0, "right": 0, "left": 0, "bottom": 0, "backgroundColor": "black", "zIndex": 1000, "transition": "0.4s", "opacity": 0.4 }}></div>
            <div style={{ "position": "fixed", "left": "calc(50% - 165px)", "top": "20px", "width": "370px", "height": "220px", "zIndex": 1001, "backgroundColor": "#fafafa" }}>
              <input 
                className="form-control"
                type="text" 
                placeholder="Comment Name" 
                name="currentEditCommentName"
                id="currentEditCommentName"
                value={this.state.currentEditCommentName}
                onChange={this.onChange}
                style={{ "position": "absolute", "top": "10px", "left": "10px", "width": "auto" }}
              />
              <textarea 
                id="currentEditComment"
                name="currentEditComment"
                placeholder="Comment"
                value={this.state.currentEditComment} 
                onChange={this.onChange} 
                style={{ "position": "absolute", "top": "56px", "left": "10px", "right": "10px", "bottom": "50px" }}
                rows="14" 
                cols="40"
                ></textarea>
              <div style={{ "position": "absolute", "bottom": "10px", "left": "10px", "right": "10px", "height": "30px", "textAlign": "right" }}>
                <button onClick={this.saveComment} className="btn btn-primary">OK</button>
                &nbsp;&nbsp;
                <button onClick={this.cancelComment} className="btn">Cancel</button>
              </div>
            </div>
          </div>
        }


        <div className="header">
          <div className="row">
            <div className="col-md-8">
              <h1>{this.state.firstName}&nbsp;{this.state.lastName}</h1>
              <div className="feedback-studentName">
                { this.state.assignmentName}
                
              </div>
              <Link to={`/assignment/${this.state.assignmentId}`}>&lt; Back to feedback list</Link>
            </div>
            <div className="col-md-4 text-md-right">
              <button type="button" style={{ marginTop: "4px" }}onClick={this.newFeedback} className="btn btn-primary" ><i className="fas fa-plus"></i> New Feedback</button>          
            </div>
          </div>
        </div>

        <div className="content">

          <form onSubmit={this.onSubmit}>
            <div className="feedback-section">
              <h2>Student Details</h2>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    { /*
                    <label htmlFor="firstName">First Name</label>
                    */ }
                    <input 
                      type="text"
                      name="firstName" 
                      id="firstName"
                      value={this.state.firstName}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      className="form-control"
                      placeholder="First Name"
                    />
                  </div>    
                </div>
                <div className="col-md-4">        
                  <div className="form-group">
                    {/* 
                    <label htmlFor="firstName">Last Name</label>
                    */ }
                    <input 
                      type="text"
                      name="lastName" 
                      id="lastName"
                      placeholder="Last Name"
                      value={this.state.lastName}
                      onChange={this.onChange}
                      onBlur={this.onBlur}

                      className="form-control" 
                    />
                  </div>          

                  { /*  
                  <div className="form-group">
                    <label htmlFor="studentNumber">Student Number</label>
                    <input 
                      type="text" 
                      name="studentNumber"
                      id="studentNumber"
                      value={this.state.studentNumber}
                      onChange={this.onChange}
                      onBlur={this.onBlur}

                      className="form-control" 
                    />
                  </div>            
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                      name="email"
                      id="email"
                      type="email" 
                      value={this.state.email}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      className="form-control" 
                    />
                  </div>  
                  */ }          
                </div>
              </div>
            </div>



            { this.state.rubricId &&
              <div className="feedback-section">
                <h2>Rubric Score</h2>

                { rubricCriteriaIds.map( (rubricCriteriaId, index) => (
                  <div key={index} style={{ "marginBottom": "12px"}}>

                    <div className="row">
                      <div className="col-md-5">            
                        <input 
                          type="text" 
                          className="form-control" 
                          style={{ "width": "calc(100% - 130px)", "display": "inline-block" }} 
                          value={ rubricMap[rubricCriteriaId].name } 
                          onChange={this.changeRubricCriteriaName.bind(this, rubricCriteriaId)} 
                          onBlur={this.blurRubricCriteriaName.bind(this, rubricCriteriaId)}/>

                        
                        <button type="button" title="Move Down" style={{ float: "right", marginRight: "2px" }} className="btn" onClick={this.moveRubricCriteria.bind(this, rubricCriteriaId, 1)}>
                          <i className="fas fa-angle-down"></i>
                        </button>

                        <button type="button" title="Move Up" style={{ float: "right", marginRight: "2px" }} className="btn" onClick={this.moveRubricCriteria.bind(this, rubricCriteriaId, -1)}>
                          <i className="fas fa-angle-up"></i>
                        </button>

                        <button type="button" style={{ float: "right", marginRight: "2px" }} className="btn" onClick={this.deleteRubricCriteria.bind(this, rubricCriteriaId )}>
                          <i className="far fa-trash-alt"></i>
                        </button>


                      </div>
                      <div className="col-md-7" style={{ "position": "relative" }}>            


                      { rubricMap[rubricCriteriaId].ratings.map((rating, ratingIndex) => (

                        <div 
                          key={ratingIndex} 
                          className="rating-dropdown"
                        >
                          <div 
                            className={ 
                              'rating-button' +  
                              ((this.state.ratings[rubricCriteriaId] === rating) ? ' rating-button-selected' : '')
                            }
                            onClick={this.clickRating.bind(this, rubricCriteriaId, ratingIndex)}
                          />

                          { this.state.editRubricCriteriaId === rubricCriteriaId && ratingIndex === this.state.editRatingIndex && 
                            <div className="rating-dropdown-editcontent">
                            { /*
                              <textarea value={rating} onChange={this.setRating.bind(this, rubricCriteriaId, ratingIndex)} rows="6" cols="60"></textarea>
                            */ }
                              <label>Rating: 
                                <input size="20" id={ rubricCriteriaId + '-' + ratingIndex } value={this.state.rubricEditRatingValue} onChange={this.setRating.bind(this, rubricCriteriaId, ratingIndex)}/>
                              </label>
                              <div>
                                <button type="button" onClick={this.closeEditRating.bind(this, rubricCriteriaId, ratingIndex)} className="btn btn-sm" style={{ "marginRight": "6px" }}>Close</button>
                              </div>
                            </div>
                          }

                          { (this.state.editRubricCriteriaId !== rubricCriteriaId || this.state.editRatingIndex != ratingIndex) && 
                            <div className="rating-dropdown-content">
                              <div style={{"whiteSpace": "nowrap"}}>
                              <div dangerouslySetInnerHTML={{__html: rating.replace(/\n/g, '<br/>') }} />
                              </div>

                              <div style={{ "marginTop": "6px" }}>
                                <button type="button" onClick={this.editRating.bind(this, rubricCriteriaId, ratingIndex)} className="btn btn-sm" style={{ "marginRight": "6px" }}>Edit</button>
                                <button type="button" onClick={this.deleteRating.bind(this, rubricCriteriaId, ratingIndex)} className="btn btn-sm" data-toggle="tooltip"  data-placement="top" title="Delete rating from the rubric">Delete</button>
                              </div>
                            </div>
                          }



                        </div>
                        ))}

                        <button 
                          type="button" 
                          className="btn btn-primary" 
                          onClick={this.addRubricCriteriaRating.bind(this, rubricCriteriaId, 1)}
                          data-toggle="tooltip" 
                          data-placement="top" 
                          title="Add a rating to the rubric criteria"
                          style={{ "marginTop": (rubricMap[rubricCriteriaId].ratings.length > 0 ? "-14px" : "0px") }}            
                          ><i className="fas fa-plus"></i></button>

                      </div>

                    </div>


                    <div className="row">
                      <div className="col-md-12">     
                          Rating: &nbsp;
                          <strong>{ this.state.ratings[rubricCriteriaId] }</strong>
                      </div>
                    </div>


                  </div>
                ))}


                { this.state.addingRubricCriteria && 
                  <div>
                    <div><img src={spinner} height="12"/>&nbsp;&nbsp;Adding...</div>
                    <button type="button" className="btn btn-primary disabled" >+</button>
                  </div>
                }

                { !this.state.addingRubricCriteria && 
                <button 
                  type="button" 
                  className="btn btn-primary" 
                  onClick={this.addRubricCriteria}
                  data-toggle="tooltip" 
                  data-placement="top" 
                  title="Add criteria to the rubric"            
                  ><i className="fas fa-plus"></i> Add Rubric Criteria</button>
                }

              </div>
            }


            <div  className="feedback-section">
              <h2>Feedback comment bank</h2>

              {this.state.generateCommentsFor !== false && 
                <div>
                  <div style={{ "position": "fixed", "top": 0, "right": 0, "left": 0, "bottom": 0, "backgroundColor": "black", "zIndex": 1000, "transition": "0.4s", "opacity": 0.4 }}></div>
                  <div style={{ "position": "fixed", "left": "calc(50% - 250px)", "top": "20px", "padding": "20px", "width": "500px", "overflowY":"auto","minHeight": "420px", "maxHeight": "600px", "zIndex": 1001, "backgroundColor": "#fafafa" }}>
            
                    <CommentBankEditor criteriaId={this.state.generateCommentsFor} feedback={this}/>
                  </div>
                </div>
              }

              { criteriaIds.length === 0 && 
                <div style={{ marginBottom: "12px" }}>
                  <div style={{ fontWeight: 500 }}> <i className="fas fa-exclamation-circle"></i>&nbsp;Your feedback comment bank is currently empty.</div><br/>
                  You can use the 'Add Feedback Criteria' button to add a feedback criteria section.
                </div>
              }



              { criteriaIds.length !== 0 &&
                <div>
                  <div className="row">
                    <div className="col-md-6">

                  { criteriaIds.map( (criteriaId, index) => (
                    <div key={index} style={{ "marginBottom": "20px"}}>

                      <div className="row feedback-row">
                        <div className="col-md-6"> 
                          { /*

                          <div className="feedback-row-grip" onMouseDown={this.feedbackGripMouseDown.bind(this, criteriaId, 1)}>
                            <i className="fas fa-grip-vertical"></i>
                          </div>

                          */ }

                          <input placeholder="Feedback Criteria Name" type="text" className="form-control" value={ commentBankMap[criteriaId].name } onChange={this.changeCriteriaName.bind(this, criteriaId)} onBlur={this.blurCriteriaName.bind(this, criteriaId)}/>

                          { /* comment criteria up down delete button */ }

                          <div className="feedback-row-buttons">
                            <button type="button" title="Move Down" className="btn" onClick={this.moveCriteria.bind(this, criteriaId, 1)}>
                              <i className="fas fa-angle-down"></i>
                            </button>

                            <button type="button" title="Move Up" className="btn" onClick={this.moveCriteria.bind(this, criteriaId, -1)}>
                              <i className="fas fa-angle-up"></i>
                            </button>

                            <button type="button" className="btn" onClick={this.deleteCriteria.bind(this, criteriaId)}>
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>

                        </div>
                        <div className="col-md-6">            
                          
                            { commentBankMap[criteriaId].comments.map((comment, commentIndex) => (

                              <div 
                                key={commentIndex} 
                                className="comment-dropdown"
                              >

                                { 
                                  ( typeof commentBankMap[criteriaId].commentKeys === 'undefined' ||
                                    commentIndex >= commentBankMap[criteriaId].commentKeys.length ||
                                    this.state.selectedComments.indexOf(commentBankMap[criteriaId].commentKeys[commentIndex]) === -1) &&
                                    <div 
                                      className="comment-button"
                                      onMouseOver={this.mouseOverComment.bind(this, criteriaId, commentIndex)}
                                      onMouseOut={this.mouseOutComment.bind(this, criteriaId, commentIndex)}
                                      onClick={this.clickComment.bind(this, criteriaId, commentIndex)}
                                    />
                                }

                                { 
                                  ( typeof commentBankMap[criteriaId].commentKeys !== 'undefined' &&
                                    commentIndex < commentBankMap[criteriaId].commentKeys.length &&
                                    this.state.selectedComments.indexOf(commentBankMap[criteriaId].commentKeys[commentIndex]) !== -1) &&
                                    <div 
                                      className="comment-button"
                                      style={{ backgroundColor: "blue" }}
                                      onMouseOver={this.mouseOverComment.bind(this, criteriaId, commentIndex)}
                                      onMouseOut={this.mouseOutComment.bind(this, criteriaId, commentIndex)}

                                      onClick={this.clickComment.bind(this, criteriaId, commentIndex)}
                                    />
                                }
      { /*
                                { this.state.editCriteriaId === criteriaId && commentIndex === this.state.editCommentIndex && 
                                  <div className="comment-dropdown-editcontent">
                                    <textarea value={this.state.currentEditComment} onChange={this.setComment.bind(this, criteriaId, commentIndex)} rows="6" cols="60"></textarea>
                                    <div>
                                      <button type="button" onClick={this.closeEditComment.bind(this, criteriaId, commentIndex)} className="btn btn-sm" style={{ "marginRight": "6px" }}>Close</button>
                                    </div>
                                  </div>
                                }
                              */}
                                { (this.state.editCriteriaId !== criteriaId || this.state.editCommentIndex != commentIndex) && 
                                  <div className="comment-dropdown-content">

                                    {/*
                                    <div style={{"whiteSpace": "nowrap"}}>


                                    <div dangerouslySetInnerHTML={{__html: comment.replace(/\n/g, '<br/>') }} />
                                    </div>
                                    */}
                                    <div>
                                      <button type="button" onClick={this.editComment.bind(this, criteriaId, commentIndex)} className="btn btn-sm" style={{ "marginRight": "6px" }}><i className="fas fa-pen"></i></button>
                                      <button type="button" onClick={this.deleteComment.bind(this, criteriaId, commentIndex)} className="btn btn-sm" data-toggle="tooltip"  data-placement="top" title="Delete criteria from the comment bank"><i className="far fa-trash-alt"></i></button>
                                    </div>
                                  </div>
                                }


                              </div>
                            ))}

                            <button 
                              type="button" 
                              className="btn btn-primary" 
                              onClick={this.showAddComment.bind(this, criteriaId)}
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title="Add a comment"
                              style={{ "marginTop": (commentBankMap[criteriaId].comments.length > 0 ? "-14px" : "0px") }}            
                              ><i className="fas fa-plus"></i></button>
&nbsp;
                            <button 
                              type="button" 
                              className="btn btn-primary" 
                              onClick={this.generateComments.bind(this, criteriaId)}
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title="Generate Comments"
                              style={{ "marginTop": (commentBankMap[criteriaId].comments.length > 0 ? "-14px" : "0px") }}            
                              ><i className="fas fa-star"></i></button>


                          </div>
                        </div>
                      </div>
                  ))}


                    </div>

                    <div className="col-md-6">
                      <div style={{ "minHeight": "200px"}}>
                        <h3 style={{margin: "0"}}><div dangerouslySetInnerHTML={{__html: this.state.hoverCommentName.replace(/\n/g, '<br/>') }} /></h3>
                        <div dangerouslySetInnerHTML={{__html: this.state.hoverComment.replace(/\n/g, '<br/>') }} />

                      </div>
                    </div>
                  </div>

                  <div style={{ "marginBottom": "20px" }}>
                    { commentBankPatternsData.length > 0 && 
                      <h4>Saved Patterns</h4>
                    }

                    { commentBankPatternsData.length > 0 && 
                      <div className="row" style={{ "marginBottom": "10px" }}>
                        <div className="col-md-12">
                        { commentBankPatternsData.map( (pattern, index) => (
                          <div key={index} style={{ "display": "inline-block", "marginRight": "5px" }}>
                            <button                       
                            type="button" 
                            className="btn" 
                            onClick={this.selectCommentBankPattern.bind(this, pattern.id)}
                            data-toggle="tooltip" 
                            data-placement="top"                       
                            >{pattern.name}</button>
                            <button type="button" style={{ marginRight: "2px" }} className="btn" onClick={this.deleteCommentBankPattern.bind(this, pattern.id)}>
                              <i className="far fa-trash-alt"></i>
                            </button>                  
                          </div>                  
                        ))}
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-md-5">
                        <button 
                            type="button" 
                            className="btn btn-info" 
                            onClick={this.showSaveCommentBankPattern}
                            data-toggle="tooltip" 
                            data-placement="top" 
                            title="Save this comment bank pattern"            
                            ><i className="fas fa-plus"></i> Save Pattern</button>
                      </div>              
                    </div>
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-md-5">     
                  { this.state.addingCriteria && 
                    <div>
                      <div><img src={spinner} height="12"/>&nbsp;&nbsp;Adding...</div>
                      <button type="button" className="btn btn-primary disabled" >+</button>
                    </div>
                  }

                  { !this.state.addingCriteria && 
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={this.addCriteria}
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="Add criteria to the comment bank"            
                    ><i className="fas fa-plus"></i> Add Feedback Criteria</button>
                  }
                </div>



                { criteriaIds.length > 0 && 

                  <div className="col-md-7">    
                    <div className="form-group">
                      <label htmlFor="feedbackgrade" style={{ "fontWeight": "bold" }}>Grade</label>
                      <div className="" style={{ paddingLeft: "0"}}>
                      <input                    
                          min="0"
                          className={ 'form-control col-2 ' + ( formErrors.scalePoints ? ' is-invalid': '') } 
                          style={{ display: "inline-block", width: "60px" }}
                          name="grade"
                          id="feedbackgrade"
                          value={ this.state.grade }
                          onChange={this.onChange}
                          onBlur={this.onBlur}/> / 100           
                      </div>                                      
                    </div>          


                      <div>
                        <button type="button" onClick={this.copyFeedbackToClipboard} className="btn " ><i className="far fa-copy"></i> Copy To Clipboard</button>          
                        &nbsp;

                        <button type="button" onClick={this.submitFeedbackAndView} className="btn " ><i className="fas fa-eye"></i> View Feedback</button>          
                        &nbsp;


                        <button type="button" onClick={this.newFeedback} className="btn " ><i className="fas fa-plus"></i> New Feedback</button>          
                      </div>

                      <div style={{ "height": "18px" }}>
                      { (this.state.copySuccess != '') && 
                        <div>
                          {this.state.copySuccess}
                        </div>
                      }
                      </div>

                      <textarea value="" readOnly ref={(textarea) => this.textArea = textarea} style={{ "width": "10px", "height": "10px", "position": "absolute", "top": "-1000px", "left": "-1000px" }}/>

                  </div>
                }

              </div>
            </div>


            <h2 style={{ "borderTop": "1px solid #dddddd", "paddingTop": "10px" }}>Feedback session</h2>
            { criteriaIds.map( (criteriaId, index) => (
              <div key={index} style={{ "marginBottom": "20px" }}>
                <h3>{commentBankMap[criteriaId].name}</h3>

                <div className="row" style={{ "marginBottom": "10px" }}>
                  <div className="col-md-10">
                  {  commentBankMap[criteriaId].comments.length == 0 && 
                    <div>
                      After entering text below, you can highlight it and click the blue plus button <i class="fas fa-plus"></i> to add it to your comment bank
                    </div>

                  }
                  { commentBankMap[criteriaId].comments.map((comment, commentIndex) => (
                        <div 
                          key={commentIndex} 
                          className="comment-dropdown"
                        >
                            { 
                              ( typeof commentBankMap[criteriaId].commentKeys === 'undefined' ||
                              commentIndex >= commentBankMap[criteriaId].commentKeys.length ||
                              this.state.selectedComments.indexOf(commentBankMap[criteriaId].commentKeys[commentIndex]) === -1) &&
                              <div 
                                  className="comment-button"
                                  onClick={this.clickComment.bind(this, criteriaId, commentIndex)}
                                />
                            }

                            { (typeof commentBankMap[criteriaId].commentKeys !== 'undefined' &&
                                commentIndex < commentBankMap[criteriaId].commentKeys.length &&
                                this.state.selectedComments.indexOf(commentBankMap[criteriaId].commentKeys[commentIndex]) !== -1) &&
                                <div 
                                  className="comment-button"
                                  style={{ backgroundColor: "blue" }}
                                  onClick={this.clickComment.bind(this, criteriaId, commentIndex)}
                                />
                            }

  {/*
                          <div 
                            className="comment-button"
                            onClick={this.clickComment.bind(this, criteriaId, commentIndex)}
                          />
  */}
                          {
                            /*
                            this.state.editCriteriaId === criteriaId && commentIndex === this.state.editCommentIndex && 
                            <div className="comment-dropdown-editcontent">
                              <textarea value={comment} onChange={this.setComment.bind(this, criteriaId, commentIndex)} rows="6" cols="60"></textarea>
                              <div>
                                <button type="button" onClick={this.closeEditComment.bind(this, criteriaId, commentIndex)} className="btn btn-sm" style={{ "marginRight": "6px" }}>Close</button>
                              </div>
                            </div>
                            */
                          }

                          { (this.state.editCriteriaId !== criteriaId || this.state.editCommentIndex != commentIndex) && 
                            <div className="comment-dropdown-content" style={{ paddingTop: "4px" }}>
                              <div style={{ "backgroundColor": "#eeeeee", "padding": "10px"}}>
                                <div style={{"whiteSpace": "nowrap"}}>
                                <div dangerouslySetInnerHTML={{__html: comment.replace(/\n/g, '<br/>') }} />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      ))}


                  </div>
                </div>

                <div className="row">                     
                  <div className="col-md-10">  
                    <textarea 
                        value={this.state.comments[criteriaId]} 
                        id={ 'feedbackComment' + criteriaId } 
                        onChange={this.updateFeedbackComment.bind(this, criteriaId)}
                        onKeyUp={this.feedbackCommentKeyUp.bind(this, criteriaId)}
                        onBlur={this.feedbackCommentBlur.bind(this, criteriaId)}
                        className="form-control"
                        rows="6"
                        />
                  </div>
                  <div className="col-md-2">  
                    <button 
                      type="button" 
                      className="btn btn-primary" 
                      onClick={this.addCommentToCriteria.bind(this, criteriaId)} 
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Add highlighted text to comment bank"><i className="fas fa-plus"></i></button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    <strong>Tags:</strong>
                    { tags.map( (tag, index) => (
                      <div className="feedback-tag" key={index}>{index !== 0 && <span>, </span>}[<a href="javascript: void(0)" onClick={this.tagClick.bind(this, criteriaId, tag.name)}>{tag.name}</a>]</div>
                    ))}


                  </div>
                </div>
              </div>
            ))}

            <div className="form-group">
              <label htmlFor="grade" style={{ "fontWeight": "bold" }}>Grade</label>
              <div className="" style={{ paddingLeft: "0"}}>
                  <input                    
                      min="0"
                      className={ 'form-control col-2 ' + ( formErrors.scalePoints ? ' is-invalid': '') } 
                      style={{ display: "inline-block", width: "60px" }}
                      name="grade"
                      id="grade"
                      value={ this.state.grade }
                      onChange={this.onChange}
                      onBlur={this.onBlur}/> / 100           
              </div>                                      
            </div>          


            <div style={{ "marginTop": "4px"}}>
              { /*
              <button type="button" onClick={this.doCancel} className="btn" style={{ "marginRight": "4px" }}>Cancel</button>
              <button type="button" onClick={this.submitFeedback} className="btn btn-primary"  style={{ "marginRight": "4px" }}>Submit</button>
              */ }
              <button type="button" onClick={this.submitFeedbackAndView} className="btn" ><i className="fas fa-eye"></i> View Feedback</button>
              &nbsp;
              <button type="button" onClick={this.newFeedback} className="btn btn-primary" ><i className="fas fa-plus"></i> New Feedback</button>          

            </div>
          </form>
        </div>

      </div>
    );
  }
}

export default firestoreConnect()(Feedback);