import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CriteriaList from '../criteria/CriteriaList';
import RubricList from '../rubric/RubricList';
import AssignmentList from '../assignment/AssignmentList';
import MessageList from '../messages/MessageList';

import { firebaseSendSubmissionResult } from '../../store';

class Dashboard extends Component {

  doIt = (e) => {
    e.preventDefault();
    //console.log(firebaseFunctions);
    var submissionId = '2xaqcH7OVkaBZ0gNn39Y';
    firebaseSendSubmissionResult({ submissionId: submissionId }).then(function(result) {
      // Read result of the Cloud Function.
//      var sanitizedMessage = result.data.text;
      // ...
      console.log(result);
    });    
  }

  render() {
//    const { criteria } = this.props;
    return (
      <div>

        { /*
        <div className="row">
          <div className="card" style={{width: '14rem'}}>
       
            <div className="card-body">
              <h5 className="card-title">Criteria Creator</h5>
              <p className="card-text"></p>
              <Link to="/criteria" className="btn btn-primary">Start</Link>

            </div>
          </div>  

          <div className="card" style={{width: '14rem', 'marginLeft': '10px'}}>
            <div className="card-body">
              <h5 className="card-title">Rubric Creator</h5>
              <p className="card-text"></p>
              <Link to="/rubric" className="btn btn-primary">Start</Link>
            </div>
          </div>  

          <div className="card" style={{width: '14rem', 'marginLeft': '10px'}}>
            <div className="card-body">
              <h5 className="card-title">Message Creator</h5>
              <p className="card-text">Greetings and Sign offs</p>
              <Link to="/message" className="btn btn-primary">Start</Link>
            </div>
          </div>  

          <div className="card" style={{width: '14rem', 'marginLeft': '10px'}}>
            <div className="card-body">
              <h5 className="card-title">Assignment Creator</h5>
              <p className="card-text"></p>
              <Link to="/assignment" className="btn btn-primary">Start</Link>
            </div>
          </div>  

        </div>

        <CriteriaList/>
        <RubricList/>
        <MessageList/>

        */ }
        <AssignmentList history={this.props.history}/>
      </div>
    )
  }
}

export default Dashboard;
