import store, { firestore } from '../store';


class CommentBankStore {

  constructor(commentBankId) {
    this.commentBankId = commentBankId;

    this.listeners = [];
    this.name = "";
    this.commentBank = [];
    this.criteriaIds = [];
  }

  addListener = (listener) => {
    let id = this.generateKey();
    this.listeners.push({
      id: id,
      listener: listener
    });


    this.commentBankUpdated();

    return id;
  }

  removeListener = (listenerId) => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id === listenerId) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

  // update the listeners
  commentBankUpdated = () => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(typeof this.listeners[i].listener !== 'undefined'
        && typeof this.listeners[i].listener.onCommentBankUpdate !== 'undefined') {
          this.listeners[i].listener.onCommentBankUpdate({
            commentBank: this.commentBank,
            commentBankId: this.commentBankId,
            commentBankName: this.name,
            commentBankCriteriaIds: this.criteriaIds,
            addingCriteria: false            
          });
        }
    }
  }

  // load a comment bank from firestore
  load = async() => {
    const uid = store.getState().firebase.auth.uid;

    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;


    // get the comment bank record
    /*
    {
      criteriaIds: [] // list of the comment bank criteria in order
    }
    */
    let results = await firestore.collection(commentBanksPath).doc(this.commentBankId).get();
    let commentBankData = results.data();

    // something went wrong in fetching record
    if(typeof commentBankData === 'undefined') {
      this.commentBank = [];
      this.commentBankCriteriaIds = [];
      return;
    }

    let criteriaIds = commentBankData.criteriaIds;
    if(typeof criteriaIds === 'undefined') {
      criteriaIds = [];
    }


    // need to remember if comment keys had to be created as they will need to be stored
    // each comment needs a key, feedback will store the key to remember the comment was selected
    let commentKeysCreated = false;
    let commentNamesCreated = false;

    let commentBank = [];

    // get the criteria belonging to the comment bank
    // each comment criteria record is 
    /*
    {
      commentKeys: [], // the comment keys in the same order as the comments
      comments: [],    // the comments
      name: ""         // the name of the comment criteria
    }
    */
    results = await firestore.collection(commentBankPath + '/commentCriteria').get();
    for(let i = 0; i < results.docs.length; i++) {
      let criteria = results.docs[i].data();

      let comments = criteria.comments;
      // uh oh something went wrong
      if(typeof comments === 'undefined') {
        comments = [];
      }

      let commentKeys = criteria.commentKeys;
      if(typeof commentKeys === 'undefined') {
        // comment keys dont exist, so create them
        commentKeysCreated = true;
        commentKeys = [];
        for(let j = 0; j < comments.length; j++) {
          commentKeys.push(this.generateKey());
        }
      }

      let commentNames = criteria.commentNames;
      if(typeof commentNames === 'undefined') {
        // comment keys dont exist, so create them
        commentNamesCreated = true;
        commentNames = [];
        for(let j = 0; j < comments.length; j++) {
          commentNames.push('');
        }

      }

      commentBank.push({
        "id": results.docs[i].id,
        "name": criteria.name,
        "comments": comments,
        "commentKeys": commentKeys,
        "commentNames": commentNames
      });

      if(commentKeysCreated || commentNamesCreated) {
        // comment keys have been created 
        // so need to save them in firestore
        criteria = {
          name: criteria.name,
          comments: comments,
          commentKeys: commentKeys,
          commentNames: commentNames
        };

        let criteriaId = results.docs[i].id;
        firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).set(criteria);
      }
    }

    this.commentBank = commentBank;
    this.name = commentBankData.name;
    this.criteriaIds = criteriaIds;

    // update the listeners
    this.commentBankUpdated();
  }

  // add a comment  to comment criteria
  addAComment = async(criteriaId, comment, commentName) => {

    const uid = store.getState().firebase.auth.uid;
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;

    let criteria = false;
    let commentBank = this.commentBank;

    // find the criteria in the comment bank and update it.
    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id === criteriaId) {
        criteria = commentBank[i];
        break;
      }
    }    

    if(criteria !== false) {
      criteria.comments.push(comment);
      criteria.commentKeys.push(this.generateKey());
      criteria.commentNames.push(commentName);

      this.commentBankUpdated();
      await firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).set(criteria);
      
    }
    this.load();
  }

  // update a comment in comment criteria
  updateComment = async(criteriaId, commentIndex, comment, commentName) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;

    const commentBank = this.commentBank;
    let criteria = false;
    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id === criteriaId) {
        criteria = commentBank[i];
        break;
      }
    }

    if(criteria) {
      if(criteria.comments.length > commentIndex) {
        criteria.comments[commentIndex] = comment;
        criteria.commentNames[commentIndex] = commentName;
        this.commentBankUpdated();
        await firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).set(criteria);
      }
    }
    this.load();

  }

  deleteComment = async(criteriaId, commentIndex) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;

    const commentBank = this.commentBank;
    let criteria = false;

    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id === criteriaId) {
        criteria = commentBank[i];
        break;
      }
    }

    if(criteria) {
      if(commentIndex >= criteria.comments.length) {
        return;
      }
      
      criteria.comments.splice(commentIndex, 1);
      criteria.commentKeys.splice(commentIndex, 1);
      criteria.commentNames.splice(commentIndex, 1);
      //this.commentBankId();      
      this.commentBankUpdated();
      await firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).set(criteria);
    }
    this.load();
  }

  updateCriteriaName = async(criteriaId, criteriaName) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;

    let criteria = false;
    const commentBank = this.commentBank;

    for(let i = 0; i < commentBank.length; i++) {
      if(commentBank[i].id === criteriaId) {
        criteria = commentBank[i];
        break;
      }
    }    

    if(criteria) {
      criteria.name = criteriaName;
      await firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).set(criteria);
    }    
  }


  addCriteria = async(criteria) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const commentCriteriaPath = 'users/' + uid + '/commentBanks/' + this.commentBankId + '/commentCriteria';

    criteria.commentBankId = this.commentBankId;
    let result = await firestore.collection(commentCriteriaPath).add(criteria);
    let criteriaId = result.id;

    // need to add the new criteriaId to the comment bank record
    result = await firestore.collection(commentBanksPath).doc(this.commentBankId).get();
    let commentBankRecord = result.data();

    let commentBankData = {};
    commentBankData.criteriaIds = commentBankRecord.criteriaIds.slice(0);
    commentBankData.criteriaIds.push(criteriaId);

    // save it bank to comment bank record
    
    await firestore.collection(commentBanksPath).doc(this.commentBankId).set(commentBankData);
    this.load();
    // need to reload everything...
  }

  moveCriteria = async(criteriaId, direction) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBanksPath = 'users/' + uid + '/commentBanks';

    var index = false;
    for(let i = 0; i < this.criteriaIds.length; i++) {
      if(this.criteriaIds[i] === criteriaId) {
        index = i;
        break;
      }
    }

    if(index !== false) {
      var newIndex = index + direction;

      if(newIndex >= 0 && newIndex < this.criteriaIds.length) {
        let saveCriteriaId = this.criteriaIds[newIndex];
        this.criteriaIds[newIndex] = this.criteriaIds[index];
        this.criteriaIds[index] = saveCriteriaId;

        let commentBankData = {};
        commentBankData.criteriaIds = this.criteriaIds;
        this.commentBankUpdated();
        await firestore.collection(commentBanksPath).doc(this.commentBankId).set(commentBankData);
      }
    }
    this.load();
  }


  deleteCriteria = async(criteriaId) => {
    const uid = store.getState().firebase.auth.uid;
    const commentBanksPath = 'users/' + uid + '/commentBanks';
    const commentBankPath = 'users/' + uid + '/commentBanks/' + this.commentBankId;

    let commentBankData = {};
    commentBankData.criteriaIds = [];
    for(let i = 0; i < this.criteriaIds.length; i++) {
      if(this.criteriaIds[i] !== criteriaId) {
        commentBankData.criteriaIds.push(this.criteriaIds[i]);
      }
    }    

    this.criteriaIds = commentBankData.criteriaIds;
    await firestore.collection(commentBanksPath).doc(this.commentBankId).set(commentBankData);

    for(let i = 0; i < this.commentBank.length; i++) {
      if(this.commentBank[i].id === criteriaId) {
        // delete this record
        this.commentBank.splice(i, 1);
        break;
      }
    }
    this.commentBankUpdated();
    await firestore.collection(commentBankPath + '/commentCriteria').doc(criteriaId).delete();
    this.load();
  }  


  generateKey = () => {
    var lut = [];
  
    for ( var i = 0; i < 256; i ++ ) {
      lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }    
  
    var d0 = Math.random() * 0xffffffff | 0;
    var d1 = Math.random() * 0xffffffff | 0;
    var d2 = Math.random() * 0xffffffff | 0;
    var d3 = Math.random() * 0xffffffff | 0;
    var uuid = lut[ d0 & 0xff ] + lut[ d0 >> 8 & 0xff ] + lut[ d0 >> 16 & 0xff ] + lut[ d0 >> 24 & 0xff ] + '-' +
      lut[ d1 & 0xff ] + lut[ d1 >> 8 & 0xff ] + '-' + lut[ d1 >> 16 & 0x0f | 0x40 ] + lut[ d1 >> 24 & 0xff ] + '-' +
      lut[ d2 & 0x3f | 0x80 ] + lut[ d2 >> 8 & 0xff ] + '-' + lut[ d2 >> 16 & 0xff ] + lut[ d2 >> 24 & 0xff ] +
      lut[ d3 & 0xff ] + lut[ d3 >> 8 & 0xff ] + lut[ d3 >> 16 & 0xff ] + lut[ d3 >> 24 & 0xff ];
  
    return uuid.toUpperCase();  
  }

}

export default CommentBankStore;