import React, { Component } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { firebaseConnect } from 'react-redux-firebase'; //firebase instead of firestore


import CriteriaCreator from './CriteriaCreator';
import CommentCreator from './CommentCreator';

class EditCriteria extends Component {

  state = {
    id: false,
    name: '',
    description: '',
    scalePoints: 0,
    scale: [],
    scaleLabels: [],
    scalePositiveComments: [],
    scaleNegativeComments: [],
    formErrors: {},
    step: 0
  };

  static getDerivedStateFromProps(props, state) {

    const id = props.match.params.id;

    if(id !== state.id) {
      // need to update state..
      if(!id) {
        return {
          id: id,
          criteria: '',
          description: '',
          scalePoints: 0,
          scaleLabels: [],
          scalePositiveComments: [],
          scaleNegativeComments: [],
          formErrors: {},
          step: 0
        }  
      } else {
        const { selectedCriteria, scale } = props;

        if(!selectedCriteria || !scale) {
          // values not loaded yet, so keep current state;
          return state;
        }

        var sortedScale = scale.slice(0);
        sortedScale.sort(function(a, b) {
          return a.index - b.index;
        });
    
        let scaleLabels = [];
        let scalePositiveComments = [];
        let scaleNegativeComments = [];
    
        for(let i = 0; i < sortedScale.length; i++) {
          scaleLabels.push(sortedScale[i].label);
          let positiveComments = sortedScale[i].positiveComments.slice(0);
          let negativeComments = sortedScale[i].negativeComments.slice(0);
          
          scalePositiveComments.push(positiveComments);
          scaleNegativeComments.push(negativeComments);
        }
        
        let newState = {
          id: id, 
          name: selectedCriteria.name,
          description: selectedCriteria.description,
          scalePoints: selectedCriteria.scalePoints,
          scaleLabels: scaleLabels,
          scalePositiveComments: scalePositiveComments,
          scaleNegativeComments: scaleNegativeComments,
          formErrors: {},
          step: 0,
        }
    
        return newState;        
      }
    }

    // keep state the same
    return state;
  }

  cancel = () => {
    this.props.history.push('/');
  }

  createCriteriaFromState = async () => {

    const { firestore, auth, history } = this.props;
    const data  = this.state;

    let criteria = {};
    criteria.name = data.name;
    criteria.description = data.description;
    criteria.scalePoints = data.scalePoints;
    criteria.createdBy = auth.uid;

    const scalePoints = data.scalePoints;
    const scaleLabels = data.scaleLabels;

    let scalePositiveComments = [];
    let scaleNegativeComments = [];
    for(let i = 0; i < scalePoints; i++) {
      scalePositiveComments.push([]);
      scaleNegativeComments.push([]);

      for(let j = 0; j < data.scaleNegativeComments[i].length; j++) {
        scaleNegativeComments[i].push(data.scaleNegativeComments[i][j]);        
      }

      for(let j = 0; j < data.scalePositiveComments[i].length; j++) {
        scalePositiveComments[i].push(data.scalePositiveComments[i][j]);        
      }
    }

    
    firestore.add({ collection: 'criteria' }, criteria).then( async (result) => {
      const id = result.id;
      const commentsCollection = '/criteria/' + id + '/scale';
      for(let i = 0; i < scalePoints; i++) {
        let scaleEntry = {
          label: scaleLabels[i],
          index: i,
          negativeComments: scaleNegativeComments[i],
          positiveComments: scalePositiveComments[i]
        };
        await firestore.add({ collection: commentsCollection }, scaleEntry);
      }
      history.push('/');
    });
  }

  updateCriteriaFromState = async () => {
    const { id } = this.state;
    const { firestore, history } = this.props;
    const data  = this.state;

    let criteria = {};
    criteria.name = data.name;
    criteria.description = data.description;
    criteria.scalePoints = data.scalePoints;


    const scalePoints = data.scalePoints;
    const scaleLabels = data.scaleLabels;

    let scalePositiveComments = [];
    let scaleNegativeComments = [];
    for(let i = 0; i < scalePoints; i++) {
      scalePositiveComments.push([]);
      scaleNegativeComments.push([]);

      for(let j = 0; j < data.scaleNegativeComments[i].length; j++) {
        scaleNegativeComments[i].push(data.scaleNegativeComments[i][j]);        
      }

      for(let j = 0; j < data.scalePositiveComments[i].length; j++) {
        scalePositiveComments[i].push(data.scalePositiveComments[i][j]);        
      }
    }


    firestore.update({ collection: 'criteria', doc: id }, criteria).then( async (result) => {
      var scalePath = '/criteria/' + id + '/scale';
      var currentScale = this.props.scale;
      console.log('current scale = ');
      console.log(currentScale);
      // should update, create and delete rather than delete all..
      for(let i = 0; i < currentScale.length; i++) {
        let docId = currentScale[i].id;
        await firestore.delete({ collection: scalePath, doc: docId });
      }


      for(let i = 0; i < scalePoints; i++) {
        let scaleEntry = {
          label: scaleLabels[i],
          index: i,
          negativeComments: scaleNegativeComments[i],
          positiveComments: scalePositiveComments[i]
        };
        await firestore.add({ collection: scalePath }, scaleEntry);
      }


      history.push('/');

    });
  }

  finish = async () => {
    const { id } = this.state;

    if(!id) {
      // add new
      this.createCriteriaFromState();
    } else {
      // edit existing..
      console.log('change existing..');
      this.updateCriteriaFromState();
    }
  }

  clickStep = (step, e) => {
    if(step === 1) {
      this.onSubmit(e)
    } else if(step === 0) {
      this.setState({ step });
    }
  }

  onChange = (e) => {
    let formErrors = this.state.formErrors;

    if(e.target.name === 'criteria' && e.target.value.trim() !== '') {
      formErrors.criteria = false;
    }

    if(e.target.name === 'scalePoints' && parseInt(e.target.value, 10) > 0) {
      formErrors.scalePoints = false;

    }

    this.setState({ [e.target.name]: e.target.value, formErrors });
  }

  onChangeScalePoints = (e) => {
    this.setState({scalePoints: e.target.value});

    let scalePoints = parseInt(e.target.value, 10);
    if(isNaN(scalePoints)) {
      return;      
    }
    this.setScalePoints(scalePoints);
  }

  setScalePoints = (scalePoints) => {
    if(scalePoints < 0) {
      return;        
    }
    let { scaleLabels, scalePositiveComments, scaleNegativeComments } = this.state;

    // make copies
    scaleLabels = scaleLabels.slice(0);    
    scalePositiveComments = scalePositiveComments.map(a => ([...a]));
    scaleNegativeComments = scaleNegativeComments.map(a => ([...a]));

    while(scaleLabels.length < scalePoints) {
      scaleLabels.push('');
      let comments = [];
      scalePositiveComments.push(comments);
      comments = [];
      scaleNegativeComments.push(comments);
    }
    while(scaleLabels.length > scalePoints) {
      scaleLabels.pop();
      scalePositiveComments.pop();
      scaleNegativeComments.pop();
    }
    this.setState({scalePoints, scaleLabels, scalePositiveComments, scaleNegativeComments });
  }

  setComment = (scaleIndex, positive, commentIndex, comment) => {
    if(positive) {
      let { scalePositiveComments } = this.state;
      var newComments = scalePositiveComments[scaleIndex].map( (entry, index) => (index === commentIndex ? comment : entry ));
      scalePositiveComments = scalePositiveComments.map( (entry, index) => (index === scaleIndex ? newComments : entry ));
      this.setState({ scalePositiveComments });
    } else {
      let { scaleNegativeComments } = this.state;
      scaleNegativeComments[scaleIndex][commentIndex] = comment;
      this.setState({ scaleNegativeComments });
    }

  }

  addComment = (scaleIndex, positive, comment) => {
    if(positive) {
      let { scalePositiveComments } = this.state;

      var newComments = [ ...scalePositiveComments[scaleIndex], comment ];
      scalePositiveComments =  scalePositiveComments.map( (entry, index) => (index === scaleIndex ? newComments : entry ));
      this.setState({ scalePositiveComments });
      return scalePositiveComments[scaleIndex].length - 1;
    } else {
      let { scaleNegativeComments } = this.state;
      var newComments = [ ...scaleNegativeComments[scaleIndex], comment ];
      scaleNegativeComments =  scaleNegativeComments.map( (entry, index) => (index === scaleIndex ? newComments : entry ));

      this.setState({ scaleNegativeComments });
      return scaleNegativeComments[scaleIndex].length - 1;

    }
  }

  increaseScalePoints = () => {
    let scalePoints  = parseInt(this.state.scalePoints, 10);
    
    if(isNaN(scalePoints)) {
        return;
    }
    this.setScalePoints(scalePoints + 1);
  }

  decreaseScalePoints = () => {
    let scalePoints  = parseInt(this.state.scalePoints, 10);
    if(isNaN(scalePoints)) {
        return;
    }
    this.setScalePoints(scalePoints - 1);
  }

  onChangeScaleLabel = (scaleLabelIndex, e) => {
    let { scaleLabels } = this.state;      
    scaleLabels[scaleLabelIndex] = e.target.value;
    this.setState({ scaleLabels });
  }


  setStep = (step) => {
    this.setState({step});
  }

  render() {
    const { step } = this.state;
    return (
      <div>

        <div className="row">
          <div onClick={this.clickStep.bind(this, 0)} className={'col-md-2 step' + (this.state.step === 0 ? '' : ' step-inactive' )}>
            <div className="float-right" style={{ "paddingTop": "10px" }}>&gt;</div>
            <div className="step-number">Step 1:</div> 
            <div>Criteria</div>
          </div>
          
          <div onClick={this.clickStep.bind(this, 1)} className={'col-md-2 step' + (this.state.step === 1 ? '' : ' step-inactive' )}>
            <div className="step-number">Step 2:</div> 
            <div>Comments</div>
          </div>
        </div>


        { step === 0 &&
          <CriteriaCreator criteria={this.state} onChange={this.onChange} onChangeScalePoints={this.onChangeScalePoints} onChangeScaleLabel={this.onChangeScaleLabel} onIncreaseScalePoints={this.increaseScalePoints} onDecreaseScalePoints={this.decreaseScalePoints} onSetStep={this.setStep}  onCancel={this.cancel}/>
        }
        { step === 1 &&
          <CommentCreator criteria={this.state} onSetStep={this.setStep} onAddComment={this.addComment} onSetComment={this.setComment} onFinish={this.finish}/>

        }

      </div>
    );
  }
}

// if editing, id will be passed through params, how to know if invalid id?
// if adding, no id in params, 
export default compose(
  firestoreConnect(props => 
    (props.match.params.id ? 
    [
      {collection: 'criteria', storeAs: 'selectedCriteria' + props.match.params.id, doc: props.match.params.id },
      {collection: '/criteria/' + props.match.params.id + '/scale', storeAs: 'selectedCriteriaScale' + props.match.params.id}
    ] :
    [])
  ),
  connect( (state, props) => (
    (props.match.params.id ?
    {
      auth: state.firebase.auth,
      selectedCriteria: state.firestore.ordered['selectedCriteria' + props.match.params.id] && state.firestore.ordered['selectedCriteria' + props.match.params.id][0],
      scale: state.firestore.ordered['selectedCriteriaScale' + props.match.params.id]
    } :
    {
      auth: state.firebase.auth
    })
  ))
)(EditCriteria);