import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dataStore from '../../data/DataStore';

import FeedbackList from '../feedback/FeedbackList';

class AssignmentDetails extends Component {
  assignmentListenerId = false;
  currentAssignmentId = false;


  state = {
    assignment: false
  }


  setAssignment(assignmentId) {
    if(this.currentAssignmentId !== assignmentId) {

      if(this.currentAssignmentId !== false) {
        let currentAssignment = dataStore.getAssignmentStore(this.currentAssignmentId);
        currentAssignment.removeListener(this.assignmentListenerId);
      }

      if(assignmentId !== false) {
        let assignment = dataStore.getAssignmentStore(assignmentId);

        this.assignmentListenerId = assignment.addListener(this);
        this.currentAssignmentId = assignmentId; 
        assignment.load();
      }

      this.setState({ assignmentId: assignmentId });
    }
  }

  onAssignmentDetailsUpdate(assignmentDetails) {
    this.setState({
      id: assignmentDetails.id,
      assignment: assignmentDetails
    });
  }
  
  componentDidMount() {
    this.setAssignment(this.props.match.params.id);
  }
  componentDidUpdate(prevProps) {
    if(typeof this.props.match.params.id != 'undefined') {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        this.setAssignment(this.props.match.params.id);
      }
    } 
  }

  componentWillUnmount() {
    this.setAssignment(false);
  }


  render() {

    const { assignment } = this.state;

    if(!assignment) {
      return (
        <div>Loading...</div>
      );
    }

    return (
      <div style={{ "marginBottom": "10px" }}>
        <h1>{assignment.name}</h1>
        
        <FeedbackList assignmentId={assignment.id} history={this.props.history}/>

        { /*

        <Link to={ '/assignment/addsubmission/' + selectedAssignment.id } className="btn btn-primary">Add Feedback Old</Link>
        &nbsp; &nbsp;
        
        <SubmissionList assignmentId={selectedAssignment.id}/>
        */}
      </div>
    );
  }
}


export default AssignmentDetails;
