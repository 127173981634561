import store, { firestore } from '../store';

class RubricStore {

  constructor(rubricId) {
    this.rubricId = rubricId;

    this.listeners = [];

    this.rubric = [];
    this.rubricName = '';
    this.rubricCriteriaIds = [];

  }

  addListener = (listener) => {
    let id = this.generateKey();
    this.listeners.push({
      id: id,
      listener: listener
    });


    this.rubricUpdated();

    return id;
  }

  removeListener = (listenerId) => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id === listenerId) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

  // update the listeners
  rubricUpdated = () => {
    for(let i = 0; i < this.listeners.length; i++) {
      if(typeof this.listeners[i].listener !== 'undefined'
        && typeof this.listeners[i].listener.onRubricUpdate !== 'undefined') {
          this.listeners[i].listener.onRubricUpdate({
            rubric: this.rubric,
            rubricName: this.rubricName,
            rubricCriteriaIds: this.rubricCriteriaIds,
            addingRubricCriteria: false
          });
        }
    }
  }


  // load a rubric from firestore
  load = async() => {
    const uid = store.getState().firebase.auth.uid;
    const rubricsPath = 'users/' + uid + '/rubrics';
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';

    let results = await firestore.collection(rubricsPath).doc(this.rubricId).get();
    let rubricData = results.data();
    if(typeof rubricData === 'undefined') {
      return;
    }

    results = await firestore.collection(rubricCriteriaPath).get();
    
    let rubric = [];
    for(let i = 0; i < results.docs.length; i++) {
      let rubricCriteria = results.docs[i].data();
      let ratings = rubricCriteria.ratings;
      if(typeof ratings === 'undefined') {
        ratings = [];
      }
      rubric.push({
        "id": results.docs[i].id,
        "name": rubricCriteria.name,
        "ratings": ratings
      });
    }

    let rubricCriteriaIds = rubricData.rubricCriteriaIds;
    if(typeof rubricCriteriaIds == 'undefined') {
      rubricCriteriaIds = [];
    }


    this.rubricName = rubricData.name;
    this.rubric = rubric;
    this.rubricCriteriaIds = rubricCriteriaIds;

    this.rubricUpdated();

    /*
    return {
      rubric: this.rubric,
      rubricName: this.rubricName,
      rubricCriteriaIds: this.rubricCriteriaIds,
      addingRubricCriteria: false
    }
    */
  }


  addCriteria = async(criteria) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricsPath = 'users/' + uid + '/rubrics';
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';

    criteria.rubricId = this.rubricId;
    let result = await firestore.collection(rubricCriteriaPath).add(criteria);
    let rubricCriteriaId = result.id;

    this.rubricCriteriaIds.push(rubricCriteriaId);

    var rubricData = {};
    rubricData.rubricCriteriaIds = this.rubricCriteriaIds;
    
    // save it bank to comment bank record
    await firestore.collection(rubricsPath).doc(this.rubricId).set(rubricData);    
    this.load();
  }


  addRating = async(criteriaId, value) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';

    let rubricCriteria = false;
    let ratingIndex = 0;
    const rubric = this.rubric;


    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == criteriaId) {
        rubricCriteria = rubric[i];

        break;
      }
    }    


    if(rubricCriteria) {
      rubricCriteria.ratings.push(value);

      this.rubricUpdated();
      await firestore.collection(rubricCriteriaPath).doc(criteriaId).set(rubricCriteria);
    }
    this.load();

  }

  updateRating = async(criteriaId, ratingIndex, value) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';

    const rubric = this.rubric;
    let rubricCriteria = false;

    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id === criteriaId) {
        rubricCriteria = rubric[i];

        break;
      }
    }    

    if(rubricCriteria) {
      if(ratingIndex < rubricCriteria.ratings.length) {
        rubricCriteria.ratings[ratingIndex] = value;

        this.rubricUpdated();
        await firestore.collection(rubricCriteriaPath).doc(criteriaId).set(rubricCriteria);
      }
    }

    this.load();
  }

  deleteRating = async(criteriaId, ratingIndex) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';


    let rubric = this.rubric;
    let rubricCriteria = false;
    for(let i = 0; i < rubric.length; i++) {
      if(rubric[i].id == criteriaId) {
        rubricCriteria = rubric[i];
        break;

      }
    }    

    if(rubricCriteria) {
      if(ratingIndex < rubricCriteria.ratings.length) {
        rubricCriteria.ratings.splice(ratingIndex, 1);
        this.rubricUpdated();
        await firestore.collection(rubricCriteriaPath).doc(criteriaId).set(rubricCriteria);
      }
    }

    this.load();

  }


  moveCriteria = async(criteriaId, direction) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricsPath = 'users/' + uid + '/rubrics';
  
    var index = false;
    for(let i = 0; i < this.rubricCriteriaIds.length; i++) {
      if(this.rubricCriteriaIds[i] === criteriaId) {
        index = i;
        break;
      }
    }

    if(index !== false) {
      var newIndex = index + direction;

      if(newIndex >= 0 && newIndex < this.rubricCriteriaIds.length) {
        let saveCriteriaId = this.rubricCriteriaIds[newIndex];
        this.rubricCriteriaIds[newIndex] = this.rubricCriteriaIds[index];
        this.rubricCriteriaIds[index] = saveCriteriaId;

        let rubricData = {};
        rubricData.rubricCriteriaIds = this.rubricCriteriaIds;
        this.rubricUpdated();
        await firestore.collection(rubricsPath).doc(this.rubricId).set(rubricData);
      }
    }
    this.load();
  }


  deleteCriteria = async(criteriaId) => {
    const uid = store.getState().firebase.auth.uid;
    const rubricsPath = 'users/' + uid + '/rubrics';
    const rubricCriteriaPath = 'users/' + uid + '/rubrics/' + this.rubricId + '/rubricCriteria';

    for(let i = 0; i < this.rubric.length; i++) {
      if(this.rubric[i].id === criteriaId) {
        this.rubric.splice(i, 1);
        break;
      }
    }    

    for(let i = 0; i < this.rubricCriteriaIds.length; i++) {
      if(this.rubricCriteriaIds[i] === criteriaId) {
        this.rubricCriteriaIds.splice(i, 1);
      }
    }    

    let rubricData = {};
    rubricData.rubricCriteriaIds = this.rubricCriteriaIds;

    this.rubricUpdated();
    await firestore.collection(rubricsPath).doc(this.rubricId).set(rubricData);
    await firestore.collection(rubricCriteriaPath).doc(criteriaId).delete();
    this.load();

  }

  generateKey = () => {
    var lut = [];
  
    for ( var i = 0; i < 256; i ++ ) {
      lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }    
  
    var d0 = Math.random() * 0xffffffff | 0;
    var d1 = Math.random() * 0xffffffff | 0;
    var d2 = Math.random() * 0xffffffff | 0;
    var d3 = Math.random() * 0xffffffff | 0;
    var uuid = lut[ d0 & 0xff ] + lut[ d0 >> 8 & 0xff ] + lut[ d0 >> 16 & 0xff ] + lut[ d0 >> 24 & 0xff ] + '-' +
      lut[ d1 & 0xff ] + lut[ d1 >> 8 & 0xff ] + '-' + lut[ d1 >> 16 & 0x0f | 0x40 ] + lut[ d1 >> 24 & 0xff ] + '-' +
      lut[ d2 & 0x3f | 0x80 ] + lut[ d2 >> 8 & 0xff ] + '-' + lut[ d2 >> 16 & 0xff ] + lut[ d2 >> 24 & 0xff ] +
      lut[ d3 & 0xff ] + lut[ d3 >> 8 & 0xff ] + lut[ d3 >> 16 & 0xff ] + lut[ d3 >> 24 & 0xff ];
  
    return uuid.toUpperCase();  
  }



}

export default RubricStore;
