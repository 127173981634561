import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';

import AppNavbar  from './components/layout/AppNavbar';
import Dashboard  from './components/layout/Dashboard';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgottenPassword from './components/auth/ForgottenPassword';
import AccountManagement from './components/auth/AccountManagement';

import { UserIsAuthenticated, UserIsNotAuthenticated } from './helpers/auth';

//import AddCriteria from './components/criteria/AddCriteria';
import EditCriteria from './components/criteria/EditCriteria';
import RubricCreator from './components/rubric/RubricCreator';
import AssignmentCreator from './components/assignment/AssignmentCreator';
import AssignmentDetails from './components/assignment/AssignmentDetails';
import Tags from './components/tags/Tags';
import GradeSubmission from './components/submission/GradeSubmission';
import Submission from './components/submission/Submission';

import Feedback from './components/feedback/Feedback';
import ViewFeedback from './components/feedback/ViewFeedback';

import MessageEditor from './components/messages/MessageEditor';
import spinner from './components/layout/spinner2.gif';



import './App.css';

// https://projects.invisionapp.com/share/3GPKSDPY5MK#/screens/336369293_7

class App extends Component {

  state = {
//    criteria: [],
    rubric: []
  }

  


  render() {

    return (
      <Provider store={store}>
        <Router>      
          <div className="App">

            <div className="progress-overlay" id="progress-overlay" style={{ "zIndex": 1000, "display": "none", "position": "fixed", "bottom": 0, "top": 0, "left": 0, "right": 0, "backgroundColor": "#000000", "opacity": 0.3 }}>
              <div style={{ "textAlign": "center", "color": "#ffffff", "fontSize": "20px", "marginTop": "30px" }}>
                <img src={spinner}/>&nbsp;&nbsp;Saving...
              </div>
            </div>

            <div className="saving-overlay" id="saving-overlay" style={{ "zIndex": 1000, "display": "none", "position": "fixed", "bottom": 0, "right": 0, "height": "26px", "width": "100px" }}>
              <img src={spinner} height="20px"/>&nbsp;&nbsp;Saving...
            </div>

            <AppNavbar/>
            <div className="container">
              <Switch>
                <Route exact path="/login" component={UserIsNotAuthenticated(Login)}/>
                <Route exact path="/register" component={UserIsNotAuthenticated(Register)}/>
                <Route exact path="/forgotten-password" component={UserIsNotAuthenticated(ForgottenPassword)}/>
                <Route exact path="/acctmgmt" component={UserIsNotAuthenticated(AccountManagement)}/>

                <Route exact path="/" component={UserIsAuthenticated(Dashboard)}/>
                <Route exact path="/criteria" component={UserIsAuthenticated(EditCriteria)}/>
                <Route exact path="/criteria/edit/:id" component={UserIsAuthenticated(EditCriteria)}/>

                <Route exact path="/rubric" component={UserIsAuthenticated(RubricCreator)}/>
                <Route exact path="/rubric/edit/:id" component={UserIsAuthenticated(RubricCreator)}/>


                <Route exact path="/assignment" component={UserIsAuthenticated(AssignmentCreator)}/>
                <Route exact path="/assignment/edit/:id" component={UserIsAuthenticated(AssignmentCreator)}/>
                <Route exact path="/assignment/:id" component={UserIsAuthenticated(AssignmentDetails)}/>
                <Route exact path="/assignment/addsubmission/:assignmentId" component={UserIsAuthenticated(GradeSubmission)}/>
                <Route exact path="/assignment/tags/:assignmentId" component={UserIsAuthenticated(Tags)}/>
                

                <Route exact path="/assignment/submission/view/:id" component={UserIsAuthenticated(Submission)}/>

                <Route exact path="/message" component={UserIsAuthenticated(MessageEditor)}/>
                <Route exact path="/message/edit/:id" component={UserIsAuthenticated(MessageEditor)}/>

                <Route exact path="/assignment/addfeedback/:assignmentId" component={UserIsAuthenticated(Feedback)}/>
                <Route exact path="/assignment/:assignmentId/feedback/edit/:id" component={UserIsAuthenticated(Feedback)}/>
                <Route exact path="/assignment/:assignmentId/feedback/view/:id" component={UserIsAuthenticated(ViewFeedback)}/>

                

            {/*}
                <Route exact path="/criteria" render={(props) => <EditCriteria {...props} />}/>
                <Route exact path="/criteria" component={EditCriteria}/>

            render={ (props) => <Dashboard {...props} criteria={this.state.criteria}/>}
                <Route exact path="/rubric" render={(props) => <RubricCreator {...props} criteria={this.state.criteria} onFinish={this.rubricCreatorFinish}/>}/>
            
                <Route exact path="/client/add" component={AddClient}/>
                <Route exact path="/client/:id" component={ClientDetails}/>
            */ }
              </Switch>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
