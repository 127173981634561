import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Criteria from '../criteria/Criteria';
import store  from '../../store';

class GradeSubmission extends Component {
  state = {
    formErrors: {},
    id: false,
    assignmentName: '',
    assignmentId: '',
    rubricId: '',
    rubricName: '',
    firstName: '',
    lastName: '',
    studentNumber: '',
    email: '',

    greeting: '',
    signoff: '',
    criteriaIds: [],
    criteriaSelectedLevels: [],
    criteriaLabels: [],
    criteriaComments: [],

    
    grade: 0,

    messages: [],
    greetingMessage: false,
    signoffMessage: false
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeGreeting = (e) => {
    const { messages } = this.state;
    let text = '';

    for(var i = 0; i < messages.length; i++) {
      if(messages[i].name == e.target.value && messages[i].type === 'greeting') {
        text = messages[i].text;
      }

    }
    this.setState({ [e.target.name]: e.target.value, greeting: text });

  }

  onChangeSignoff = (e) => {
    const { messages } = this.state;
    let text = '';
    for(var i = 0; i < messages.length; i++) {
      if(messages[i].name === e.target.value && messages[i].type === 'signoff') {
        text = messages[i].text;
      }      
    }
    this.setState({ [e.target.name]: e.target.value, signoff: text });

  }

  onSubmit = (e) => {
    e.preventDefault();
    this.saveInFirestore();
  }


  onCancel = (e) => {
    const { firestore, history } = this.props;
    e.preventDefault();
    history.goBack();

  }

  
  saveInFirestore = () => {
    const { firestore, history } = this.props;
    const assignmentId = this.state.assignmentId;

    const data  = this.state;
    let submission = {};

    submission.assignmentId = data.assignmentId;
    submission.rubricId = data.rubricId;
    submission.firstName = data.firstName;
    submission.lastName = data.lastName;
    submission.studentNumber = data.studentNumber;
    submission.email = data.email;
    submission.criteriaIds = data.criteriaIds;
    submission.criteriaSelectedLevels = data.criteriaSelectedLevels;
    submission.criteriaLabels = data.criteriaLabels;
    submission.criteriaComments = data.criteriaComments;
    submission.grade = data.grade;
    submission.greeting = data.greeting;
    submission.signoff = data.signoff;

    submission.greeting = submission.greeting.replace(/\[Name\]/g, data.firstName);
    submission.greeting = submission.greeting.replace(/\(Name\)/g, data.firstName);
    submission.signoff = submission.signoff.replace(/\[Name\]/g, data.firstName);
    submission.signoff = submission.signoff.replace(/\(Name\)/g, data.firstName);

    if(this.state.id === false) {
      // create new
      firestore.add({ collection: 'submissions' }, submission).then(  (result) => {
        let submissionId = result.id;
        let submissionPath = 'assignments/' + assignmentId + '/submissions';
        firestore.add({ collection: submissionPath }, { submissionId: submissionId }).then(  (result) => {
          history.push('/assignment/' + assignmentId);
        });
      });
    } else {
      // save it..
      firestore.update({ collection: 'submissions', doc: this.state.id }, submission).then( async (result) => {
        history.push('/assignment/' + assignmentId);
      });
  
  
    }
  }

  setRubricDetails = () => {
    

  }

  getMessages() {
    const { firestore } = this.props;

    firestore.get({ collection: 'messages', where: ["createdBy", "==", store.getState().firebase.auth.uid ] }).then((results) => {  
      let messages = [];
      for(var i = 0; i < results.docs.length; i++) {
        var message = results.docs[i].data();
        messages.push({
          "name": message.name,
          "type": message.type,
          "text": message.text
        });
      }

      this.setState({ messages });
    });  
  }

  getSubmissionDetails(submissionId) {
    const { firestore  } = this.props;
    firestore.get({ collection: 'submissions', doc: submissionId }).then((result) => {
      let submission = result.data();
      
      /*
    firstName: '',
    lastName: '',
    studentNumber: '',
    email: '',

    greeting: '',
    signoff: '',
    criteriaIds: [],
    criteriaSelectedLevels: [],
    criteriaLabels: [],
    criteriaComments: [],

    */

      this.setState({ 
        id: submissionId,
        assignmentId: submission.assignmentId, 
        rubricId: submission.rubricId,
        firstName: submission.firstName,
        lastName: submission.lastName,
        studentNumber: submission.studentNumber,
        email: submission.email,

        greeting: submission.greeting,
        signoff: submission.signoff,

        /*
        criteriaIds: submission.criteriaIds,
        criteriaSelectedLevels: submission.criteriaSelectedLevels,
        criteriaLabels: submission.criteriaLabels,
        criteriaComments: submission.criteriaComments,
        */
        grade: submission.grade


      });

      let currentComments = {
        criteriaIds: submission.criteriaIds,
        criteriaSelectedLevels: submission.criteriaSelectedLevels,
        criteriaLabels: submission.criteriaLabels,
        criteriaComments: submission.criteriaComments
      };
      this.getRubricDetails(submission.rubricId, currentComments);
      this.getMessages();
      /*
      this.setState({ assignmentName: assignment.name, assignmentId: assignmentId, rubricId: assignment.rubricId });
      this.getRubricDetails(assignment.rubricId);
      this.getMessages();
      */

    });

  }

  getAssignmentDetails(assignmentId) {
    const { firestore  } = this.props;

    this.setState({ assignmentName: '' });
    firestore.get({ collection: 'assignments', doc: assignmentId }).then((result) => {
      let assignment = result.data();
      this.setState({ id: false, assignmentName: assignment.name, assignmentId: assignmentId, rubricId: assignment.rubricId });
      this.getRubricDetails(assignment.rubricId);
      this.getMessages();
    });
  }

  getRubricDetails(rubricId, currentComments) {
    const { firestore  } = this.props;

    this.setState({ rubricName: '' });
    firestore.get({ collection: 'rubrics', doc: rubricId }).then((result) => {
      let rubric = result.data();

      let criteriaSelectedLevels = [];
      let criteriaLabels = [];
      let criteriaComments = [];
      while(criteriaSelectedLevels.length < rubric.criteria.length) {

        let selectedLevel = false;
        let criteriaLabel = '';
        let criteriaComment = '';

        // set the comments if they've been passed in..
        if(typeof currentComments !== 'undefined') {
          let criteriaId = rubric.criteria[criteriaSelectedLevels.length];
          for(let i = 0; i < currentComments.criteriaIds.length; i++) {
            if(criteriaId === currentComments.criteriaIds[i]) {
              selectedLevel = currentComments.criteriaSelectedLevels[i];
              criteriaLabel = currentComments.criteriaLabels[i];
              criteriaComment = currentComments.criteriaComments[i];

            }
          }
        }


        criteriaSelectedLevels.push(selectedLevel);
        criteriaLabels.push(criteriaLabel);
        criteriaComments.push(criteriaComment);
      }
      this.setState({ 
          rubricName: rubric.name, 
          criteriaIds: rubric.criteria, 
          criteriaSelectedLevels,
          criteriaLabels,
          criteriaComments 
      });
    });
  }

  changeComment = (index, e) => {
    let criteriaComments = this.state.criteriaComments.slice(0);
    criteriaComments[index] = e.target.value;
    this.setState({ criteriaComments });
    
  }
  commentSelected = (criteriaId, level, comment, label) => {

    const { criteriaIds } = this.state;
    let criteriaSelectedLevels = this.state.criteriaSelectedLevels.slice(0);
    let criteriaLabels = this.state.criteriaLabels.slice(0);
    let criteriaComments = this.state.criteriaComments.slice(0);
    
    for(let i = 0; i < criteriaIds.length; i++) {
      if(criteriaIds[i] === criteriaId) {
        criteriaSelectedLevels[i] = level;
        criteriaLabels[i] = label;
        criteriaComments[i] = comment;
      }
    }

    this.setState({ criteriaSelectedLevels, criteriaLabels, criteriaComments });

  }

  setCriteriaLevel = (criteriaId, level, label) => {
    const { criteriaIds } = this.state;
    let criteriaSelectedLevels = this.state.criteriaSelectedLevels.slice(0);
    let criteriaLabels = this.state.criteriaLabels.slice(0);
    
    for(let i = 0; i < criteriaIds.length; i++) {
      if(criteriaIds[i] === criteriaId) {
        criteriaSelectedLevels[i] = level;
        criteriaLabels[i] = label;
      }
    }

    this.setState({ criteriaSelectedLevels, criteriaLabels });
  }


  componentDidMount() {
    this.setState({ name: '' })

    
    if(typeof this.props.match.params.id != 'undefined') {
      this.getSubmissionDetails(this.props.match.params.id);
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {
      this.getAssignmentDetails(this.props.match.params.assignmentId);
    }

  }

  componentDidUpdate(prevProps) {

    if(typeof this.props.match.params.id != 'undefined') {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        this.getSubmissionDetails(this.props.match.params.id);
      }
    } else if(typeof this.props.match.params.assignmentId != 'undefined') {
      if (this.props.match.params.assignmentId !== prevProps.match.params.assignmentId) {

        this.getAssignmentDetails(this.props.match.params.assignmentId);
      }
    }
  }

  render() {
    const { formErrors } = this.state;
    const { criteriaIds, criteriaSelectedLevels, criteriaLabels, criteriaComments } = this.state;

    let greetings = this.state.messages.filter(message => message.type === 'greeting');
    let signoffs = this.state.messages.filter(message => message.type === 'signoff');
    return (
      <div>
        <h1>{ this.state.assignmentName}</h1>
        <Link to={`/assignment/${this.state.assignmentId}`}>&lt; Back to assignment</Link>

        <h2>Assignment Feedback Details</h2>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input 
                  type="text"
                  name="firstName" 
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>            
              <div className="form-group">
                <label htmlFor="firstName">Last Name</label>
                <input 
                  type="text"
                  name="lastName" 
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  className="form-control" 
                />
              </div>            
              <div className="form-group">
                <label htmlFor="studentNumber">Student Number</label>
                <input 
                  type="text" 
                  name="studentNumber"
                  id="studentNumber"
                  value={this.state.studentNumber}
                  onChange={this.onChange}
                  className="form-control" 
                />
              </div>            
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input 
                  name="email"
                  id="email"
                  type="email" 
                  value={this.state.email}
                  onChange={this.onChange}
                  className="form-control" 
                />
              </div>            
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-md-4">
                <div>Rubric: {this.state.rubricName}</div>
              </div>
            </div>

            <div className="row">
              { criteriaIds.map( (criteriaId, index) => (
                <div key={index} className="col-md-3" >
                  <Criteria id={criteriaId} key={criteriaId} onCommentSelected={this.commentSelected}/>
                </div>
              ))}
            </div>

            <div className="form-group">
              <label htmlFor="greeting" style={{ "fontWeight": "bold" }}>Greeting</label>
              <select id="greetingMessage" name="greetingMessage" onChange={this.onChangeGreeting} value={this.state.greetingMessage}>
                <option value="">Please Select</option>
                { greetings.map( (message, index) => (
                  <option key={index} value={message.name}>{message.name}</option>
                ))}
              </select>
              <textarea
                className="form-control" 
                name="greeting" 
                id="greeting"
                placeholder="" 
                value={this.state.greeting} 
                onChange={this.onChange}
                rows="3"
              />
            </div>


            { criteriaIds.map( (criteriaId, index) => (
              <div key={index}>
                { (criteriaSelectedLevels[index] !== false) &&
                  <div className="form-group">
                    <label htmlFor={"comment" + index}  style={{ "fontWeight": "bold" }}>{ criteriaLabels[index] }</label>

                    <Criteria id={criteriaId} level={criteriaSelectedLevels[index]} key={criteriaId} onCommentSelected={this.commentSelected}/>
                    <textarea
                      className="form-control" 
                      name={"comment" + index}
                      id={"comment" + index}
                      placeholder="" 
                      value={criteriaComments[index]} 
                      onChange={this.changeComment.bind(this, index )}
                      rows="3"
                    />
                  </div>
                }
              </div>
            ))}

            <div className="form-group">
              <label htmlFor="signoff"  style={{ "fontWeight": "bold" }}>Sign Off</label>

              <select id="signoffMessage" name="signoffMessage" onChange={this.onChangeSignoff} value={this.state.signOffMessage}>
                <option value="">Please Select</option>
                { signoffs.map( (message, index) => (
                  <option key={index} value={message.name}>{message.name}</option>
                ))}
              </select>


              <textarea
                className="form-control" 
                id="signoff"
                name="signoff" 
                placeholder="" 
                value={this.state.signoff} 
                onChange={this.onChange}
                rows="3"
              />
            </div>
          </div> 

          <div className="form-group">
            <label htmlFor="grade" style={{ "fontWeight": "bold" }}>Number Grade</label>
            <div className="" style={{ paddingLeft: "0"}}>
                <input                    
                    min="0"
                    className={ 'form-control col-2 ' + ( formErrors.scalePoints ? ' is-invalid': '') } 
                    style={{ display: "inline-block", width: "60px" }}
                    name="grade"
                    id="grade"
                    value={ this.state.grade }
                    onChange={this.onChange}/> / 100               
            </div>                                      
          </div>          
          <button type="button" onClick={this.onCancel} className="btn btn-secondary">Cancel</button>
          &nbsp;
          <button type="button" onClick={this.onSubmit} className="btn btn-primary">Submit</button>
        </form>

      </div>
    );
  }
}

export default firestoreConnect()(GradeSubmission);