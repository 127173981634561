import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase'; //firebase instead of firestore
import { notifyUser } from '../../actions/notifyActions';


class AppNavbar extends Component {
  state = {
    isAuthenticated: false
  }

  static getDerivedStateFromProps(props, state) {
  
    const { auth } = props;
    if(auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();

    const { firebase } = this.props;
    notifyUser('', false);
    firebase.logout();

  }

  render() {
    const { isAuthenticated } = this.state;
    const { auth } = this.props;
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-danger mb-4">
        <div className="container">
          <Link to="/" className="navbar-brand">
            Headset
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMain">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarMain">
              {isAuthenticated ? ( 
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Assignments
                    </Link>
                  </li>

{ /*
                  <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Dashboard
                  </Link>
                  </li>
*/ }                  
                </ul>

              ) : null
              }
            {isAuthenticated ? ( 
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a href="#!" className="nav-link">
                      {auth.email}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#!" className="nav-link" onClick={this.onLogoutClick}>
                      Logout
                    </a>
                  </li>

                </ul>
              ) : null
              }


          </div>
        </div>
      </nav>
    )
  }
}

export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify
    }),
    // actions go in here
    { notifyUser }
  )
)(AppNavbar);

