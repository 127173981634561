import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { firebaseConnect } from 'react-redux-firebase'; //firebase instead of firestore
import store  from '../../store';


class AssignmentCreator extends Component {
  state = {
    id: false,
    name: '',
//    emailSubject: '',
    description: '',
    //rubricId: '',
    commentBankId: '',
    commentBankName: '',
    commentBanks: false,
    formErrors: {}
  }

  /*
  static getDerivedStateFromProps(props, state) {

    const id = props.match.params.id;

    if(id !== state.id) {
      // need to update state..
      if(!id) {
        return {
          id: id,
          name: '',
//          emailSubject: '',
          description: '',
          commentBankId: '',
          formErrors: {}
        }  
      } else {
        const { selectedAssignment } = props;

        if(!selectedAssignment) {
          // values not loaded yet, so keep current state;
          return state;
        }

        let commentBankId = selectedAssignment.commentBankId;
        if(typeof commentBankId == 'undefined') {
          commentBankId = '';
        }
        
        let newState = {
          id: id, 
          name: selectedAssignment.name,
//          emailSubject: selectedAssignment.emailSubject,
          description: selectedAssignment.description,
          commentBankId: commentBankId,        
          formErrors: {}
        }
    
        return newState;        
      }
    }

    // keep state the same
    return state;
  }
  */

  onChange = (e) => {
    let formErrors = this.state.formErrors;
    this.setState({ [e.target.name]: e.target.value, formErrors });
  }

  onCancel = (e) => {
    e.preventDefault();
    if(typeof this.state.id === 'undefined' || this.state.id === false) {
      this.props.history.push('/');
    } else {
      this.props.history.push('/assignment/' + this.state.id);
    }
  }

  createAssignmentFromState = async () => {

    const { firestore, history, auth } = this.props;
    const data  = this.state;
    const uid = store.getState().firebase.auth.uid;

    const userPath = 'users/' + uid;
 
    let assignment = {};
    assignment.name = data.name;
    assignment.description = data.description;
    assignment.commentBankId = data.commentBankId;
    assignment.createdBy = uid;

    if(data.commentBankId === '') {
      let commentbank = {};
      commentbank.name = data.commentBankName;
      commentbank.criteriaIds = [];
      commentbank.createdBy = uid;

      // need to create comment bank
      firestore.add({ collection: userPath + '/commentBanks' }, commentbank).then( async (result) => {
        assignment.commentBankId = result.id;
        // create a rubric

        let rubric = {};
        rubric.name = data.name;
        rubric.rubricCriteriaIds = [];
        rubric.createdBy = uid;

        firestore.add({ collection: userPath + '/rubrics' }, rubric).then (async(result) => {
          assignment.rubricId = result.id;

          firestore.add({ collection: userPath + '/assignments' }, assignment).then( async (result) => {
            const id = result.id;  
            history.push('/assignment/' + id);
          });  

        });
      });
    } else {
      // create a rubric
      let rubric = {};
      rubric.name = data.name;
      rubric.rubricCriteriaIds = [];
      rubric.createdBy = uid;

      firestore.add({ collection: userPath + '/rubrics' }, rubric).then (async(result) => {
        assignment.rubricId = result.id;      
        firestore.add({ collection: userPath + '/assignments' }, assignment).then( async (result) => {
          const id = result.id;  
          history.push('/assignment/' + id);
        });
      });
    }
  }  

  updateAssignmentFromState = async () => {
    const { id } = this.state;
    const { firestore, history, auth } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;

    const data  = this.state;

    let assignment = {};
    assignment.name = data.name;
    assignment.description = data.description;
//    assignment.emailSubject = data.emailSubject;
    assignment.commentBankId = data.commentBankId;

    if(typeof assignment.name === 'undefined') {
      assignment.name = '';
    }

    if(typeof assignment.description === 'undefined') {
      assignment.description = '';
    }
  
    /*
    if(typeof assignment.emailSubject === 'undefined') {
      assignment.emailSubject = '';
    }
*/
    if(data.commentBankId === '') {
      let commentbank = {};
      commentbank.name = data.commentBankName;
      commentbank.createdBy = uid;
      commentbank.criteriaIds = [];

      // need to create comment bank
      firestore.add({ collection: userPath + '/commentBanks' }, commentbank).then( async (result) => {
        assignment.commentBankId = result.id;
        firestore.update({ collection: userPath + '/assignments', doc: id }, assignment).then( async (result) => {
          history.push('/assignment/' + id);
        });
      });
    } else {

      firestore.update({ collection: userPath + '/assignments', doc: id }, assignment).then( async (result) => {
        history.push('/assignment/' + id);
      });
    }

  }


  onFinish = (e) => {
    e.preventDefault();
    const { id } = this.state;

    if(!id) {
      // add new
      this.createAssignmentFromState();
    } else {
      // edit existing..
      this.updateAssignmentFromState();
    }
  }


  // pass through props?
  loadCommentBanks() {
    console.log('load comment banks');
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;
    
//    const userPath = 'users/' + auth.uid;

    // , where: ["createdBy", "==", store.getState().firebase.auth.uid ]
    console.log('collection: ' + userPath + '/commentBanks');
    firestore.get({ collection: userPath + '/commentBanks' }).then((results) => {  
      let commentBanks = [];
      for(var i = 0; i < results.docs.length; i++) {
        let commentBank = results.docs[i].data();
        commentBank.id = results.docs[i].id;
        commentBanks.push(commentBank);
      }

      this.setState({ commentBanks });
    });  
  }

  loadAssignmentDetails() {
    console.log('load assignment detailsd');
    const { firestore } = this.props;
    const uid = store.getState().firebase.auth.uid;
    const userPath = 'users/' + uid;
    const assignmentId = this.props.match.params.id;

    firestore.get({ collection: userPath + '/assignments', doc: assignmentId }).then((result) => {
      let assignment = result.data();
      assignment.id = result.id;
      this.setState({ 
        id: result.id,
        name: assignment.name,
        description: assignment.description,
        commentBankId: assignment.commentBankId,
        formErrors: {} 
      });

    });
  }

  componentDidMount() {
    this.loadCommentBanks();
    if(typeof this.props.match.params.id != 'undefined') {
      this.loadAssignmentDetails();
    }

  }

  componentDidUpdate(prevProps) {
    if(typeof this.props.match.params.id != 'undefined') {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        this.loadCommentBanks();
        this.loadAssignmentDetails();
      }
    } else {
      if(typeof prevProps.match.params.id !== 'undefined') {
        this.loadCommentBanks();
        this.setState({
          id: false,
          name: '',
          description: '',
          commentBankId: '',
          formErrors: {}          
        });
      }
    }
  }



  render() {
    const {  auth } = this.props;
    let { name, description, commentBankId, commentBankName, formErrors, commentBanks } = this.state;

    if(!commentBanks) {
      return (
        <div>Loading...</div>
      );
    }


    let commentBankList = commentBanks;

//    console.log('comment bank list');
//    console.log(commentBankList);

    return (
      <div>
        <h1>Assignment Creator</h1>
        <form onSubmit={this.onSubmit} style={{ "marginBottom": "30px"}}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="name">Assignment Name</label>
                <input 
                    type="text" 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="name" 
                    placeholder="" 
                    value={name} 
                    onChange={this.onChange}/>
                { formErrors.name && 
                  <div  className="invalid-feedback">{formErrors.name}</div>
                }
              </div>          

              {/*
              <div className="form-group">
                <label htmlFor="name">Email Subject</label>
                <input 
                    type="text" 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="emailSubject" 
                    placeholder="" 
                    value={emailSubject} 
                    onChange={this.onChange}/>
                { formErrors.emailSubject && 
                  <div  className="invalid-feedback">{formErrors.emailSubject}</div>
                }
              </div>      
              */}

              <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea 
                      className="form-control" 
                      name="description" 
                      placeholder="" 
                      value={description} 
                      onChange={this.onChange}
                      rows="3"
                  />
              </div>   

              <div className="form-group">
                  <label htmlFor="commentBankId">Comment Bank</label>
                  <select name="commentBankId" className="form-control" value={commentBankId} onChange={this.onChange}>
                    <option value="">Create a new Comment Bank</option>
                    {commentBankList.map((entry, index) => (
                      <option value={entry.id} key={entry.id}>{entry.name}</option>
                    ))
                    }
                  </select>
              </div>

              { commentBankId === '' &&
              <div className="form-group">
                  <label htmlFor="commentBankName">New Comment Bank Name:</label>
                  <input 
                    type="text" 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="commentBankName" 
                    placeholder="" 
                    value={commentBankName} 
                    onChange={this.onChange}/>                  
              </div>
              }

            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <button type="button" onClick={this.onCancel} className="btn btn-secondary">Cancel</button>
              &nbsp;
              <button type="button" onClick={this.onFinish} className="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


export default firestoreConnect()(AssignmentCreator);

/*
export default compose(
  firestoreConnect(['criteria']),
  connect((state) => ({
    criteria: state.firestore.ordered.criteria
  }))
)(RubricCreator);
*/


/*

// if editing, id will be passed through params, how to know if invalid id?
// if adding, no id in params, 
export default compose(
  firestoreConnect(props => 
    (props.match.params.id ? 
    [
      { collection: 'assignments', storeAs: 'selectedAssignment' + props.match.params.id, doc: props.match.params.id },
      { collection: 'commentbanks',  where: ['createdBy', '==', store.getState().firebase.auth.uid] }
    ] :
    [ { collection: 'commentbanks',  where: ['createdBy', '==', store.getState().firebase.auth.uid] } ])
  ),
  connect( (state, props) => (
    (props.match.params.id ?
    {
      auth: state.firebase.auth,
      selectedAssignment: state.firestore.ordered['selectedAssignment' + props.match.params.id] && state.firestore.ordered['selectedAssignment' + props.match.params.id][0],
      commentbanks: state.firestore.ordered['commentbanks']

    } :
    {
      auth: state.firebase.auth,
      commentbanks: state.firestore.ordered['commentbanks']
    })
  ))
)(AssignmentCreator);
*/