import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import store  from '../../store';


class RubricList extends Component {
  render() {
    const { rubrics, auth } = this.props;

    if(!rubrics) {
      return (
        <div>Loading...</div>
      );
    }

    let rubricList = rubrics.filter(rubric => rubric.createdBy === auth.uid);

    if(rubricList.length === 0) {
      return (
        <div></div>
      )
    }
    if(rubrics) {
      return (
        <div>
          <h2>Rubrics</h2>
          <div className="row">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                { rubricList.length === 0 &&
                  <tr>
                    <td colSpan="3">No rubrics found</td>
                  </tr>
                }
                { rubricList.map((entry, index) => (
                  <tr key={entry.id}>
                    <td>{entry.name}</td>
                    <td>{entry.description}</td>
                    <td style={{"textAlign": "right"}}><Link to={`/rubric/edit/${entry.id}`} className="btn btn-dark">Edit</Link></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'rubrics',
      where: ['createdBy', '==', store.getState().firebase.auth.uid]
    }
  ]),
  connect((state) => ({
    auth: state.firebase.auth,
    rubrics: state.firestore.ordered.rubrics
  }))
)(RubricList);