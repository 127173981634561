import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import store  from '../../store';


class MessageEditor extends Component {
  state = {
    id: false,
    name: '',
    text: '',
    type: 'greeting',
    formErrors: {}
  }


  static getDerivedStateFromProps(props, state) {

    const id = props.match.params.id;

    if(id !== state.id) {
      // need to update state..
      if(!id) {
        return {
          id: id,
          name: '',
          text: '',
          formErrors: {}
        }  
      } else {
        const { selectedMessage } = props;

        if(!selectedMessage) {
          // values not loaded yet, so keep current state;
          return state;
        }
        
        let newState = {
          id: id, 
          name: selectedMessage.name,
          text: selectedMessage.text,
          type: selectedMessage.type,
          formErrors: {}
        }
    
        return newState;        
      }
    }

    // keep state the same
    return state;
  }

  onChange = (e) => {
    let formErrors = this.state.formErrors;
    this.setState({ [e.target.name]: e.target.value, formErrors });
  }

  onCancel = (e) => {
    e.preventDefault();
    this.props.history.push('/');
  }

  createMessageFromState = async () => {

    const { firestore, history, auth } = this.props;
    const data  = this.state;

    let message = {};
    message.name = data.name;
    message.text = data.text;
    message.type = data.type;
    message.createdBy = auth.uid;
    

    firestore.add({ collection: 'messages' }, message).then( async (result) => {
//      const id = result.id;
      history.push('/');
    });
  }  

  updateMessageFromState = async () => {
    const { id } = this.state;
    const { firestore, history } = this.props;
    const data  = this.state;

    let message = {};
    message.name = data.name;
    message.text = data.text;
    message.type = data.type;

    firestore.update({ collection: 'messages', doc: id }, message).then( async (result) => {
      history.push('/');
    });

  }


  onFinish = (e) => {
    e.preventDefault();
    const { id } = this.state;

    if(!id) {
      // add new
      this.createMessageFromState();
    } else {
      // edit existing..
      this.updateMessageFromState();
    }


  }


  render() {
    const { criteria, auth } = this.props;
    let {  selectedMessage, formErrors } = this.state;

    /*
    if(!selectedMessage) {
      return (
        <div>Loading...</div>
      );
    }
*/
    return (
      <div>
        <h1>Message Creator</h1>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input 
                    type="text" 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="name" 
                    id="name"
                    placeholder="" 
                    value={this.state.name} 
                    onChange={this.onChange}/>
                { formErrors.name && 
                  <div  className="invalid-feedback">{formErrors.name}</div>
                }
              </div>          

              <div className="form-group">
                <label htmlFor="type">Type</label>
                <select 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="type" 
                    id="type"
                    placeholder="" 
                    value={this.state.type} 
                    onChange={this.onChange}>
                    <option value="greeting">Greeting</option>
                    <option value="signoff">Sign Off</option>
                </select>
                { formErrors.name && 
                  <div  className="invalid-feedback">{formErrors.name}</div>
                }
              </div>          


              <div className="form-group">
                  <label htmlFor="text">Text</label>
                  <textarea 
                      className="form-control" 
                      name="text" 
                      placeholder="" 
                      value={this.state.text} 
                      onChange={this.onChange}
                      rows="3"
                  />
              </div>   

            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <button type="button" onClick={this.onCancel} className="btn btn-secondary">Cancel</button>
              &nbsp;
              <button type="button" onClick={this.onFinish} className="btn btn-primary">Finish</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


/*
export default compose(
  firestoreConnect(['criteria']),
  connect((state) => ({
    criteria: state.firestore.ordered.criteria
  }))
)(RubricCreator);
*/


// if editing, id will be passed through params, how to know if invalid id?
// if adding, no id in params, 
export default compose(
  firestoreConnect(props => 
    (props.match.params.id ? 
    [
      { collection: 'messages', storeAs: 'selectedMessage' + props.match.params.id, doc: props.match.params.id },
    ] :
    [ ])
  ),
  connect( (state, props) => (
    (props.match.params.id ?
    {
      auth: state.firebase.auth,
      selectedMessage: state.firestore.ordered['selectedMessage' + props.match.params.id] && state.firestore.ordered['selectedMessage' + props.match.params.id][0]
    } :
    {
      auth: state.firebase.auth,
    })
  ))
)(MessageEditor);
