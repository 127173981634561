import { createStore, combineReducers, compose } from 'redux';
import {reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import firebase from 'firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import 'firebase/auth'
import 'firebase/firestore';
import 'firebase/functions'

// Reducers
import notifyReducer from './reducers/notifyReducer';

const firebaseConfig = {
  apiKey: "AIzaSyC2iEQLt_hl5gu4Fw1qdH3E-hUpZsGpN-w",
  authDomain: "headset-dbad6.firebaseapp.com",
  databaseURL: "https://headset-dbad6.firebaseio.com",
  projectId: "headset-dbad6",
  storageBucket: "headset-dbad6.appspot.com",
  messagingSenderId: "35353301428"}

// react redux firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Init firebase instance
firebase.initializeApp(firebaseConfig)

// Init firestore
export const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

firebase.functions();
export const firebaseSendSubmissionResult = firebase.functions().httpsCallable('sendSubmissionResult');
export const firebaseSendFeedbackResult = firebase.functions().httpsCallable('sendFeedbackResult');

// Add reactReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reduxFirestore(firebase), // <- needed if using firestore
  reactReduxFirebase(firebase, rrfConfig) // firebase instance as first argument
)(createStore)


// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  notify: notifyReducer
})

// create initial state
const initialState = {};

//const store = createStoreWithFirebase(rootReducer, initialState)

// create store
const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  reactReduxFirebase(firebase)
  /*
  compose(
    
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  */
);


export default store;
