import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import store  from '../../store';

import Criteria from '../criteria/Criteria';

class RubricCreator extends Component {
  state = {
    id: false,
    name: '',
    description: '',
    rubricCriteria: [],
    selectedCriteria: '',
    formErrors: {}
  }


  static getDerivedStateFromProps(props, state) {

    const id = props.match.params.id;

    if(id !== state.id) {
      // need to update state..
      if(!id) {
        return {
          id: id,
          name: '',
          description: '',
          rubricCriteria: [],
          selectedCriteria: '',
          formErrors: {}
        }  
      } else {
        const { selectedRubric } = props;

        if(!selectedRubric) {
          // values not loaded yet, so keep current state;
          return state;
        }
        
        let newState = {
          id: id, 
          name: selectedRubric.name,
          description: selectedRubric.description,
          rubricCriteria: selectedRubric.criteria,
          formErrors: {}
        }
    
        return newState;        
      }
    }

    // keep state the same
    return state;
  }

  onChange = (e) => {
    let formErrors = this.state.formErrors;
    this.setState({ [e.target.name]: e.target.value, formErrors });
  }

  onCancel = (e) => {
    e.preventDefault();
    this.props.history.push('/');
  }

  createRubricFromState = async () => {

    const { firestore, history, auth } = this.props;
    const data  = this.state;

    let rubric = {};
    rubric.name = data.name;
    rubric.description = data.description;
    rubric.criteria = data.rubricCriteria.slice(0);
    rubric.createdBy = auth.uid;
    

    firestore.add({ collection: 'rubrics' }, rubric).then( async (result) => {
//      const id = result.id;
      history.push('/');
    });
  }  

  updateRubricFromState = async () => {
    const { id } = this.state;
    const { firestore, history } = this.props;
    const data  = this.state;

    let rubric = {};
    rubric.name = data.name;
    rubric.description = data.description;
    rubric.criteria = data.rubricCriteria.slice(0);

    firestore.update({ collection: 'rubrics', doc: id }, rubric).then( async (result) => {
      history.push('/');
    });

  }


  onFinish = (e) => {
    e.preventDefault();
    const { id } = this.state;

    if(!id) {
      // add new
      this.createRubricFromState();
    } else {
      // edit existing..
      this.updateRubricFromState();
    }


  }

  onAddCriteria = (e) => {
    let {rubricCriteria} = this.state;

    if(this.state.selectedCriteria === '') {
      return;
    }

    //need to make sure not already in there..
    rubricCriteria = [ ...rubricCriteria, this.state.selectedCriteria ];
    console.log('new rubric');
    console.log(rubricCriteria);
    this.setState({ rubricCriteria });
  }

  onRemoveCriteria = (index, e) => {
    e.preventDefault();
    const { rubricCriteria } = this.state;
    let criteria = this.state.rubricCriteria.slice(0);
    criteria.splice(index, 1);
    this.setState({ rubricCriteria: criteria });
  }

  render() {
    const { criteria, auth } = this.props;
    let { rubricCriteria, selectedCriteria, formErrors } = this.state;

    if(!criteria) {
      return (
        <div>Loading...</div>
      );
    }

    let criteriaList = criteria.filter(entry => entry.createdBy === auth.uid);

    if(criteriaList.length === 0) {
      return (
        <div>
          Please <Link to="/criteria">create criteria</Link> first.
        </div>
      );
    }

    return (
      <div>
        <h1>Rubric Creator</h1>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input 
                    type="text" 
                    className={ 'form-control' + ( formErrors.name ? ' is-invalid': '') } 
                    name="name" 
                    placeholder="" 
                    value={this.state.name} 
                    onChange={this.onChange}/>
                { formErrors.name && 
                  <div  className="invalid-feedback">{formErrors.name}</div>
                }
              </div>          


              <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea 
                      className="form-control" 
                      name="description" 
                      placeholder="" 
                      value={this.state.description} 
                      onChange={this.onChange}
                      rows="3"
                  />
              </div>   

              <div className="form-group">
                  <label htmlFor="selectedCriteria">Criteria</label>
                  <select name="selectedCriteria" className="form-control" value={selectedCriteria} onChange={this.onChange}>
                    <option value="">Please Select</option>
                    {criteriaList.map((entry, index) => (
                      <option value={entry.id} key={entry.id}>{entry.name}</option>
                    ))
                    }
                  </select>

                  <button type="button" className="btn btn-primary" onClick={this.onAddCriteria}>Add Criteria</button>
              </div>

            </div>
          </div>

          <div className="row">
            { rubricCriteria.map((criteriaId, index) => (
              <div key={index} className="col-md-3" >
                <Criteria id={ criteriaId }/>
                <button type="button" className="btn btn-danger" onClick={this.onRemoveCriteria.bind(this, index)}>Remove</button>
              </div>
            ))

            }
          
          </div>

          <div className="row">
            <div className="col-md-8">
              <button type="button" onClick={this.onCancel} className="btn btn-secondary">Cancel</button>
              &nbsp;
              <button type="button" onClick={this.onFinish} className="btn btn-primary">Finish</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


/*
export default compose(
  firestoreConnect(['criteria']),
  connect((state) => ({
    criteria: state.firestore.ordered.criteria
  }))
)(RubricCreator);
*/


// if editing, id will be passed through params, how to know if invalid id?
// if adding, no id in params, 
export default compose(
  firestoreConnect(props => 
    (props.match.params.id ? 
    [
      { collection: 'rubrics', storeAs: 'selectedRubric' + props.match.params.id, doc: props.match.params.id },
      { collection: 'criteria', where:  ['createdBy', '==', store.getState().firebase.auth.uid] }
    ] :
    [ { collection: 'criteria', where:  ['createdBy', '==', store.getState().firebase.auth.uid] } ])
  ),
  connect( (state, props) => (
    (props.match.params.id ?
    {
      auth: state.firebase.auth,
      selectedRubric: state.firestore.ordered['selectedRubric' + props.match.params.id] && state.firestore.ordered['selectedRubric' + props.match.params.id][0],
      criteria: state.firestore.ordered['criteria']

    } :
    {
      auth: state.firebase.auth,
      criteria: state.firestore.ordered['criteria']
    })
  ))
)(RubricCreator);
