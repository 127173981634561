import React from 'react';

const Alert = (props) => {
  const { message, messageType } = props;
  return (
    <div
      className={ 'alert' + (
        messageType === 'success' ? ' alert-success'
        : messageType === 'error' ? ' alert-danger'
        : messageType === 'info' ? ' alert-info'
        : ''
      )}
    >
      {message}
    </div>
  );
};

export default Alert;
