import React, { Component } from 'react';

class CommentCreator extends Component {
  state = {
    showAddComment: false,
    scaleIndex: false,
    positiveComment: true,
    comment: '',
    commentIndex: false,
    scaleLabel: '',

    hoverComment: '',
    showHover: false,
    hoverScaleLabel: ''
  }

  onChange = (e) => {
    this.props.onSetComment(this.state.scaleIndex, this.state.positiveComment, this.state.commentIndex, e.target.value );

    this.setState({ [e.target.name]: e.target.value });
  }

  cancelEditComment = (e) => {
    e.preventDefault();
    this.setState({ showAddComment: false, comment: '', commentIndex: false });
  }

  onSubmitComment = (e) => {
    e.preventDefault();
//    this.props.onAddComment(this.state.scaleIndex, this.state.positiveComment, this.state.comment);
    this.props.onSetComment(this.state.scaleIndex, this.state.positiveComment, this.state.commentIndex, this.state.comment);
    this.setState({ comment: '', showAddComment: false});
  }

  mouseOverComment = (scaleIndex, side, commentIndex, e) => {
    let comment = '';
    let criteria = this.props.criteria;

    if(side === 'left') {
      comment = criteria.scaleNegativeComments[scaleIndex][commentIndex];
    }

    if(side === 'right') {
      comment = criteria.scalePositiveComments[scaleIndex][commentIndex];
    }

    let hoverScaleLabel = criteria.scaleLabels[scaleIndex];

    this.setState({ hoverComment: comment, showHover: true, hoverScaleLabel });
  }

  mouseOutComment = (scaleIndex, side, commentIndex, e) => {
    this.setState({ showHover: false, hoverComment: '' });

  }

  goBack = (e) => {
    this.props.onSetStep(0);
  }

  onFinish = (e) => {
    this.props.onFinish();
  }

  onClickComment = (scaleIndex, side, commentIndex, e) => {
    let positiveComment = side === 'right';
    let comment = '';
    let criteria = this.props.criteria;
    let scaleLabel = '';

    if(side === 'left') {
      comment = criteria.scaleNegativeComments[scaleIndex][commentIndex];
    }

    if(side === 'right') {
      comment = criteria.scalePositiveComments[scaleIndex][commentIndex];
    }

    scaleLabel = criteria.scaleLabels[scaleIndex];

    this.setState({ scaleIndex, showAddComment: true, positiveComment, commentIndex, comment, scaleLabel  });

  }
  
  showAddComment = (scaleIndex, side, e) => {
    let positiveComment = side === 'right';
    let criteria = this.props.criteria;

    let commentIndex = this.props.onAddComment(scaleIndex, positiveComment, '');
    let scaleLabel = criteria.scaleLabels[scaleIndex];

    this.setState({ scaleLabel, scaleIndex, showAddComment: true, positiveComment, commentIndex, comment: '' });
  }
  render() {
    var criteria = this.props.criteria;

    if(criteria.scaleLabels.length === 0) {
      return (
        <div>
          
        </div>
      );
    }

    let { showHover, hoverComment, hoverScaleLabel } = this.state;

    let midPoint = 200;
    const rowHeight = 21;
    const columnWidth = 20;
    let gridHeight = rowHeight * criteria.scaleLabels.length;
    let lineHeight = gridHeight - (rowHeight - 5);

    return (
      <div>
        <h1>Comment creator</h1>
        <h2>{criteria.criteria}</h2>
        <div className="row">
          <div className="col-md-8" style={{ "position": "relative", "height": gridHeight + "px" }}>

              <div style={{ "borderLeft": "1px solid red", "position": "absolute", "top": (Math.floor( (rowHeight - 5) / 2)) + "px", "left": Math.floor( midPoint) + "px", "height": lineHeight + "px" }}></div>
              { criteria.scaleLabels.map((scaleLabel, index) => (
                <div key={index}>
                  <div className="circle-button" 
                    style={{ position: "absolute", top: ( (rowHeight - 15) / 2 + index * rowHeight) + "px", left: Math.round(midPoint - 15/2 - (criteria.scaleNegativeComments[index].length + 1) * columnWidth) + "px" }} 
                    onClick={this.showAddComment.bind(this, index, 'left')}>+</div>

                  { criteria.scaleNegativeComments[index].map((comment, commentIndex) => (
                    <div key={commentIndex} 
                        onMouseOver={this.mouseOverComment.bind(this, index, 'left', commentIndex)} 
                        onMouseOut={this.mouseOutComment.bind(this, index, 'left', commentIndex )}
                        onClick={this.onClickComment.bind(this, index, 'left', commentIndex)}
                        className={ 'comment-symbol comment-symbol-negative ' +  ( this.state.positiveComment === false && this.state.scaleIndex === index &&  commentIndex === this.state.commentIndex ? ' comment-symbol-selected' : '' )}
                        style={{ "top": ( (rowHeight - 10) / 2 + index * rowHeight) + "px", "left": Math.round(-10/2 + midPoint - (commentIndex + 1) * columnWidth) + "px" }}></div>
                  ))}

                  <div className="scale-point-symbol" 
                    style={{  "left": Math.round(-5/2 + midPoint) + "px", "top": (Math.floor( (rowHeight - 5) / 2 + index * rowHeight)) + "px" }}></div>

                  { criteria.scalePositiveComments[index].map((comment, commentIndex) => (
                    <div key={commentIndex} 
                        onMouseOver={this.mouseOverComment.bind(this, index, 'right', commentIndex)} 
                        onMouseOut={this.mouseOutComment.bind(this, index, 'right', commentIndex )}
                        onClick={this.onClickComment.bind(this, index, 'right', commentIndex)}
                        className={ 'comment-symbol' +  ( this.state.positiveComment === true && this.state.scaleIndex === index &&  commentIndex === this.state.commentIndex ? ' comment-symbol-selected' : '' )}
                        style={{ "top": ( (rowHeight - 10) / 2 + index * rowHeight) + "px", "left": Math.round(-10/2 + midPoint + (commentIndex + 1) * columnWidth) + "px" }}></div>
                  ))}
                  <div className="circle-button" 
                        style={{ position: "absolute", top: ( (rowHeight - 15) / 2 + index * rowHeight) + "px", left: Math.round(midPoint - 15 / 2 +(criteria.scalePositiveComments[index].length + 1) * columnWidth) + "px"}} onClick={this.showAddComment.bind(this, index, 'right')}>+</div>
                </div>
              ))}
          </div>
          <div className="col-md-4">
            { showHover && 
            <div>
              <h3>{hoverScaleLabel}</h3>
              <p>{hoverComment}</p>
            </div>
            }
          </div>

        </div>
        { this.state.showAddComment && 
          <form onSubmit={this.onSubmitComment}>
            <div className="row">
              <h3>{this.state.scaleLabel}</h3>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <label htmlFor="comment">Edit Comment</label>
                  <textarea 
                      className="form-control" 
                      name="comment" 
                      placeholder="" 
                      value={this.state.comment} 
                      onChange={this.onChange}
                      rows="3"
                  />
                  { /*
                  <button type="button" onClick={this.cancelEditComment} className="btn btn-secondary">Cancel</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                  */ }
                </div>
              </div>
            </div>
          </form>
        }

        <button type="button" onClick={this.goBack} className="btn btn-secondary">Back</button>
        &nbsp;
        <button type="button" onClick={this.onFinish} className="btn btn-primary">Finish</button>
      </div>
    )
  }
}

export default CommentCreator;

