import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase'; //firebase instead of firestore
import { notifyUser } from '../../actions/notifyActions';
import Alert from '../layout/Alert';

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })

  }
  onSubmit = (e) => {
    e.preventDefault();

    const { firebase, notifyUser } = this.props;
    const { email, password } = this.state;

    notifyUser('Logging in...', 'info');
    firebase.login({ email, password }).catch(err => notifyUser('Invalid Login Credentials', 'error'));
  }

  componentDidMount() {
    const { notifyUser } = this.props;
    console.log("LOGIN COMPONENT MOUNTED");
    notifyUser(false, 'info');
  }

  render() {

    const { message, messageType } = this.props.notify;

    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-body">
              <h1 className="text-center pb-4 pt-3">
                <span className="text-primary">
                  <i className="fas fa-lock"/> Login
                </span>
              </h1>
              { message ? (
                <Alert message={message} messageType={messageType}/>
              ): null }
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input 
                    type="text"
                    className="form-control"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input 
                    type="password"
                    className="form-control"
                    name="password"
                    required
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
                <input type="submit" value="Login" className="btn btn-primary btn-block"/>

                <div>
                  or <Link to="/register">Register</Link>
                  &nbsp;
                  or <Link to="/forgotten-password">Forgotten Password</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      notify: state.notify
    }),
    // actions go in here
    { notifyUser }
  )

)(Login);
