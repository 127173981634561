import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { notifyUser } from '../../actions/notifyActions';
import { Link } from 'react-router-dom';

import Alert from '../layout/Alert';

class AccountManagement extends Component {
  state = {
    newPassword: '',
    error: null,
    passwordReset: false
  };


  onSubmit = event => {
    const { code, newPassword } = this.state;
    event.preventDefault();

    console.log('new password = ' + newPassword);
    console.log('code = ' + code);

    this.props.firebase.auth().confirmPasswordReset(code, newPassword)
    .then( (response) => {
      // Success
      this.setState({ passwordReset: true });
      console.log(response);
      console.log('success!');
    })
    .catch(error => {
      // Invalid code
      this.setState({ error });
    })

    /*
    //this.props.firebase.auth
    this.props.firebase.auth()
      .sendPasswordResetEmail(email)
      .then( () => {
        this.setState({ email: '', error: null });
      }).catch(error => {
        this.setState({ error });
      });
*/
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    let mode = '';
    let code = '';
//    console.log(this.props.location.search) // "?filter=top&origin=im"
    let params = this.props.location.search.substring(1).split('&');
    
    for(let i = 0; i < params.length; i++) {
      var pair = params[i].split('=');
      console.log(pair);
      if (decodeURIComponent(pair[0]) == 'mode') {
        mode = decodeURIComponent(pair[1]);
      }      
      if (decodeURIComponent(pair[0]) == 'oobCode') {
        code = decodeURIComponent(pair[1]);      
      }
    }


    console.log('code is ' + code);
    this.props.firebase.auth().verifyPasswordResetCode(code)
    .then( (email) => {
      // Display a "new password" form with the user's email address
      console.log(email);
      this.setState({ "mode": mode, "code": code });

    })
    .catch( (e) => {
      console.log(e);
      // Invalid code
      console.log('invalid code');
      this.setState({ "mode": 'invalid' });
    })    

  }  

  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <div>
        { !this.state.passwordReset && 
          <div>
            <h2>New Password</h2>
            { this.state.mode === 'resetPassword' && 
            <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <input 
                        type="password"
                        className="form-control"
                        name="newPassword"
                        required
                        value={this.state.newPassword}
                        onChange={this.onChange}
                      />
                    </div>
                    <input type="submit" value="Set" className="btn btn-primary btn-block"/>

                    {error && <p>{error.message}</p>}


            </form>
            }

            { this.state.mode === 'invalid' && 
              <div>
                <p>The link you have followed appears to be invalid.</p>
                <Link to={`/`} className="btn btn-primary">OK</Link>
              </div>
            }

          </div>
        }
        { this.state.passwordReset && 
          <div>
            <p>Your password has been reset</p>
            <Link to={`/`} className="btn btn-primary">OK</Link>
          </div>
        }
      </div>
    );
  }
}


export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      notify: state.notify
    }),
    // actions go in here
    { notifyUser }
  )
)(AccountManagement);


